import { NgModule } from '@angular/core';
import { RequiredFieldPipe } from './pipes/required-field.pipe';
import { FormRowComponent, FormRowTemplate } from './components/form-components/form-row.component';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormInputTextComponent } from './components/form-fields/form-input-text.component';
import { FormInputNumberComponent } from './components/form-fields/form-input-number.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormCheckboxComponent } from './components/form-fields/form-checkbox.component';
import { FormSelectComponent } from './components/form-fields/form-select.component';
import { FormCalendarComponent } from './components/form-fields/form-calendar.component';
import { FormButtonbarComponent } from './components/form-components/form-buttonbar.component';
import { FormInputSwitchComponent } from './components/form-fields/form-input-switch.component';
import { FormInputHhmmComponent } from './components/form-fields/form-input-hhmm.component';
import { FormButtongroupSelectionComponent } from './components/form-fields/form-buttongroup-selection.component';
import { FormRadiobuttonComponent } from './components/form-fields/form-radiobutton.component';
import { NumericDirective } from './directives/numeric.directive';
import { FormMultiselectComponent } from './components/form-fields/form-multiselect.component';
import { FileUtils } from './utils/file-utils';
import { FormUploadComponent } from './components/form-fields/form-upload.component';
import { LabelValueComponent } from './components/form-components/label-value.component';
import { FormInputTextareaComponent } from './components/form-fields/form-input-textarea.component';
import { FormInputOptionalSwitchComponent } from './components/form-fields/form-input-optional-switch.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SearchPipe } from './pipes/search.pipe';
import { FormConfirmButtonComponent } from './components/form-components/form-confirm-button.component';
import { FormInputReadonlyComponent } from './components/form-fields/form-input-readonly.component';
import { FormRadiobuttonSingleComponent } from './components/form-fields/form-radiobutton-single.component';
import { RibbonComponent } from './components/ribbon/ribbon.component';
import {PrimengSharedModule} from './primeng-shared.module';
import {PageSpinnerModule} from './components/page-spinner/page-spinner.module';
import { FormInputPasswordComponent } from './components/form-fields/form-input-password.component';
import { FormMonthCalendarComponent } from './components/form-fields/form-month-calendar.component';
import { FormInputTextButtonComponent } from './components/form-fields/form-input-text-button.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DragDropModule,
    PrimengSharedModule,
    PageSpinnerModule,
    TranslateModule
  ],
  declarations: [
    NumericDirective,
    RequiredFieldPipe,
    SearchPipe,
    FormRowTemplate,
    FormRowComponent,
    FormInputReadonlyComponent,
    FormInputTextComponent,
    FormInputTextButtonComponent,
    FormInputTextareaComponent,
    FormButtonbarComponent,
    FormConfirmButtonComponent,
    FormCheckboxComponent,
    FormSelectComponent,
    FormMultiselectComponent,
    FormCalendarComponent,
    FormMonthCalendarComponent,
    FormInputNumberComponent,
    FormInputSwitchComponent,
    FormInputOptionalSwitchComponent,
    FormInputHhmmComponent,
    FormButtongroupSelectionComponent,
    FormRadiobuttonComponent,
    FormRadiobuttonSingleComponent,
    FormUploadComponent,
    LabelValueComponent,
    FormInputPasswordComponent,
    RibbonComponent
  ],
  exports: [
    NumericDirective,
    RequiredFieldPipe,
    SearchPipe,
    FormRowTemplate,
    FormRowComponent,
    FormInputReadonlyComponent,
    FormInputTextComponent,
    FormInputTextButtonComponent,
    FormInputTextareaComponent,
    FormButtonbarComponent,
    FormConfirmButtonComponent,
    FormSelectComponent,
    FormMultiselectComponent,
    FormCheckboxComponent,
    FormCalendarComponent,
    FormMonthCalendarComponent,
    FormInputNumberComponent,
    FormInputSwitchComponent,
    FormInputOptionalSwitchComponent,
    FormInputHhmmComponent,
    FormButtongroupSelectionComponent,
    FormRadiobuttonComponent,
    FormRadiobuttonSingleComponent,
    FormUploadComponent,
    LabelValueComponent,
    FormInputPasswordComponent,
    RibbonComponent,
    PrimengSharedModule
  ],
  providers: [FileUtils, DecimalPipe]
})
export class SharedModule {
}
