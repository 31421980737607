import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {restServices} from '../../../rest.services';
import {StorageService} from '../general-utils/storage.service';
import {USER_KEY, LOGGED_USER} from '../../../shared/constants/app.constants';
import { UtenteService } from './utente.service';

@Injectable()
export class AuthenticationService {
  env = environment;
  restServices = restServices;

  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService,
    private utenteService: UtenteService) {
  }

  login(input: any) {
    return this.http.post<any>(this.env.api_url + restServices.authenticate, input).pipe(
      map(authData => {
        if (authData && authData.id_token) {
          // store jwt token in local storage to keep user logged in between page refreshes
          this.storageService.setItem(USER_KEY, authData.id_token);
          return authData;
        }
        return null;
      })
    );
  }

  getCurrentUser() {
    this.utenteService.getUtenteLoggato().subscribe(resp => {
      if (resp && resp.body) {
        this.storageService.setItem(LOGGED_USER, JSON.stringify(resp.body));
      }
    });
  }

  logout() {
    return this.http.post<any>(this.env.api_url + restServices.logout, {}).pipe(map(userData => {
      if (userData) {
        this.cleanLocalStorage();
        // this.router.navigate(['/login']);
      }
    }));
  }

  // changePassword(input: any): Observable<any> {
  //   return this.http.post<any>(this.env.api_url + restServices.changePassword, input);
  // }

  cleanLocalStorage() {
    localStorage.clear();
    sessionStorage.clear();
    this.storageService.clear();
    location.reload();
  }

}
