import { HttpParameterCodec, HttpParams } from '@angular/common/http';

export interface Pagination {
  page: number;
  size: number;
  totalRecords?: number; // x dyn-table!
  sort?: string[];
}

export interface Search {
  query?: string;
}

export interface SearchWithPagination extends Search, Pagination {
}

export const createRequestOption = (req?: any): HttpParams => {
  let options: HttpParams = new HttpParams({encoder: new CustomURLEncoder() });
  if (req) {
    Object.keys(req).forEach(key => {
      if (key !== 'sort') {
        options = options.set(key, req[key]);
      }
    });
    if (req.sort) {
      req.sort.forEach((val: string) => {
        options = options.append('sort', val);
      });
    }
  }
  return options;
};

export class CustomURLEncoder implements HttpParameterCodec {
  encodeKey(key: string) {
    return encodeURIComponent(key); 
  }
  encodeValue(key: string) { 
    return encodeURIComponent(key); 
  }
  decodeKey(key: string) { 
    return decodeURIComponent(key); 
   }
  decodeValue(key: string) {
    return decodeURIComponent(key); 
  }
}

/**
 * Compone un filtro di sort (x getAll)
 */
export const createReqSort = (sortFieldName: string, sortDir: 'asc' | 'desc'): any => {
  return sortFieldName && sortDir ? { sort: [sortFieldName + ',' + sortDir] } : null;
};

/**
 * Compone un filtro di ricerca "equals" su un campo (x getAll)
 */
export const createReqEquals = (fieldName: string, equalsValue: any): any => {
  return fieldName && equalsValue != null ? { [fieldName + '.equals']: equalsValue } : null;
};

