import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PageSpinnerComponent} from './page-spinner.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

@NgModule({
  declarations: [PageSpinnerComponent],
  imports: [
    CommonModule,
    ProgressSpinnerModule
  ],
  exports: [PageSpinnerComponent]
})
export class PageSpinnerModule { }
