<div class="app-body">
  <main class="d-flex align-items-center ml-0 h-100">
    <div class="container">
      <div class="row">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mx-auto">
          <div class="card-group" style="opacity: 0.95">
            <div class="card p-6 login-card">
              <div class="card-body bg-white">
                <div class="d-flex justify-content-center pb-3">
                  <img [src]="logoPath" alt="logo" [ngClass]="!deviceService.isDesktop() ? 'img-fill-container' : ''">
                </div>
                <div class="text-center text-black mt-2">
                  <h2>{{translate.instant('login_accessLabel', { appName })}}</h2>
                  <hr class="bg-black">
                  <form [formGroup]="formLogin" (submit)="login()">
                    <form-row>
                      <ng-template formRowTemplate="content">
                        <form-input-text [control]="formLogin.controls['username']" [label]="'username'|translate"
                          [placeholder]="'stdPlaceholder'|translate" class="text-left">
                        </form-input-text>
                      </ng-template>
                    </form-row>
                    <form-row>
                      <ng-template formRowTemplate="content">
                        <form-input-password [control]="formLogin.controls['password']" [label]="'password'|translate"
                          [placeholder]="'stdPlaceholder'|translate" class="text-left">
                        </form-input-password>
                      </ng-template>
                    </form-row>
                    <br>
                    <form-row>
                      <ng-template formRowTemplate="content">
                        <button type="submit" class="btn btn-black btn-lg" [disabled]="formLogin.invalid">
                          {{ 'login' | translate }}
                          <i *ngIf="loading" class="fas fa-circle-notch fa-spin fa-fw"></i>
                          <i *ngIf="!loading" class="fas fa-sign-in-alt fa-fw"></i>
                        </button>
                      </ng-template>
                    </form-row>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>

<!-- 
                
                 -->