import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'form-buttongroup-selection',
  template: `
    <div class="form-group">
      <div class="row"><label class="col-form-label font-weight-bold">{{label}}</label></div>
      <div class="row">
        <div class="btn-group w-100" style="height: calc(1.5em + 0.75rem + 2px)">
          <button *ngFor="let control of controls; let i = index" type="button" class="btn btn-sm"
                  [ngClass]="control.value ? 'btn-success' : 'btn-info'"
                  [ngStyle]="atLeastOne && noneSelected() ? {'border': '1px solid #dc3545'} : null"
                  [disabled]="control.disabled"
                  (click)="control.setValue(!control.value)">
            {{btnLabels[i]}}
          </button>
        </div>
      </div>
    </div>`,
})
export class FormButtongroupSelectionComponent {

  @Input() controls: AbstractControl[]; // lista controlli BOOLEAN del form
  @Input() label: string;
  @Input() btnLabels: string[];
  @Input() atLeastOne: boolean; // se almeno uno dei pulsanti deve essere selezionato

  noneSelected(): boolean {
    return !this.controls.find(c => c.value === true);
  }

}
