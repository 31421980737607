import { DatePipe, DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ResponseBudgetOvDTO } from '../../models/dto/response-budget-ov-dto';
import { Classe } from '../../models/entities/classe';
import { ProfiloEnum } from '../../models/enums/profilo-enum';
import { StatoBudget } from '../../models/enums/stato-budget';

@Injectable()
export class ModelService {

  constructor(
    private translate: TranslateService,
    private datepipe: DatePipe,
    private _decimalePipe: DecimalPipe) {

  }

  populateOvBudget(budgetOv: ResponseBudgetOvDTO, loggedUser: Classe) {
    const isCreaBudgetEnabled = loggedUser.utenteProfilos && loggedUser.utenteProfilos.find(up => up.profilo.profilo === ProfiloEnum.CREA_BUDGET) ? true : false;
    const isBudgetPerArticoloEnabled = loggedUser.utenteProfilos && loggedUser.utenteProfilos.find(up => up.profilo.profilo === ProfiloEnum.BUDGET_ARTICOLO) ? true : false;
    const isSchedulazioneRevisioneEnabled = loggedUser.utenteProfilos && loggedUser.utenteProfilos.find(up => up.profilo.profilo === ProfiloEnum.SCHEDULAZIONE_REVISIONE) ? true : false;
    const periodoInizioB = new Date(budgetOv.periodoInizio);
    const periodoFineB = new Date(budgetOv.periodoFine);
    budgetOv.periodoBudget = this.translate.instant('primeng.monthNamesShort')[periodoInizioB.getMonth()] + ' ' + periodoInizioB.getFullYear() + ' - ' +
      this.translate.instant('primeng.monthNamesShort')[periodoFineB.getMonth()] + ' ' + periodoFineB.getFullYear();
    budgetOv.periodoBudget_start = this.translate.instant('primeng.monthNamesShort')[periodoInizioB.getMonth()] + ' ' + periodoInizioB.getFullYear();
    budgetOv.periodoBudget_end = this.translate.instant('primeng.monthNamesShort')[periodoFineB.getMonth()] + ' ' + periodoFineB.getFullYear();
    budgetOv.periodoPrecBudget_start = this.translate.instant('primeng.monthNamesShort')[periodoInizioB.getMonth()] + ' ' + (periodoInizioB.getFullYear() - 1);
    budgetOv.periodoPrecBudget_end = this.translate.instant('primeng.monthNamesShort')[periodoFineB.getMonth()] + ' ' + (periodoFineB.getFullYear() - 1);
    const periodoInizioR = new Date(budgetOv.periodoInizioRev);
    const periodoFineR = new Date(budgetOv.periodoFineRev);
    budgetOv.periodoRevisione = this.translate.instant('primeng.monthNamesShort')[periodoInizioR.getMonth()] + ' ' + periodoInizioR.getFullYear() + ' - ' +
      this.translate.instant('primeng.monthNamesShort')[periodoFineR.getMonth()] + ' ' + periodoFineR.getFullYear();
    budgetOv.dataInserimento = this.datepipe.transform(budgetOv.dataIns, 'dd/MM/yyyy');
    budgetOv.notAttivo = budgetOv.stato !== StatoBudget.ATTIVO || !isCreaBudgetEnabled;
    budgetOv.isEsportaDisabled = (loggedUser.utenteProfilos.find(up => up.profilo.profilo === ProfiloEnum.DOWNLOAD_EXCEL) ? false : true) ||
      budgetOv.stato === StatoBudget.APERTO || budgetOv.stato === StatoBudget.IN_CREAZIONE;
    budgetOv.inCreazione = budgetOv.stato === StatoBudget.IN_CREAZIONE;
    budgetOv.isPerArticoloDisabled = budgetOv.stato === StatoBudget.IN_CREAZIONE || !isBudgetPerArticoloEnabled;
    budgetOv.isSchedulazioneRevisioneDisabled = budgetOv.stato === StatoBudget.IN_CREAZIONE || !isSchedulazioneRevisioneEnabled;
    budgetOv.bpz = budgetOv.stato !== StatoBudget.IN_CREAZIONE ? budgetOv.bpz : null;
    budgetOv.bk = budgetOv.stato !== StatoBudget.IN_CREAZIONE ? budgetOv.bk : null;
    budgetOv.ytd = budgetOv.stato !== StatoBudget.IN_CREAZIONE ? budgetOv.ytd : null;
    budgetOv.bdgRes = budgetOv.stato !== StatoBudget.IN_CREAZIONE ? budgetOv.bdgRes : null;
    budgetOv.previsione = budgetOv.stato !== StatoBudget.IN_CREAZIONE ? budgetOv.previsione : null;
    budgetOv.differenza = budgetOv.stato !== StatoBudget.IN_CREAZIONE ? budgetOv.differenza : null;
    budgetOv.percDiff = budgetOv.stato !== StatoBudget.IN_CREAZIONE ? budgetOv.percDiff : null;
    budgetOv.consPortE = budgetOv.portafEuro + budgetOv.ytd;
    budgetOv.bdgPz_tooltip = this.translate.instant('bdgPz') + ' ' + this.translate.instant('nelPeriodo') + ' ' + budgetOv.periodoBudget;
    budgetOv.bdgE_tooltip = this.translate.instant('bdgE') + ' ' + this.translate.instant('nelPeriodo') + ' ' + budgetOv.periodoBudget;
    budgetOv.consPortE_tooltip = this.translate.instant('consPortE') + ' ' + this.translate.instant('nelPeriodo') + ' ' + budgetOv.periodoBudget;
    const now = new Date();
    let lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    budgetOv.periodoConsuntivo_start = now < periodoInizioB ? '' : this.translate.instant('primeng.monthNamesShort')[periodoInizioB.getMonth()] + ' ' +
      periodoInizioB.getFullYear();
    budgetOv.periodoConsuntivo_end = now < periodoInizioB ? '' : (now > periodoFineB ? this.translate.instant('primeng.monthNamesShort')[periodoFineB.getMonth()] +
      ' ' + periodoFineB.getFullYear() : this.translate.instant('primeng.monthNamesShort')[now.getMonth()] + ' ' + now.getFullYear());
    budgetOv.consE_tooltip = this.translate.instant('consE') + ' ' + this.translate.instant('nelPeriodo') + ' ' +
      budgetOv.periodoConsuntivo_start + ' - ' + budgetOv.periodoConsuntivo_end;
    budgetOv.periodoBudgetResiduo_start = now > periodoFineB ? '' : (now < periodoInizioB ? this.translate.instant('primeng.monthNamesShort')[periodoInizioB.getMonth()] +
      ' ' + periodoInizioB.getFullYear() : this.translate.instant('primeng.monthNamesShort')[now.getMonth()] + ' ' + now.getFullYear());
    budgetOv.periodoBudgetResiduo_end = now > periodoFineB ? '' : this.translate.instant('primeng.monthNamesShort')[periodoFineB.getMonth()] + ' ' + periodoFineB.getFullYear();
    let nowMinus12M = new Date();
    nowMinus12M.setMonth(nowMinus12M.getMonth() - 12);
    budgetOv.periodo12M_start = this.translate.instant('primeng.monthNamesShort')[nowMinus12M.getMonth()] + ' ' + nowMinus12M.getFullYear();
    budgetOv.periodo12M_end = this.translate.instant('primeng.monthNamesShort')[lastMonth.getMonth()] + ' ' + lastMonth.getFullYear();
    budgetOv.annoCorrente_start = this.translate.instant('primeng.monthNamesShort')[0] + ' ' + now.getFullYear();
    budgetOv.annoCorrente_end = this.translate.instant('primeng.monthNamesShort')[11] + ' ' + now.getFullYear();
    budgetOv.annoPrecedente_start = this.translate.instant('primeng.monthNamesShort')[0] + ' ' + (now.getFullYear() - 1);
    budgetOv.annoPrecedente_end = this.translate.instant('primeng.monthNamesShort')[11] + ' ' + (now.getFullYear() - 1);

    // Colonne budget pz e budget euro con confronto periodo precedente
    budgetOv.maxBpzPeriodoPrec = Math.max(budgetOv.bNrPzPeriodoPrec, budgetOv.cpNrPzPeriodoPrec);
    const percAumentoBpz = ((budgetOv.bpz - budgetOv.maxBpzPeriodoPrec) / budgetOv.maxBpzPeriodoPrec) * 100;
    budgetOv.percAumentoBpz = (percAumentoBpz >= 0 ? '+' : '-') + this._decimalePipe.transform(Math.abs(percAumentoBpz), '1.2-2') + '%';

    budgetOv.maxEPeriodoPrec = Math.max(budgetOv.bEuroPeriodoPrec, budgetOv.cpEuroPeriodoPrec);
    const percAumentoE = ((budgetOv.bk - budgetOv.maxEPeriodoPrec) / budgetOv.maxEPeriodoPrec) * 100;
    budgetOv.percAumentoE = (percAumentoE >= 0 ? '+' : '-') + this._decimalePipe.transform(Math.abs(percAumentoE), '1.2-2') + '%';
  }
}
