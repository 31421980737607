import {Component, Input, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {SpinnerService} from './spinner.service';

const SPINNER_MESSAGE = '';

@Component({
  selector: 'spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  @Input() show: boolean;
  LOADING_TEXT: string;
  bgColor: string;
  size: string;
  color: string;
  type: string;
  fullScreen: boolean;

  constructor(private spinner: NgxSpinnerService,
              private spinnerService: SpinnerService) {

    this.LOADING_TEXT = this.spinnerService.config.spinnerText;
    this.bgColor = this.spinnerService.config.bgColor;
    this.size = this.spinnerService.config.size;
    this.color = this.spinnerService.config.color;
    this.type = this.spinnerService.config.type;
    this.fullScreen = this.spinnerService.config.fullScreen;
  }

  ngOnInit() {
    this.LOADING_TEXT = SPINNER_MESSAGE;
    this.spinnerService.getSpinnerStatus().subscribe(data => {
      if (data) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    });
  }

}
