import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'form-radiobutton',
  template: `
    <div class="form-group" [ngClass]="paddingTop ? 'pt-' + paddingTop : ''">
      <p-radioButton [formControl]="control" [value]="value1"
                     [label]="label1" labelStyleClass="font-weight-bold"
                     (onClick)="control.setValue(value1); handleChange.emit($event)">
      </p-radioButton>
      &nbsp;&nbsp;
      <p-radioButton [formControl]="control" [value]="value2"
                     [label]="label2" labelStyleClass="font-weight-bold"
                     (onClick)="control.setValue(value2); handleChange.emit($event)">
      </p-radioButton>
    </div>
  `
})
export class FormRadiobuttonComponent {

  @Input() control: AbstractControl;
  @Input() label1: string;
  @Input() label2: string;
  @Input() value1: any;
  @Input() value2: any;
  @Input() paddingTop: number;

  @Output() handleChange: EventEmitter<any> = new EventEmitter();

}
