import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import Swal, {SweetAlertResult} from 'sweetalert2';
import {ActivatedRouteSnapshot} from '@angular/router';
import {Pagination} from '../../../shared/utils/request-util';
import {DATE_FORMAT_JSON, DATE_FORMAT_TABLE} from '../../../shared/constants/app.constants';
import * as moment from 'moment';
import {Moment} from 'moment';
import {DatePipe} from '@angular/common';
import * as _ from 'lodash';
import { CalendarConfig } from '../../models/general/calendar-config';
import { CALENDAR_EN, CALENDAR_IT } from './../../../shared/constants/app.constants';
import * as FileSaver from 'file-saver';
import { DynTableBadgeType } from 'src/app/shared/components/dyn-table';
import { MessageService } from 'primeng/api';


@Injectable()
export class UtilsService {

  constructor(
    private datepipe: DatePipe,
    private messageService: MessageService,
    private translate: TranslateService) {
  }

  getPagination(size?: number, lazyLoadEvent?: any, stateKey?: string): Pagination {
    let defaultPagination = lazyLoadEvent ? { page: lazyLoadEvent.pagination.targetPage - 1, size: lazyLoadEvent.pagination.recordsPerPage, sort: null } : 
      { page: 0, size: size ? size : 10 };
    if (lazyLoadEvent) {
      if (lazyLoadEvent.pagination.orderField && lazyLoadEvent.pagination.orderDirection) {
        defaultPagination.sort = [lazyLoadEvent.pagination.orderField + ',' + lazyLoadEvent.pagination.orderDirection]
      }
      return defaultPagination;
    }
    if (stateKey) {
      const sk = JSON.parse(localStorage.getItem(stateKey));
      return sk ? { page: !sk.first ? 1 : (sk.first + sk.rows) / sk.rows, size: sk.rows } : defaultPagination;
    }
    return defaultPagination;
  }

  getDynTableBasicLabels() {
    return {
      ricercaHeader: this.translate.instant('Ricerca'),
      filtraBtnLabel: this.translate.instant('Filtra'),
      resetBtnLabel: this.translate.instant('Reset'),
      totals: this.translate.instant('Totali'),
      showing: this.translate.instant('Mostrati'),
      of: this.translate.instant('di'),
      actionsHeader: this.translate.instant('Azioni')
    };
  }

  getSiNoOptions() {
    return [{
      label: this.translate.instant('si'),
      value: true
    }, {
      label: this.translate.instant('no'),
      value: false
    }];
  }

  fireWarningMsg(title: string, text?: string, autoClose = false, showCancelBtn = true) {
    Swal.fire({
      icon: 'warning',
      title: this.translate.instant(title),
      text: text,
      showConfirmButton: !autoClose,
      showCancelButton: !autoClose && showCancelBtn,
      timer: autoClose ? 1000 : null
    }).then();
  }

  fireConfirmationMsg(title: string, text?: string, timer = 1000) {
    Swal.fire({
      icon: 'success',
      title: this.translate.instant(title),
      text: text,
      showConfirmButton: false,
      showCancelButton: false,
      timer
    }).then();
  }

  fireAskConfirmMsg(title: string, withoutTranslation?: boolean): Promise<SweetAlertResult<unknown>> {
    return Swal.fire({
      icon: 'question',
      title: withoutTranslation ? title : this.translate.instant(title),
      inputAttributes: { autocapitalize: 'off' },
      showCancelButton: true,
      cancelButtonText: this.translate.instant('Annulla'),
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  fireErrorConfirmMsg(title: string, text?: string, autoClose = false, showCancelBtn = true): Promise<SweetAlertResult<unknown>> {
    return Swal.fire({
      icon: 'error',
      title: this.translate.instant(title),
      text: text,
      showConfirmButton: !autoClose,
      showCancelButton: !autoClose && showCancelBtn,
      timer: autoClose ? 1000 : null
    });
  }

  fireErrorMsg(title: string, text?: string, autoClose = false): Promise<SweetAlertResult<unknown>> {
    return Swal.fire({
      icon: 'error',
      title: this.translate.instant(title),
      text: text,
      showConfirmButton: !autoClose,
      timer: autoClose ? 1000 : null
    });
  }

  fireConfirmationToast(title?: string, text?: string, duration = 2500) {
    this.messageService.add({severity: 'success', summary: title, detail: text, life: duration, closable: false});
  }

  fireInfoToast(title?: string, text?: string, duration = 2500) {
    this.messageService.add({severity: 'info', summary: title, detail: text, life: duration, closable: false});
  }

  getPageTitle(routeSnapshot: ActivatedRouteSnapshot): string {
    let title: string = routeSnapshot.data && routeSnapshot.data.pageTitle ? routeSnapshot.data.pageTitle : '';
    if (routeSnapshot.firstChild) {
      title = this.getPageTitle(routeSnapshot.firstChild) || title;
    }
    return this.translate.instant(title);
  }

  public padLeft(text: any, width: number, paddingChar: string = '0') {
    text = text.toString();
    return text.padStart(width, paddingChar);
  }

  public padRight(text: any, width: number, paddingChar: string = '0') {
    text = text.toString();
    return text.padEnd(width, paddingChar);
  }

  public createNumberRange(start: number, end: number) {
    const result: number[] = [];
    for (let i = start; i <= end; i++) {
      result.push(i);
    }
    return result;
  }

  public notNullAndNotEmpty(array: any[]) {
    return array && array.length > 0;
  }

  public notNullAndNotEmptyString(str: string) {
    return str && str.trim().length > 0;
  }

  public nullOrEmpty(array: any[]) {
    return array === null || array.length === 0;
  }

  public nullOrEmptyString(str: string) {
    return str === null || str.trim().length === 0;
  }

  /**
   * Array che può essere usato per dropdown filtri "client" boolean true/false
   */
  public getBooleanOptions(): any[] {
    return [
      { label: this.translate.instant('si'), value: true },
      { label: this.translate.instant('no'), value: false }
    ];
  }

  public createObjectWithId(idValue: number): any {
    return idValue ? { id: idValue } : null;
  }

  /**
   * Ritorna la stringa concatenata dal separatore (default spazio)
   * - controlla anche se l'elemento successivo è != null/vuoto prima di inserire il separatore
   */
  smartConcat(data: string[], separator = ' ') {
    let result = '';
    data.forEach((d, index) => {

      const trimmedVal: string = this.checkNullAndTrim(d);
      const isLastElement: boolean = index === (data.length - 1);
      const theresOthers: boolean = !!data.slice(index + 1, data.length).find(val => !!val);

      result += trimmedVal.length ? trimmedVal + ((!isLastElement && theresOthers) ? separator : '') : '';
    });
    return result;
  }

  public replace(text: string, toReplace: string, replaceWith: string) {
    return text ? text.replace(toReplace, replaceWith) : null;
  }

  /**
   * Ritorna stringa vuota se il campo è null + esegue il trim se NON null
   */
  checkNullAndTrim(input: string, substitute?: string) {
    return !!input ? input.trim() : (substitute ? substitute : '');
  }

  /**
   * Ritorna l'array in input ordinato per campo in input di TIPO STRINGA (reverse=true per ordinamento inverso)
   */
  public sortArrayByStringField(array: any[], fieldName: string, reverse?: boolean): any[] {
    if (array) {
      return !reverse ?
        array.sort((f1, f2) => (f1[fieldName]?.localeCompare(f2[fieldName])))
        : array.sort((f1, f2) => (f2[fieldName]?.localeCompare(f1[fieldName])));
    }
    return [];
  }

  /**
   * Ritorna l'array in input ordinato per campo in input di TIPO NUMERICO (reverse=true per ordinamento inverso)
   */
  public sortArrayByNumericField(array: any[], fieldName: string, reverse?: boolean): any[] {
    if (array) {
      return !reverse ? array.sort((f1, f2) => f1[fieldName] - f2[fieldName]) : array.sort((f1, f2) => f2[fieldName] - f1[fieldName]);
    }
    return [];
  }

  /**
   * Per aggiungere una nuova chiave dentro ad un JSON ad una specifica posizione
   * @param obj - oggetto JSON
   * @param index - indice dove si vuole posizionare il nuovo campo
   * @param propToAdd - oggetto nella forma {key: value} che si vuole aggiungere
   */
  addPropertyToObject(obj: any, index: number, propToAdd: any): any {
    const objKeys: string[] = Object.keys(obj);
    objKeys.splice(index, 0, Object.keys(propToAdd)[0]);
    const result = {};
    objKeys.forEach(key => {
      if (obj.hasOwnProperty(key)) {
        // riaggiungo la prop presa dall'oggetto originale
        result[key] = obj[key];
      } else {
        // se non è presente nell'oggetto originale -> è la prop che voglio aggiungere
        result[key] = propToAdd[key];
      }
    });
    return result;
  }

  /**
   * Aggiunge l'item all'array all'index specificato
   */
  addObjToArray(array: any[], index: number, itemToAdd: any): any[] {
    array.splice(index, 0, itemToAdd);
    return array;
  }

  // #################################### METODI UTILITA' SULLE DATE / ORARI ######################################################

  /**
   * Ritorna stringa orario nel formato hh:mm aggiungendo anche gli "0" di padding dove serve
   */
  public getOrario(hh: number, mm: number) {
    return this.padLeft(hh, 2) + ':' + this.padRight(mm, 2);
  }

  /**
   * Ritorna la parte della data da un timestamp in formato stringa JSON (yyyy-MM-ddTHH:mm:ssZ)
   */
  public getDatePart(timestamp: string) {
    return timestamp.substring(0, timestamp.indexOf('T'));
  }

  /**
   * Ritorna le ore e minuti da una data in formato stringa hh:mm
   */
  public getTimeFromStringDate(date: string, format: string) {
    if (date && format && date !== 'Invalid date') {
      const dateConverted: Date = this.convertStringToDate(date, format);
      return this.getOrario(dateConverted.getHours(), dateConverted.getMinutes());
    }
    return null;
  }

  /**
   * USARE QUESTO METODO SE SI VUOLE CONVERTIRE DA UN DATEPICKER --> DATA IN FORMATO STRINGA X BE
   * (è l'unico che mantiene la timezone corretta con l'orario inviando quindi la data corretta e non -2 ore)
   */
  public convertDateToStringUTC(date: Date) {
    if (date) {
      return moment.utc({
        day: date.getDate(), month: date.getMonth(), year: date.getFullYear(),
        hours: date.getHours(), minutes: date.getMinutes(), seconds: date.getSeconds()
      }).toJSON();
    }
    return null;
  }

  public convertDateToStringNoUTC(date: Date) {
    if (date) {
      return moment({
        day: date.getDate(), month: date.getMonth(), year: date.getFullYear(),
        hours: date.getHours(), minutes: date.getMinutes(), seconds: date.getSeconds()
      }).toJSON();
    }
    return null;
  }

  /**
   * Da usare prima di inviare una request rest per convertire la data in UTC senza timezone +2 (FE -> BE)
   */
  public convertDateToMoment(date: Date): Moment {
    if (date) {
      return moment({ day: date.getDate(), month: date.getMonth(), year: date.getFullYear() });
    }
    return null;
  }

  /**
   * Da usare per convertire da BE a FE con timezone +2 (FE -> BE)
   */
  public convertDateToMomentUTC(date: Date): Moment {
    if (date) {
      return moment.utc({ day: date.getDate(), month: date.getMonth(), year: date.getFullYear() });
    }
    return null;
  }

  /**
   * Trasforma la data che si passa in formato NON UTC quindi SENZA il +2 ore (FE -> BE)
   */
  public convertTsToMoment(date: Date): Moment {
    if (date) {
      return moment({
        day: date.getDate(), month: date.getMonth(), year: date.getFullYear(),
        hours: date.getHours(), minutes: date.getMinutes(), seconds: date.getSeconds()
      });
    }
    return null;
  }

  /**
   * Trasforma il timestamp che si passa in formato UTC quindi +2 ore (da usare BE -> FE)
   */
  public convertTsToMomentUTC(date: Date): Moment {
    if (date) {
      return moment.utc({
        day: date.getDate(), month: date.getMonth(), year: date.getFullYear(),
        hours: date.getHours(), minutes: date.getMinutes(), seconds: date.getSeconds()
      });
    }
    return null;
  }

  /**
   * Da usare quando si vuole convertire una data STRING nel formato specificato a --> data Date (usate dai date-picker)
   * bisogna specificare anche il formato della data
   */
  public convertStringToDate(dateString: string, format: string) {
    if (dateString && dateString !== 'Invalid date') {
      if (format === DATE_FORMAT_TABLE) {
        const ggmmaaaa: string[] = dateString.split('/');
        return new Date(Number(ggmmaaaa[2]), Number(ggmmaaaa[1]) - 1, Number(ggmmaaaa[0]));
      } else if (format === DATE_FORMAT_JSON) {
        return new Date(dateString);
      }
    }
    return null;
  }

  public convertToTableFormatDate(date: string, format?: string) {
    return date ? moment(date).format(format ? format : 'DD/MM/YYYY') : null;
  }

  public convertToTableFormatTS(date: string, format?: string) {
    return date ? moment(date).format(format ? format : 'DD/MM/YYYY HH:mm') : null;
  }

  public convertToTableFormatTSWithSeconds(date: string, format?: string) {
    return date ? moment(date).format(format ? format : 'DD/MM/YYYY HH:mm:ss') : null;
  }

  public convertJSONStringToDate(date: string, format?: string) {
    if (date) {
      return format ? this.convertStringToDate(moment(date).format(format), format) : moment(date).toDate();
    }
    return null;
  }

  public getDateMinusXDay(date: Date, days: number) {
    return this.convertTsToMoment(date).subtract(days, 'days').toDate();
  }

  public getDatePlusXDay(date: Date, days: number) {
    return this.convertTsToMoment(date).add(days, 'days').toDate();
  }

  public convertNumericStringToItFormat(item: any, field: string, decimals: number) {
    item[field] = item[field] !== null ? Number(item[field].split('.').join('').replace(',', '.')).toLocaleString("it-IT", {minimumFractionDigits: decimals}) : null;
  }

  public convertNumericStringToPositiveItFormat(item: any, field: string, decimals: number) {
    let convertedStringValue = null;
    if (item[field] !== null) {
      let numericValue = Number(item[field].split('.').join('').replace(',', '.'));
      if (numericValue < 0) {
        numericValue = 0;
      }
      convertedStringValue = numericValue.toLocaleString("it-IT", {minimumFractionDigits: decimals});
    }
    item[field] = convertedStringValue;
  }

  public convertNumberToItString(value: number, decimals: number) {
    return value !== null ? value.toLocaleString("it-IT", {minimumFractionDigits: decimals}) : null;
  }

  public convertItStringToNumber(value: string) {
    return Number(value.split('.').join('').replace(',', '.'))
  }

  /**
   * Compares two Date objects and returns e number value that represents the result:
   * 0 if the two dates are equal.
   * 1 if the first date is greater than second.
   * -1 if the first date is less than second.
   * @param date1 First date object to compare.
   * @param date2 Second date object to compare.
   */
  public compareDate(date1: Date, date2: Date): number {
    if (date1.getTime() === date2.getTime()) {
      return 0;
    }
    if (date1 > date2) {
      return 1;
    }
    if (date1 < date2) {
      return -1;
    }
  }

  public dateIncluded(dateToCheck: Date, dateFrom: Date, dateTo: Date) {
    return dateToCheck.getTime() >= dateFrom.getTime() && dateToCheck < dateTo;
  }

  getDateStartOrEndOfDay(date: Date, startOrEndOfDay: boolean) {
    if (date) {
      const dateCopy: Date = _.cloneDeep(date);
      return startOrEndOfDay ? new Date(dateCopy.setHours(0, 0, 0, 0)) : new Date(dateCopy.setHours(23, 59, 59, 999));
    }
  }

  /**
   * Ritorna la data di OGGI in formato stringa JSON standard format con l'orario desiderato (inizio / fine giornata / corrente)
   * @param startOrEndOfDay - true x inizio, false x fine. Se non settato torna orario corrente
   */
  getTodayString(startOrEndOfDay?: boolean) {
    const today = moment.utc();
    if (startOrEndOfDay != null) {
      return startOrEndOfDay ? today.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSON()
        : today.set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toJSON();
    } else {
      return today.toJSON(); // torna data corrente con orario corrente
    }
  }

  /**
   * Ritorna la data di OGGI + X giorni in formato stringa JSON standard format con l'orario desiderato (inizio / fine giornata / corrente)
   * @param daysToAdd - giorni da aggiungere alla data odierna
   * @param startOrEndOfDay - true x inizio, false x fine. Se non settato torna orario corrente
   */
  getTodayPlusXDaysString(daysToAdd: number, startOrEndOfDay?: boolean) {
    const futureDate = moment.utc().add(daysToAdd, 'days');
    if (startOrEndOfDay != null) {
      return startOrEndOfDay ? futureDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSON()
        : futureDate.set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toJSON();
    } else {
      return futureDate.toJSON(); // torna data corrente con orario corrente
    }
  }

  /**
   * Ritorna la data di DOMANI in formato stringa JSON standard format con l'orario desiderato (inizio / fine giornata)
   * @param startOrEndOfDay - true x inizio, false x fine. Se non settato torna alla mezzanotte di domani
   */
  getTomorrowString(startOrEndOfDay?: boolean) {
    const tomorrow = moment.utc().add(1, 'days');
    return startOrEndOfDay ? tomorrow.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSON()
      : tomorrow.set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toJSON();
  }

  /**
   * Ritorna la data di IERI in formato stringa JSON standard format con l'orario desiderato (inizio / fine giornata)
   * @param startOrEndOfDay - true x inizio, false x fine. Se non settato torna alla mezzanotte di ieri
   */
  getYesterdayString(startOrEndOfDay?: boolean) {
    const yesterday = moment.utc().subtract(1, 'days');
    return startOrEndOfDay ? yesterday.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSON()
      : yesterday.set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toJSON();
  }

  /**
   * Ritorna la data di oggi in formato "Date" con l'orario desiderato (inizio / fine giornata / corrente)
   * @param startOrEndOfDay - true x inizio, false x fine. Se non settato torna orario corrente
   */
  getTodayDate(startOrEndOfDay?: boolean) {
    const now = new Date();
    if (startOrEndOfDay != null) {
      return startOrEndOfDay ? new Date(now.setHours(0, 0, 0, 0)) : new Date(now.setHours(23, 59, 59, 999));
    } else {
      return now; // torna data corrente con orario corrente
    }
  }

  /**
   * Ritorna la data di ieri in formato "Date" con l'orario desiderato (inizio / fine giornata / corrente)
   * @param startOrEndOfDay - true x inizio, false x fine. Se non settato torna orario corrente
   */
  getYesterdayDate(startOrEndOfDay?: boolean) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (startOrEndOfDay != null) {
      return startOrEndOfDay ? new Date(yesterday.setHours(0, 0, 0, 0)) : new Date(yesterday.setHours(23, 59, 59, 999));
    } else {
      return yesterday; // torna data corrente con orario corrente
    }
  }

  /**
   * Ritorna il n° di minuti di differenza tra dt1 e dt2
   */
  diffMinutes(dt2: Date, dt1: Date) {
    if (dt2 && dt1) {
      let diff = (dt2.getTime() - dt1.getTime()) / 1000;
      diff /= 60;
      return Math.abs(Math.round(diff));
    }
    return 0;
  }

  getCalendarLocale(codeLang: string): CalendarConfig {
    if (codeLang === 'en' || codeLang === 'gb') {
      return CALENDAR_EN;
    }
    if (codeLang === 'it') {
      return CALENDAR_IT;
    }
  }

  /* Export in excel di una tabella */
  exportExcel(excelData: any[], fileName: string, sheetName: string) {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(excelData);
      let maxWidths = [];
      if (excelData.length > 0) {
        Object.keys(excelData[0]).forEach(key => {
          maxWidths.push({ wch: Math.max(key.length, ... excelData.map(a => a[key]?.toString()?.length ? a[key]?.toString()?.length : 0)) });
        });
      }
      worksheet["!cols"] = maxWidths;
      const workbook = { Sheets: { [sheetName] : worksheet }, SheetNames: [sheetName] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, fileName);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string) {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + this.datepipe.transform(new Date(), 'yyyy.MM.dd_HH.mm.ss') + EXCEL_EXTENSION);
  }

  resetObject(obj: any) {
    Object.keys(obj).forEach(key => {
      switch (typeof obj[key]) {
        case 'number':
          obj[key] = 0;
          break;
        case 'string':
          obj[key] = '';
          break;
      }
    });
    return obj;
  }

  /**
   * Ritorna una stringa html per badge true/false dentro dyn-table
   */
   getBadge(val: boolean, valTrue: string, valFalse: string): string {
    if (val !== null) {
      return val === true ? DynTableBadgeType.SUCCESS + this.translate.instant(valTrue)
        : DynTableBadgeType.DANGER + this.translate.instant(valFalse);
    }
    return null;
  }

}
