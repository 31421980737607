import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {restServices} from '../../../rest.services';
import {createRequestOption} from '../../../shared/utils/request-util';
import { Classe } from '../../models/entities/classe';
import { AgenteCompletoDTO } from '../../models/dto/agente-completo-dto';
import { UtenteDTO } from '../../models/dto/utente-dto';

type EntityResponseType = HttpResponse<UtenteDTO>;
type EntityArrayResponseType = HttpResponse<UtenteDTO[]>;

@Injectable({providedIn: 'root'})
export class UtenteService {

  resourceUrl = environment.api_url + restServices.utente;

  constructor(protected http: HttpClient) {
  }

  create(utente: UtenteDTO): Observable<EntityResponseType> {
    return this.http.post<UtenteDTO>(this.resourceUrl, utente, {observe: 'response'});
  }

  update(utente: UtenteDTO): Observable<EntityResponseType> {
    return this.http.put<UtenteDTO>(this.resourceUrl, utente, {observe: 'response'});
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<UtenteDTO[]>(this.resourceUrl, {params: options, observe: 'response'});
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<UtenteDTO>(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  getUtenteLoggato(): Observable<HttpResponse<Classe>> {
    return this.http.get<Classe>(`${this.resourceUrl}/${restServices.utenteLoggato}`, {observe: 'response'});
  }

  getVisibilita(username: string): Observable<HttpResponse<number[]>> {
    return this.http.get<number[]>(`${this.resourceUrl}/${restServices.visibilita}/${username}`, {observe: 'response'});
  }

  getAgentiEClassi(): Observable<HttpResponse<AgenteCompletoDTO[]>> {
    return this.http.get<AgenteCompletoDTO[]>(`${this.resourceUrl}/agente-classe`, {observe: 'response'});
  }

}
