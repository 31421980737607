import {Injectable, Optional} from '@angular/core';
import {Subject} from 'rxjs';
import {NgProgress, NgProgressRef} from 'ngx-progressbar';

export class SpinnerServiceConfig {
  spinnerText = '';
  bgColor = '';
  size = '';
  color = '';
  type = '';
  fullScreen = true;
}

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private showSpinner = new Subject();

  private readonly spinnerText: string;
  private readonly bgColor: string;
  private readonly size: string;
  private readonly color: string;
  private readonly type: string;
  private readonly fullScreen: boolean;

  progressBar: NgProgressRef;

  constructor(
    @Optional() config: SpinnerServiceConfig,
    private progressBarService: NgProgress)
  {
    if (config) {
      this.spinnerText = config.spinnerText;
      this.bgColor = config.bgColor;
      this.size = config.size;
      this.color = config.color;
      this.type = config.type;
      this.fullScreen = config.fullScreen;
    }
    this.progressBar = this.progressBarService.ref('progressBar');
  }

  /**
   * return configuration module value
   */
  get config() {
    return {
      spinnerText: this.spinnerText,
      bgColor: this.bgColor,
      size: this.size,
      color: this.color,
      type: this.type,
      fullScreen: this.fullScreen
    };
  }

  getSpinnerStatus() {
    return this.showSpinner;
  }

  activate() {
    this.progressBar.start();
    this.showSpinner.next(true);
  }

  deactivate() {
    this.progressBar.complete();
    this.showSpinner.next(false);
  }
}
