export enum StatoBudget {
  IN_VALIDAZIONE = 'IN VALIDAZIONE',
  IN_CREAZIONE = 'IN CREAZIONE',
  CREATO = 'CREATO',
  APERTO = 'APERTO',
  ATTIVO = 'ATTIVO',
  IN_REVISIONE = 'IN REVISIONE',
  CHIUSO = 'CHIUSO'
}

export const getStatoBudgetList = (): any[] => {
  return Object.keys(StatoBudget).map(key => {
    return { value: key, label: StatoBudget[key] };
  });
};