<nav id="sidebar">
  <ul id="sidebar-list" class="list-unstyled components animate__animated animate__faster">

    <!-- Tasto back con sidebar NON minimizzata -->
    <li class="nav-item cursor-pointer" *ngIf="!isMinimized() && secondLevelActive">
      <a class="nav-link" (click)="backToMainSidebar()">
        <i class="nav-icon fa-fw fas fa-caret-left" style="font-size: 0.85em;"></i>&nbsp;&nbsp;<span
          class="back-text">{{'sidenav.back' | translate}}</span>
      </a>
    </li>
    <!-- Tasto back con sidebar minimizzata -->
    <li class="nav-item cursor-pointer" *ngIf="isMinimized() && secondLevelActive">
      <a class="nav-link" (click)="backToMainSidebar()">
        <i class="nav-icon fa-fw fas fa-caret-left" style="font-size: 0.85em;"></i> <span
          class="back-text">{{'sidenav.back' | translate}}</span>
      </a>
    </li>

    <ng-template ngFor let-item [ngForOf]="navItems">

      <!-- voce menù che apre nuova sidebar -->
      <ng-template [ngIf]="item.hasSidebar">
        <li [hidden]="!item.visibleOnSidebar">
          <a class="nav-item cursor-pointer" (click)="reloadSidebar(item)">
            <i class="nav-icon fa-fw {{item.icon}}"></i>
            <i class="fas fa-fw fa-caret-right pull-right align-middle" style="font-size: 0.85em;"
               [ngClass]="!isMinimized() ? 'mt-2 pl-2' : 'pl-5 pt-2'"></i>
            <span class="nav-item-text">{{item.name | translate}}</span>
          </a>
        </li>
      </ng-template>

      <!-- voce menù senza figli -->
      <ng-template [ngIf]="!item.isDropdown && !item.hasSidebar && (!item.authRoles || hasPermission(profili, item.authRoles))">

        <!-- arr1.some(r=> arr2.indexOf(r) >= 0) -->

        <li [hidden]="!item.visibleOnSidebar">
          <a [routerLink]="item.url" routerLinkActive="active" class="nav-item"
             (click)="!deviceService.isDesktop() ? closeSidebar.emit() : null; closeDropdownMenu(item.keyPage);">
            <i class="nav-icon fa-fw {{item.icon}}"></i>
            <span class="nav-item-text">{{item.name | translate}}</span>
          </a>
        </li>
      </ng-template>

      <!-- 2 livelli -->
      <ng-template [ngIf]="item.isDropdown && item.children && !item.hasSidebar && (!item.authRoles || hasPermission(profili, item.authRoles))">
        <li [hidden]="!item.visibleOnSidebar" class="nav-item">
          <a role="button" href="#menu-{{item.keyPage}}" data-toggle="collapse" data-target="#menu-{{item.keyPage}}"
             class="nav-link dropdown-toggle" id="collapse-{{item.keyPage}}"
             [ngClass]="checkDropdownOnLoading(item.url) ? '' : 'collapsed'"
             aria-expanded="false">
            <i class="nav-icon fa-fw {{item.icon}}"></i>
            <span class="nav-item-text">{{item.name | translate}}</span>
          </a>
          <div class="collapse" id="menu-{{item.keyPage}}" aria-expanded="false" [ngClass]="checkDropdownOnLoading(item.url) ? 'show' : ''">
            <ul class="list-unstyled">
              <ng-template ngFor let-subitem [ngForOf]="item.children">
                <li [hidden]="!subitem.visibleOnSidebar" class="nav-item" *ngIf="!subitem.authRoles || hasPermission(profili, subitem.authRoles)">
                  <a [routerLink]="subitem.url" routerLinkActive="active" class="nav-item"
                     (click)="closeDropdownMenu(item.keyPage); !deviceService.isDesktop() ? closeSidebar.emit() : null;">
                    <i class="nav-icon fa-fw {{subitem.icon}}"></i>
                    <span>{{subitem.name | translate}}</span>
                  </a>
                </li>
              </ng-template>
            </ul>
          </div>
        </li>
      </ng-template>

    </ng-template>

  </ul>
</nav>
