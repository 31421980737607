import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'error',
  template: `
    <div>
      <div class="row">
        <div class="col-md-2">
          <span class="hipster img-fluid rounded"></span>
        </div>
        <div class="col-md-8 mt-5" style="text-align: center">
          <h1 translate>{{'title_error'}}</h1>
          <div *ngIf="errorMessage" class="mt-3">
            <div class="alert bg-secondary text-white">
              <a (click)="backToHome()" class="cursor-pointer">
                <span class="text-white">{{ errorMessage }}&nbsp;{{ 'tornaAllaHome' | translate }}</span>
                &nbsp;<i class="fas fa-fw fa-home fa-2x text-white"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <span class="hipster img-fluid rounded"></span>
        </div>
      </div>
    </div>
  `
})
export class ErrorComponent implements OnInit, OnDestroy {
  errorMessage?: string;
  errorKey?: string;
  langChangeSubscription?: Subscription;

  constructor(
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit() {
    this.route.data.subscribe(routeData => {
      if (routeData.errorMessage) {
        this.errorKey = routeData.errorMessage;
        this.getErrorMessageTranslation();
        this.langChangeSubscription = this.translateService.onLangChange.subscribe(() => this.getErrorMessageTranslation());
      }
    });
  }

  ngOnDestroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  private getErrorMessageTranslation() {
    this.errorMessage = '';
    if (this.errorKey) {
      this.translateService.get(this.errorKey).subscribe(translatedErrorMessage => (this.errorMessage = translatedErrorMessage));
    }
  }

  backToHome() {
    this.router.navigate(['budget']);
  }
}
