import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { USER_KEY } from 'src/app/shared/constants/app.constants';
import { StorageService } from '../services/general-utils/storage.service';

@Injectable()
export class RestHttpInterceptor implements HttpInterceptor {

  constructor(
    private storageService: StorageService) {
  }

  /**
   * Trasforma gli url con path relativi in url con path assoluti nel caso siamo in ambiente locale
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.storageService.getItem(USER_KEY);
    if (authToken) {
      request = request.clone({
        headers: new HttpHeaders({
          Authorization: `Bearer ${authToken}`
        })
      });
    }
    if (environment.changeUrl && request.url.includes('api/')) {
      // flag attivo: devo settare url assoluto invece del relativo "api/...." così funzia anche da localhost
      const absolutePathRequest = request.clone({
        url: environment.app_url + request.url
      });
      return next.handle(absolutePathRequest);
    }
    return next.handle(request);
  }
}
