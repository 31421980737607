import {Injectable, OnDestroy} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {PrimeNGConfig} from 'primeng/api';
import {LANG_KEY} from '../../../shared/constants/app.constants';

/**
 * Pass-through function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 * @return The same string.
 */
export function extract(s: string) {
  return s;
}

@Injectable({
  providedIn: 'root',
})
export class I18nService implements OnDestroy {

  defaultLanguage: string;
  supportedLanguages: string[];
  langSubscription: Subscription;

  constructor(private translateService: TranslateService,
              private primengConfig: PrimeNGConfig) {
  }

  ngOnDestroy() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }

  /**
   * Initializes i18n for the application. (+ traduzioni primeng)
   * Loads language from local storage if present, or sets default language.
   * @param defaultLanguage The default language to use.
   * @param supportedLanguages The list of supported languages.
   */
  init(defaultLanguage: string, supportedLanguages: string[]) {
    this.defaultLanguage = defaultLanguage;
    this.supportedLanguages = supportedLanguages;
    this.langSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      sessionStorage.setItem(LANG_KEY, event.lang);
      this.translateService.get('primeng').subscribe(res => this.primengConfig.setTranslation(res));
    });
    this.language = defaultLanguage; // applichiamo sempre la lingua di default!
  }

  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   * If no parameter is specified, the language is loaded from local storage (if present).
   * @param language The IETF language code to set.
   */
  set language(language: string) {
    language = language || sessionStorage.getItem(LANG_KEY);
    let isSupportedLanguage = this.supportedLanguages.indexOf(language) !== -1;
    // If no exact match is found, search without the region
    if (language && !isSupportedLanguage) {
      language = this.supportedLanguages.find(supportedLanguage => supportedLanguage.startsWith(language));
      isSupportedLanguage = Boolean(language);
    }
    // Fallback if language is not supported
    if (!isSupportedLanguage) {
      language = this.defaultLanguage;
    }
    this.translateService.use(language);
  }

  /**
   * Gets the current language.
   */
  get language(): string {
    return this.translateService.currentLang;
  }

}
