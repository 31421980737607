import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'form-radiobutton-single',
  template: `
    <div class="form-group" [ngClass]="paddingTop ? 'pt-' + paddingTop : ''">
      <p-radioButton [formControl]="control" [value]="value"
                     [label]="label" labelStyleClass="font-weight-bold"
                     (onClick)="control.setValue(value); handleChange.emit($event)">
      </p-radioButton>
    </div>
  `
})
export class FormRadiobuttonSingleComponent {

  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() value: any;
  @Input() paddingTop: number;

  @Output() handleChange: EventEmitter<any> = new EventEmitter();

}
