import {NgModule} from '@angular/core';
import {CalendarModule} from 'primeng/calendar';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {TableModule} from 'primeng/table';
import {TooltipModule} from 'primeng/tooltip';
import {SplitButtonModule} from 'primeng/splitbutton';
import {InputTextModule} from 'primeng/inputtext';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputSwitchModule} from 'primeng/inputswitch';
import {TabViewModule} from 'primeng/tabview';
import {ColorPickerModule} from 'primeng/colorpicker';
import {SharedModule} from 'primeng/api';
import {PanelModule} from 'primeng/panel';
import {RadioButtonModule} from 'primeng/radiobutton';
import {AccordionModule} from 'primeng/accordion';
import {FileUploadModule} from 'primeng/fileupload';
import {CheckboxModule} from 'primeng/checkbox';
import {InputMaskModule} from 'primeng/inputmask';
import {KeyFilterModule} from 'primeng/keyfilter';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {PaginatorModule} from 'primeng/paginator';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TagModule } from 'primeng/tag';
import {PickListModule} from 'primeng/picklist';
import {CardModule} from 'primeng/card';

const primengModules = [
  AccordionModule,
  CalendarModule,
  CheckboxModule,
  ColorPickerModule,
  DialogModule,
  DropdownModule,
  FileUploadModule,
  KeyFilterModule,
  InputMaskModule,
  InputTextModule,
  InputSwitchModule,
  MessageModule,
  MultiSelectModule,
  PaginatorModule,
  PanelModule,
  ProgressBarModule,
  ProgressSpinnerModule,
  RadioButtonModule,
  SharedModule,
  SplitButtonModule,
  TableModule,
  TabViewModule,
  TooltipModule,
  SelectButtonModule,
  TagModule,
  PickListModule,
  CardModule
];

@NgModule({
  imports: [
    ...primengModules
  ],
  exports: [
    ...primengModules
  ],
})
export class PrimengSharedModule {
}
