import { Injectable } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

@Injectable()
export class FormUtilsService {

  public resetAndDisableField(formGroup: FormGroup, controlName: string) {
    formGroup.controls[controlName].reset();
    formGroup.controls[controlName].disable();
  }

  public resetAndEnableField(formGroup: FormGroup, controlName: string) {
    formGroup.controls[controlName].reset();
    formGroup.controls[controlName].enable();
  }

  public clearValidators(formGroup: FormGroup, controlName: string) {
    formGroup.controls[controlName].clearValidators();
    formGroup.controls[controlName].updateValueAndValidity();
  }

  public clearValidatorsMulti(formGroup: FormGroup, controlNames: string[]) {
    controlNames.forEach(c => this.clearValidators(formGroup, c));
  }

  public setValidatorRequired(formGroup: FormGroup, controlName: string) {
    formGroup.controls[controlName].setValidators(Validators.required);
    formGroup.controls[controlName].updateValueAndValidity();
  }

  public setValidatorRequiredMulti(formGroup: FormGroup, controlNames: string[]) {
    controlNames.forEach(c => this.setValidatorRequired(formGroup, c));
  }

  public setValidatorRequiredAndMax(formGroup: FormGroup, controlName: string, maxValue: number) {
    formGroup.controls[controlName].setValidators([Validators.required, Validators.max(maxValue)]);
    formGroup.controls[controlName].updateValueAndValidity();
  }

  public setValidatorMax(formGroup: FormGroup, controlName: string, maxValue: number) {
    formGroup.controls[controlName].setValidators(Validators.max(maxValue));
    formGroup.controls[controlName].updateValueAndValidity();
  }

  public setValidatorMin(formGroup: FormGroup, controlName: string, minValue: number) {
    formGroup.controls[controlName].setValidators(Validators.min(minValue));
    formGroup.controls[controlName].updateValueAndValidity();
  }

}
