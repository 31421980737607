import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DynTableSearchOption } from 'src/app/shared/components/dyn-table';

@Injectable()
export class LivelliApprovazioneService {
  constructor(
    private translate: TranslateService
  ) {}

  getTipiApprovazione(livelloUtente: number):DynTableSearchOption[]  {
    let tipiApprovazione: DynTableSearchOption[] = [{
      label: this.translate.instant('nonApprovato'),
      value: '!',
      color: '#d9534f'
    }, {
      label: this.translate.instant('approvatoLivelloInferiore'),
      value: '<',
      color: '#ff7514'
    }];

    if (livelloUtente !== 0) {
      tipiApprovazione.push({
        label: this.translate.instant('approvatoMioLivello'),
        value: '=',
        color: '#fce300'
      });
      tipiApprovazione.push({
        label: this.translate.instant('approvatoLivelloSuperiore'),
        value: '>',
        color: '#5cb85c'
      });
    } else {
      tipiApprovazione.push({
        label: this.translate.instant('approvato'),
        value: '0',
        color: '#5cb85c'
      });
    }

    return tipiApprovazione;
  }

  getDotTooltip(value: string) {
    if (value != null && value === '>') {
      return this.translate.instant('approvatoLivelloSuperiore');
    } else if (value != null && value === '=') {
      return this.translate.instant('approvatoMioLivello');
    } else if (value != null && value === '<') {
      return this.translate.instant('approvatoLivelloInferiore');
    } else if (value != null && value === '!') {
      return this.translate.instant('nonApprovato');
    } else if (value != null && value === '0') {
      return this.translate.instant('approvato');
    } else {
      return '';
    }
  }

  getDotColor(value: string) {
    if (value != null && value === '>') {
      return { color: '#5cb85c' };
    } else if (value != null && value === '=') {
      return { color: '#fce300' };
    } else if (value != null && value === '<') {
      return { color: '#ff7514' };
    } else if (value != null && value === '!') {
      return { color: '#d9534f' };
    } else if (value != null && value === '0') {
      return { color: '#5cb85c' };
    } else {
      return {};
    }
  }

}
