import {Component, Input} from '@angular/core';

@Component({
  selector: 'page-spinner',
  template: `
    <p-progressSpinner
        *ngIf="size === 'small'"
        [style]="{width: '20px', height: '20px', 'margin-left': '5px'}" styleClass="custom-spinner" strokeWidth="4">
    </p-progressSpinner>
    <p-progressSpinner
        *ngIf="size === 'medium'"
        [style]="{width: '40px', height: '40px', 'margin-left': '5px'}" styleClass="custom-spinner" strokeWidth="4">
    </p-progressSpinner>
    <p-progressSpinner
        *ngIf="size === 'large'"
        [style]="{width: '60px', height: '60px', 'margin-left': '5px'}" styleClass="custom-spinner" strokeWidth="4">
    </p-progressSpinner>
  `,
  styleUrls: ['page-spinner.component.scss']
})
export class PageSpinnerComponent {
  @Input() size: 'small' | 'medium' | 'large' = 'medium'; // default "medium"
  @Input() color: string;
}
