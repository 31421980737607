import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable()
export class FileUtils {

  constructor() {
  }

  downloadFile(content: any, mimeType: string, fileName: string) {
    const blobPart: BlobPart[] = this.convertToBlobPart(content);
    saveAs(new Blob(blobPart, { type: mimeType }), fileName);
  }

  convertToBlobPart(content: any): BlobPart[] {
    const byteCharacters = atob(content);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    return [new Uint8Array(byteNumbers)];
  }

}
