import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  DynTableAction,
  DynTableColSpan,
  DynTableColType,
  DynTableColumn,
  DynTableConfig,
  DynTableLabels,
  DynTablePagination,
  DynTableRowSpan,
  DynTableTypeClick,
  DynTableSearchType,
  DynTableColFilterType,
  CrudParams,
  Criterion
} from './dyn-table.model';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {DomSanitizer} from '@angular/platform-browser';
import {LazyLoadEvent} from 'primeng/api';
import {DatePipe} from '@angular/common';
import {FormBuilder, FormGroup, FormControl} from '@angular/forms';
import {Table} from 'primeng/table';
import {Dialog} from 'primeng/dialog';
import {DeviceDetectorService} from 'ngx-device-detector';
import {DynTableService} from './dyn-table.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'dyn-table',
  templateUrl: './dyn-table.component.html',
  styleUrls: ['./dyn-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class DynTableComponent implements OnInit, OnChanges {

  currentPagination: Partial<DynTablePagination>; // oggetto che tiene traccia della paginazione corrente
  // first = 0;

  constructor(
    private service: DynTableService,
    public sanitizer: DomSanitizer,
    public datepipe: DatePipe,
    public formBuilder: FormBuilder,
    public deviceService: DeviceDetectorService,
    public cdRef: ChangeDetectorRef,
    public translate: TranslateService
  ) {
  }

  @ViewChild('dynTable') dynTable: Table;
  @ViewChild('dialogCustom') dialogCustom: Dialog;

  DynTableColFilterType = DynTableColFilterType;

  @Output() lazyLoadHandler = new EventEmitter(); // evento paginazione
  @Output() notifySelectedListFilter = new EventEmitter<any>(); // emesso quando viene scelto un valore da una select dei filtri
  @Output() routeSelectedValue = new EventEmitter<any>(); // invia oggetto nella forma [value: (valore colonna link), row: (dati completi)]
  @Output() actionButtons = new EventEmitter<any>();
  @Output() callSearchService: EventEmitter<any> = new EventEmitter<any>();
  @Output() resetClicked = new EventEmitter();
  @Output() rowSelected = new EventEmitter<any>();
  @Output() rowUnselected = new EventEmitter<any>();
  @Output() addRowClicked = new EventEmitter();
  @Output() editableCell = new EventEmitter();
  @Output() iconActionClicked = new EventEmitter<any>();
  @Output() switchedValue = new EventEmitter<any>();

  @Input() loading: boolean;
  @Input() config: DynTableConfig; // configurazione della tabella con le varie proprietà
  @Input() columns: Partial<DynTableColumn>[];  // colonne tabella
  @Input() actions: Partial<DynTableAction>[]; // eventuali actions
  // @Input() labels: Partial<DynTableLabels>; // mostra le label per ogni colonna
  @Input() colGroup: Partial<DynTableColSpan>[];  // raggruppamento delle colonne della tabella
  @Input() expandedFields: Partial<DynTableColumn>[]; // campi mostrati sul expander
  @Input() rowSpanMetadata: DynTableRowSpan; // info campi rowspan {chiaveCampo: {index => riga da cui parte il match, size => n° righe rowSpan}}

  @Input() data: any[];  // dati e items della tabella
  @Input() dataKey = 'index';  // campo utilizzato per distinguere univocamente le righe (default progressivo "index")
  @Input() totalData: any[]; // aggiunto x totali "globali" --> permette di mantenere la pagination ma far vedere i totali di TUTTE le righe tornate dal WS
  @Input() actionHeader: string;  // intestazione della colonna action
  @Input() actionColWidth: number; // larghezza della colonna action(ci possono essere diversi pulsanti)
  @Input() total: number; // il numero totale delle pagine restituite dal servizio
  @Input() filterCollapsed = true; // mostra la sezione dei filtri aperta/chiusa
  @Input() inputPagination: Partial<DynTablePagination> = {targetPage: 1, recordsPerPage: 10}; // paginazione default (10 righe x pagina)
  @Input() showFooter = true; // mostra il footer della tabella

  @Input() stateKey: string; // tiene traccia dello stato della tabella (paginazione corrente, eventuali ordinamenti,..)
  @Input() rowGroup: any; // usato per le somme dei sottogruppi (ad. es i totali per anno)

  @Input() selectedRows?: any[]; // righe selezionate con la multi-select (se config.multiSelectable = true)

  @Input() selectableExpander: boolean;
  @Input() expandedRows = {};
  @Input() disableHeaderCheckbox: boolean;

  mapCheckboxState: Map<number, any[]>; // mappa che tiene conto delle selezioni con checkbox (x gestire paginazione..)
  formFilter: FormGroup;
  DynTableSearchType = DynTableSearchType;

  datiCheck: any[];
  tableHeaderCheckboxDisabled: boolean;

  DynTableColType = DynTableColType;

  /**
   * Compone l'oggetto di paginazione per eseguire chiamate verso i rest
   * @param crudParams - parametri primeNG table
   */
  public static getPagination(crudParams): Partial<DynTablePagination> {
    if (crudParams) {
      const targetPage = +crudParams.pagination.startFrom / +crudParams.pagination.size;
      const pagination = {
        targetPage: targetPage + 1,
        recordsPerPage: +crudParams.pagination.size,
      };
      if (crudParams.sorting && crudParams.sorting[0]) {
        if (crudParams.sorting[0].substr(0, 1) === '-') {
          pagination['orderField'] = crudParams.sorting[0].substr(1);
          pagination['orderDirection'] = 'desc';
        } else {
          pagination['orderField'] = crudParams.sorting[0];
          pagination['orderDirection'] = 'asc';
        }
      }
      return pagination;
    }
    return null;
  }

  /**
   * Gestione filtri JPA
   *
   */
   public getSearchJPA(crudParams: CrudParams, dontSplitKeys?: boolean) {
    // se ho 1 solo filtro di ricerca impostato entro QUI:
    if (crudParams && crudParams.criterion && crudParams.criterion.name) {
      const filter = {};
      const splitted: string[] = crudParams.criterion.name.split('.');
      if (splitted.length > 1 && !dontSplitKeys) {
        // gestione chiavi "composte" con più punti "."
        this.createJPAFilter(crudParams.criterion, splitted[0] + '.' + splitted[1], filter);
      } else {
        // gestioni chiavi "normali"
        this.createJPAFilter(crudParams.criterion, crudParams.criterion.name, filter);
      }
      return filter;
    }
    // nestedCriterions: se ho più filtri di ricerca impostati entro QUI:
    if (crudParams && crudParams.criterion && crudParams.criterion.nestedCriterions) {
      const filter = {};
      for (const nestedCriterion of crudParams.criterion.nestedCriterions) {
        const splitted: string[] = nestedCriterion.name.split('.');
        if (splitted.length > 1 && !dontSplitKeys) {
          // gestione chiavi "composte" con più punti "."
          this.createJPAFilter(nestedCriterion, splitted[0] + '.' + splitted[1], filter);
        } else {
          // gestioni chiavi "normali"
          this.createJPAFilter(nestedCriterion, nestedCriterion.name, filter);
        }
      }
      return filter;
    }
    return null;
  }

  private createJPAFilter(criterion: Criterion, filterKey: string, filter: any): void {
    if (criterion.type === DynTableColFilterType.DATE_RANGE) {
      // filtro [gte - lte]
      if (criterion.params?.[0] && criterion.params?.[1]) {
        filter[criterion.name + '.greaterOrEqualThan'] = criterion.params?.[0];
        filter[criterion.name + '.lessOrEqualThan'] = criterion.params?.[1];
        return filter;
      }
      if (criterion.params?.[0]) {
        filter[criterion.name + '.greaterOrEqualThan'] = criterion.params?.[0];
        return filter;
      }
    } else if (criterion.type.startsWith(DynTableColFilterType.TIME_RANGE)) {
      if (criterion.type.endsWith('_from')) {
        filter[criterion.name] = criterion.params?.[0];
        return filter;
      }
      if (criterion.type.endsWith('_to')) {
        filter[criterion.name] = criterion.params?.[0];
        return filter;
      }
    } else {
      // caso "classico" criterion normale: gestisco in base al type
      switch (criterion.type) {
        case DynTableColFilterType.EQUALS:
          filter[filterKey + '.equals'] = criterion.params[0];
          break;
        case DynTableColFilterType.INPUT_CONTAINS:
          filter[filterKey + '.contains'] = criterion.params[0];
          break;
        case DynTableColFilterType.IN_VAL:
          filter[filterKey + '.in'] = criterion.params;
          break;
        case DynTableColFilterType.IN_NUM:
          filter[filterKey + '.in'] = criterion.params;
          break;
        case DynTableColFilterType.SELECT:
          filter[filterKey + '.in'] = criterion.params;
          break;
      }
    }
  }

  /**
   * Compone il JSON di search da aggiungere al filter/input in base ai filtri impostati
   *
   * @param crudParams - parametri "filtri" di ricerca combo-search
   * @param dontSplitKeys - se a true, mantiene le chiavi di ricerca composte nel json con XXX.YYY invece di creare oggetto interno
   */
  public static getSearch(crudParams, dontSplitKeys?: boolean) {

    if (!crudParams) {
      return null;
    }

    // se ho 1 solo filtro di ricerca impostato entro QUI:
    // crudParams criterion->name params oppure nestedCriterions name params type
    if (crudParams.criterion && crudParams.criterion.name) {
      const filter = {};

      if (crudParams.criterion.name.split('.').length > 1 && !dontSplitKeys) {
        if (!filter[crudParams.criterion.name.split('.')[0]]) {
          filter[crudParams.criterion.name.split('.')[0]] = {};
        }
        if (crudParams.criterion.type === 'between') {
          filter[crudParams.criterion.name.split('.')[0]][crudParams.criterion.name.split('.')[1]] = this.getFromTo(
            crudParams.criterion
          );
        } else {
          filter[crudParams.criterion.name.split('.')[0]][crudParams.criterion.name.split('.')[1]] =
            crudParams.criterion.params[0];
        }
      } else {
        if (crudParams.criterion.type === 'between') {
          filter[crudParams.criterion.name] = this.getFromTo(crudParams.criterion);
        } else {
          filter[crudParams.criterion.name] = crudParams.criterion.params[0];
        }
      }
      return filter;
    }

    // se ho più filtri di ricerca impostati entro QUI:
    if (crudParams.criterion && crudParams.criterion.nestedCriterions) {
      const filter = {};
      for (const entry of crudParams.criterion.nestedCriterions) {
        if (typeof filter[entry.name.split('.')[0]] === 'undefined' && !dontSplitKeys) {
          filter[entry.name.split('.')[0]] = {};
        }
        if (entry.type === 'between') {
          if (entry.name.split('.').length > 1) {
            filter[entry.name.split('.')[0]][entry.name.split('.')[1]] = this.getFromTo(entry);
          } else {
            filter[entry.name] = this.getFromTo(entry);
          }
        } else {
          if (entry.name.split('.').length > 1 && !dontSplitKeys) {
            filter[entry.name.split('.')[0]][entry.name.split('.')[1]] = entry.params?.[0];
          } else {
            filter[entry.name] = entry.params?.[0];
          }
        }
      }
      return filter;
    }
  }

  public static getFromTo(entry) {
    if (entry.params?.[0] && entry.params?.[1]) {
      return {from: entry.params?.[0], to: entry.params?.[1]};
    }
    if (entry.params?.[0]) {
      return {from: entry.params?.[0]};
    }
    return null;
  }

  ngOnInit() {
    this.createTableForm();
    this.mapCheckboxState = new Map<number, any[]>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      // deseleziono in automatico le precedenti selezioni!
      this.data = changes.data.currentValue;
      this.clearStateSelection(); // svuoto selezioni nello state della tabella
      this.clearExpandedRowKeys();
      this.getCheckboxSelectedItems(); // carico selezioni checkbox dalla mappa
      this.checkboxHeaderTableDisabled(); // controllo se c'è la condizione checkboxDisabled per almeno una riga.
      // this.expandedRows = {}; // chiudo tutti gli expander
    }
    if (changes.loading) {
      this.loading = changes.loading.currentValue;
    }
    if (changes.inputPagination && changes.inputPagination.currentValue != null) {
      // aggiorno paginazione SOLO SE diversa da null!!
      this.inputPagination = changes.inputPagination.currentValue;
      // IMPORTANTE!!!: fa si che venga aggiornato anche il paginatore...
      if (!changes.inputPagination.isFirstChange()) {
        this.dynTable.first = (this.inputPagination.currentPage - 1) * this.inputPagination.recordsPerPage;
      }
    }
    if (changes.config) {
      this.config = changes.config.currentValue;
    }
    if (changes.dataKey) {
      this.dataKey = changes.dataKey.currentValue;
    }
    if (changes.selectedRows) {
      this.selectedRows = changes.selectedRows.currentValue;
      if (!changes.selectedRows.isFirstChange()) {
        this.dynTable.selection = null;
        this.dynTable.selection = this.selectedRows;
        this.dynTable.updateSelectionKeys();
      }
    }
    if (changes.expandedRows) {
      this.expandedRows = changes.expandedRows.currentValue;
    }
    if (changes.filterCollapsed) {
      this.filterCollapsed = changes.filterCollapsed.currentValue;
    }
  }

  /**
   * Crea il form per i filtri tabella
   */
  createTableForm() {
    if (this.config && this.config.searchFields && this.config.searchFields.length > 0) {
      const group = {};
      for (const searchField of this.config.searchFields) {
        group[searchField.name] = [null, searchField.validators];
      }
      this.formFilter = this.formBuilder.group(group);
      this.formFilter.setValidators(this.atleastOneFieldValidator);
      this.setDisabled();
      this.setDefaultOptions();
    } else if (this.config?.clientSideFilters && this.columns) {
      // creo form anche per i filtri client-side così da poterli resettare
      this.formFilter = new FormGroup({});
      this.columns.forEach(col => {
        if (col.searchable) {
          this.formFilter.addControl(col.name, new FormControl());
        }
      });
    }
  }

  /**
   * Applica il validatore che ALMENO UN campo dei filtri deve essere valorizzato x attivare il pulsante "Search"
   */
  atleastOneFieldValidator(group: FormGroup): { [key: string]: any } {
    let isAtLeastOne = false;
    if (group && group.controls) {
      for (const control in group.controls) {
        if (group.controls.hasOwnProperty(control) && group.controls[control].valid && group.controls[control].value) {
          isAtLeastOne = true;
          break;
        }
      }
    }
    return isAtLeastOne ? null : {required: true};
  }

  /**
   * Disabilita tutti i campi dei filtri che hanno "disabled" a true
   */
  setDisabled() {
    for (const searchField of this.config.searchFields) {
      if (searchField.disabled) {
        this.formFilter.get(searchField.name).disable();
      }
    }
  }

  setDefaultOptions() {
    for (const searchField of this.config.searchFields) {
      if (searchField.defaultOption) {
        this.formFilter.controls[searchField.name].setValue(searchField.defaultOption);
      }
    }
  }

  actionsPresent(): boolean {
    return this.actions && this.actions.length > 0 || this.expansionPresent();
  }

  public expansionPresent(): boolean {
    return (this.expandedFields && this.expandedFields.length > 0) || this.selectableExpander;
  }

  /**
   * Conferma filtri di ricerca - emetto lazyLoadHandler con valori filtri
   */
  public formFilterQuery() {
    const filters = {};
    const values = this.formFilter ? this.formFilter.value : null;
    if (values) {
      for (const prop in values) {
        if (values.hasOwnProperty(prop)) {
          const matchMode = this.getMatchMode(prop);
          if (values[prop]) {
            filters[prop] = {
              matchMode,
              value: values[prop] instanceof Array ? values[prop] : [values[prop]]
            };
          }
        }
      }
    }
    
    // controllo i filtri di ricerca. se sono gli stessi dello state key --> mi recupero la paginazione dal localStorage(significa che sto ricaricando 
    // la pagina, probabilmente a fronte di un aggiornamento del record e non voglio perdere la ricarica della pagina con la paginazione corretta),
    // altrimenti resetto e torno alla prima pagina(significa che è stata inserita una nuova ricerca, qndi resetto la paginazione del localStorage)
    // controllo se c'è lo state key salvato sul localStorage
    const objState = JSON.parse(localStorage.getItem(this.stateKey));
    if (filters && Object.keys(filters).length > 0) {
      if (objState && objState.filters) {
        const sameFilters = _.isEqual(filters, objState.filters);
        // rimango sulla paginazione dello statekey, altrimenti resetto e torno alla prima pagina
        if (sameFilters) {
          this.dynTable.first = objState.first;
        } else {
          this.dynTable.first = 0;
        }
      }
    } else {
      if (objState && objState.first) {
        this.dynTable.first = objState.first;
      } else {
        this.dynTable.first = 0;
      }
    }
    this.dynTable.filters = filters;
    const lazyLoadEvent = this.dynTable.createLazyLoadMetadata();
    // setto lo stateKey nel localStorage se presente
    if (filters && Object.keys(filters).length > 0) {
      if (objState) {
        objState.filters = filters;
        localStorage.setItem(this.stateKey, JSON.stringify(objState));
      }
    }
    this.onLazyLoad(lazyLoadEvent);
    
  }

  getMatchMode(field) {
    for (const searchField of this.config.searchFields) {
      if (searchField.name === field) {
        return searchField.type;
      }
    }
    return 'icontains';
  }

  /**
   * Al reset dei filtri --> invio al chiamante un filtro vuoto così "forzo" il reload di tutti i dati
   */
  resetSearch() {
    if (!this.data) {
      this.data = []; // fa emettere il lazyLoading!
    }
    if (this.config.checkboxes) {
      // rimuovo selezioni con checkbox
      this.clearCheckboxSelections();
    }
    this.resetClicked.emit(); // aggiunto Output x gestione del tasto reset
    this.formFilter.reset();
    this.setDefaultOptions(); // IMPORTANTE: permette di risettare i valori di default configurati
    this.reset();
  }

  reset() {
    if (this.stateKey) {
      localStorage.removeItem(this.stateKey); // forzatura rimozione state dal localstorage (se presente stateKey)
    }
    this.dynTable.reset();
  }

  /**
   * gruppi espandibili
   */
  getExpandedGroups(): any[] {
    const expFields = [];
    for (const entry of this.expandedFields) {
      if (entry.group && expFields.indexOf(entry.group) === -1) {
        expFields.push(entry.group);
      }
    }
    return expFields;
  }

  /**
   * campi dei gruppi espandibili
   */
  getExpandedFields(group?: string): any[] {
    const expFields = [];
    for (const entry of this.expandedFields) {
      if (entry.group === group) {
        expFields.push(entry);
      }
    }
    return expFields;
  }

  /**
   * Evento paginazione (N.B.: lanciato anche al render della tabella se l'array è inizializzato anche vuoto)
   * @param event - contiene dati paginazione / filtri search - sort
   */
  onLazyLoad(event: LazyLoadEvent) {
    const crudParams = this.mapPrimeNgTableParams(Object.assign(event));
    const pagination = DynTableComponent.getPagination(crudParams);
    this.saveCheckboxSelectedItems(); // PRIMA di cambiare paginazione: salvo eventuali selezioni in pagina con le checkbox
    this.currentPagination = pagination; // poi aggiorno paginazione...
    const search = this.getSearchJPA(crudParams);
    const merged = {...search};
    const filter = {filter: merged, pagination};
    if (this.data || (!this.data && event.filters && (Object.keys(event.filters).length > 0))) {
      this.lazyLoadHandler.emit(filter);
    }
  }

  /**
   * get end emit actions button events
   * @param ev - evento
   * @param type of actions
   * @param rowData data row
   */
  actionHandler(ev, type, rowData) {
    const objEmit = {
      event: ev,
      type,
      rowData
    };
    this.actionButtons.emit(objEmit);
  }

  // metodo usato per selezionare una riga della tabella
  handler(event, row) {
    // event.stopPropagation(); // questo fa si che l'evento "click" NON venga propagato anche al click della config (che è nel tag <tr> che contiene le action!!)
    if (this.config.rowClickAction) {
      this.config.rowClickAction(row);
    }
  }

  // metodo usato per abilitare l'action della tabella senza selezionare la riga completa(in modo da evitare la propagazione del evento della riga.
  //  ad.es l'apertura dello storico)
  handlerAction(event, action, row) {
    event.stopPropagation();
    const actionClicked = this.actions.find(el => el.type === action.type);
    actionClicked.onClick(action.type, row);
  }

  // azione usata per aprire solo l'expander senza selezionare tutta la riga della tabella
  actionRowHandler(event, row) {
    event.stopPropagation();
    if (this.actions) {
      this.actions[0].onClick(null, row);
    }
  }

  /**
   * Click sul menu del p-splitbutton
   */
  onMenuClick(event, row) {
    if (row.menuItems) {
      for (const item of row.menuItems) {
        const menuItem = this.actions[0].items.find(k => k.id === item.id);
        menuItem.disabled = item.disabled != null ? item.disabled : false;
        menuItem.visible = item.visible != null ? item.visible : false;
      }
    }
  }

  // mostra il popup in modalità tablet/cell SOLO SE la tabella non è selezionabile (single o multi)
  showCustomDialog(value: any) {
    if (this.dialogCustom && !(this.config.singleSelectable || this.config.multiSelectable)) {
      this.dialogCustom.modal = true;
      this.dialogCustom.dismissableMask = true;
      this.dialogCustom.visible = true;
    }
  }

  researchInputValue(value, formControlName) {
    this.callSearchService.emit({value, name: formControlName});
  }

  /**
   * Selezionato un valore da una select dei filtri
   * @param event - dati dalla select
   * @param id - id della select
   */
  pickedValueFromSelectFilter(event, id) {
    this.notifySelectedListFilter.emit({value: event.value, idSelect: id});
  }

  /**
   * Mappatura di tutti i parametri contenuti nell'evento "lazy" di paginazione e/o filtraggio
   */
  public mapPrimeNgTableParams(params: any) {
    const page = (params.first + params.rows) / params.rows;
    const criterion = {
      type: null,
      name: null,
      params: null,
      nestedCriterions: null
    };
    const context = this;
    if (params.filters) {
      Object.keys(params.filters).forEach(key => {
        if (Object.keys(params.filters).length === 1) {
          // 1 solo filtro impostato
          criterion.type = params.filters[key].matchMode;
          criterion.name = key;
          criterion.params = context.checkParams(params.filters[key].value);
        } else {
          // più filtri impostati --> si mettono in "and"
          if (!criterion.nestedCriterions) {
            criterion.nestedCriterions = [];
          }
          criterion.type = 'and';
          criterion.nestedCriterions.push({
            type: params.filters[key].matchMode,
            name: key,
            params: context.checkParams(params.filters[key].value)
          });
        }
      });
    }
    const pagination = {startFrom: (page - 1) * params.rows, size: params.rows};
    return {
      pagination,
      sorting: params.sortOrder && params.sortField ? [params.sortOrder > 0 ? params.sortField : '-' + params.sortField] : null,
      criterion
    };
  }

  checkParams(oriParam) {
    const outParams = oriParam.slice(0);
    if (outParams.length === 2 && outParams[0] instanceof Date) {
      outParams[0] = this.datepipe.transform(outParams[0], 'yyyy-MM-dd') + ' 00:00:00';
      if (outParams[1] !== null && outParams[1] instanceof Date) {
        outParams[1] = this.datepipe.transform(outParams[1], 'yyyy-MM-dd') + ' 23:59:59';
      } else if (outParams[1] === null) {
        outParams[1] = this.datepipe.transform(outParams[0], 'yyyy-MM-dd') + ' 23:59:59';
      }
    }
    return outParams;
  }

  /**
   * Torna true se abbiamo almeno 1 campo che presenta la prop. "sum"
   */
  hasFooterFields() {
    // se non ci sono dati niente footer
    if (this.data && this.data.length > 0) {
      for (const entry of this.columns) {
        if (entry.sum) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * I campi con sum=true avranno la somma dei valori nel footer (solo numerici)
   * se è presente in input "totalData": la somma verrà fatta su TUTTI i valori
   */
  getFooterFields() {
    const fields = [];
    if (this.actionsPresent()) {
      fields.push({hasFooter: false, style: {width: '65px'}});
    }
    // devo fare le somme per tutte le righe che hanno flag "sum" a true
    for (const entry of this.columns) {
      if (entry.sum) {
        let sum = 0;
        if (this.totalData && this.totalData.length > 0) {
          // se ho "totalData" da input --> faccio la somma globale, altrimenti la faccio x pagina
          for (const row of this.totalData) {
            sum += Number(row[entry.name]);
          }
        } else {
          for (const row of this.data) {
            sum += Number(row[entry.name]);
          }
        }
        fields.push({
          style: {...entry.style, ...entry.cellStyle},
          hasFooter: true,
          value: this.service.formatAmount(sum, true)
        });
      } else {
        fields.push({style: {...entry.style, ...entry.cellStyle}, hasFooter: false});
      }
    }
    return fields;
  }

  getSumGrouped(input) {
    const fields = [];
    for (const entry of this.columns) {
      if (entry.grouped) {
        let sum = 0;
        for (const row of this.data) {
          if (input === row.groupBy) {
            sum += Number(row[entry.name]);
          }
        }
        fields.push({
          style: {...entry.style, ...entry.cellStyle},
          hasFooter: true,
          value: this.service.formatAmount(sum, true)
        });
      } else {
        fields.push({style: {...entry.style, ...entry.cellStyle}, hasFooter: false});
      }
    }
    return fields;
  }

  /** renderizza la colonna in base al tipo colonna configurato in ogni tabella */
  renderField(field: DynTableColumn, value: any) {
    const type = field.type;
    let html = '';
    if (!type) {
      return value;
    }
    if (type === DynTableColType.BOOLEAN) {
      if (value) {
        html = '<i class="fas fa-check" style="color: #4dbd74; font-size: x-large;"></i>';
      } else {
        html = '<i class="fas fa-times" style="color: #f05b60; font-size: x-large;"></i>';
      }
    }
    if (type === DynTableColType.DOTS_APPROVE) {
      if (value != null && value === '>') {
        html = '<i class="fas fa-circle" style="color: #5cb85c; font-size: x-large;" title="' + this.translate.instant('approvatoLivelloSuperiore') + '"></i>';
      } else if (value != null && value === '=') {
        html = '<i class="fas fa-circle" style="color: #fce300; font-size: x-large;" title="' + this.translate.instant('approvatoMioLivello') + '"></i>';
      } else if (value != null && value === '<') {
        html = '<i class="fas fa-circle" style="color: #ff7514; font-size: x-large;" title="' + this.translate.instant('approvatoLivelloInferiore') + '"></i>';
      } else if (value != null && value === '!') {
        html = '<i class="fas fa-circle" style="color: #d9534f; font-size: x-large;" title="' + this.translate.instant('nonApprovato') + '"></i>';
      } else if (value != null && value === '0') {
        html = '<i class="fas fa-circle" style="color: #5cb85c; font-size: x-large;" title="' + this.translate.instant('approvato') + '"></i>';
      }
    }
    if (type === DynTableColType.DOTS) {
      if (value != null && value === true) {
        html = '<i class="fas fa-circle" style="color: #4dbd74; font-size: x-large;"></i>';
      } else if (value != null && value === false) {
        html = '<i class="fas fa-circle" style="color: #f05b60; font-size: x-large;"></i>';
      } else {
        html = value;
      }
    }
    if (type === DynTableColType.ICON_ACTION) {
      html = '<a class="' + value.split(';')[0] + '" ' +
        '(click)="iconActionClicked.emit({row: rowData, actionType: ' + value.split(';')[1] + '})"></a>';
    }
    if (type === DynTableColType.BADGE) {
      html = value ? '<span class="' + value.split(';')[0] + '" style="font-size: 0.8rem;">' + value.split(';')[1] + '</span>'
        : '';
    }
    if (type === DynTableColType.IMAGE) {
      html = '<img src="' + value + '">';
    }
    if (type === DynTableColType.URL) {
      html = '<a href=\'%%\'>%%</a>'.replace(/%%/g, value);
    }
    if (type === DynTableColType.NUMBER) {
      html = value ? value.toLocaleString('it') : '';
    }
    if (type === DynTableColType.DECIMAL) {
      html = value ? value.toLocaleString('it', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '';
    }
    if (type === DynTableColType.NO_DECIMAL) {
      html = value ? value.toLocaleString('it', { maximumFractionDigits: 0, minimumFractionDigits: 0 }) : '';
    }
    if (type === DynTableColType.PERC) {
      html = value ? value.toLocaleString('it', { maximumFractionDigits: 0, minimumFractionDigits: 0 }) + '%' : '';
    }
    if (type === DynTableColType.EURO) {
      html = this.service.formatAmount(value, true);
    }
    if (type === DynTableColType.EURO_NO_DECIMAL) {
      html = this.service.formatAmount(value, false);
    }
    if (type === DynTableColType.EURO_3_DIGITS) {
      html = this.service.formatAmount3Digits(value, true);
    }
    if (type === DynTableColType.EURO_4_DIGITS) {
      html = this.service.formatAmount4Digits(value, true);
    }
    if (type === DynTableColType.DATE) {
      html = value ? this.datepipe.transform(new Date(value), 'dd/MM/yyyy') : '';
    }
    if (type === DynTableColType.DATETIME) {
      html = this.service.convertTimezone(value, true);
    }
    if (type === DynTableColType.CUSTOM) {
      html = value;
    }
    if (type === DynTableColType.ROUTE) {
      html = '<div class="link-like">' + (value != null ? value : '') + '</div>';
    }
    if (type === DynTableColType.ICON_BADGE) {
      // se contiene il ';' significa che è un badge
      if (value.indexOf(';') != -1) {
        html = '<span class="' + value.split(';')[0] + '" style="font-size: 0.8rem;">' + value.split(';')[1] + '</span>';
      } else {
        html = '<i class="' + value.split('--')[0] + '" style="color: #FF6C00; font-size: x-large;" title="' + value.split('--')[1] + '"></i>';
      }
    }
    if (type === DynTableColType.BYTE) {
      html = this.formatByte(value);
    }

    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
 
  /** renderizza la colonna se il tipo è TEXT_COMPARE_ICON */
  renderFieldTextCompareIcon(value: any, iconRightClass: string, iconRightColor: string) {
    let html = value + ' ' + '<i class="' + iconRightClass + '" style="color: ' + iconRightColor + '"></i>';

    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  private formatByte(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  checkColumnType(col) {
    // check x mostrare popup in versione cellulare/tablet
    const dialogTypes = ['custom', 'date', 'datetime', 'euro', 'euroNoDecimal', 'euro3digits', 'url'];
    return !col.edit && dialogTypes.indexOf(col.type) !== -1;
  }

  addRowTable(col) {
    this.addRowClicked.emit(col);
  }

  editeCell(index, col, data) {
    this.editableCell.emit({index, col, data});
  }

  getMargin() {
    return this.config && this.config.marginTable ? this.config.marginTable : 'mt-1 mx-1 mb-1';
  }

  onRowSelect(event) {
    const clickElement: HTMLElement = event.originalEvent.target;
    // caso in cui ho checkbox e riga selezionabile. Se seleziono solo la checkbox non voglio che 
    // si propaghi anche l'evento del selectRow ma solo quello della checkbox
    if (this.config.checkboxes && this.config.singleSelectable) {
      if (clickElement.classList.contains('ui-chkbox-box')) {
        this.saveCheckboxSelectedItems();
        this.rowSelected.emit({data: event.data, typeClick: DynTableTypeClick.CHECKBOX});
      } else {
        this.rowSelected.emit({data: event.data, typeClick: DynTableTypeClick.SINGLEROW});
      }
    } else if (!(clickElement.classList.contains('link-like'))) {
      this.saveCheckboxSelectedItems();
      this.rowSelected.emit(event.data);
    } else {
      // se l'utenza ha cliccato sul link per nuovo tab (identificato dalla classe "link-like") --> non seleziono la riga
      if (this.config.singleSelectable) {
        this.selectedRows = null;
        this.dynTable.selection = this.selectedRows;
      } else if (this.config.multiSelectable) {
        this.selectedRows = this.selectedRows.filter(row => row[this.dataKey] != event.data[this.dataKey]);
        this.dynTable.selection = this.selectedRows;
      }
    }
  }

  onRowUnselect(event) {
    const clickElement: HTMLElement = event.originalEvent ? event.originalEvent.target : null;
    if (this.config.checkboxes && this.config.singleSelectable) {
      // 'ui-chkbox-icon' --> magari vedere se si può trovare qlcs di più stringente
      if (clickElement && clickElement.classList.contains('ui-chkbox-icon')) {
        this.saveCheckboxSelectedItems();
        this.rowUnselected.emit({data: event.data, typeClick: DynTableTypeClick.CHECKBOX});
      } else {
        this.rowUnselected.emit({data: event.data, typeClick: DynTableTypeClick.SINGLEROW});
      }
    } else {
      this.saveCheckboxSelectedItems();
      this.rowUnselected.emit(event.data);
    }
    // if (this.config.checkboxes && event.data.children) {
    //   // gestione checkbox "figlie" nell'expander --> nel container verranno settate le giuste selezioni
    //   this.dynTable.selection = null;
    // }
  }

  // caso in cui header checkbox selezionato
  checkboxSelectAll(event) {
    // da sistemare a livello di codice
    // caso in cui riga e check selezionabili entrambi
    if (this.config.checkboxes && this.config.singleSelectable) {
      this.saveCheckboxSelectedItems();
      if (event.checked) {
        for (let data of this.data) {
          this.rowSelected.emit({data: data, typeClick: DynTableTypeClick.CHECKBOX});
        }
      } else {
        for (let data of this.data) {
          this.rowUnselected.emit({data: data, typeClick: DynTableTypeClick.CHECKBOX});
        }
      }
    } else {
      this.saveCheckboxSelectedItems();
      if (event.checked) {
        for (let data of this.data) {
          this.rowSelected.emit(data);
        }
      } else {
        for (let data of this.data) {
          this.rowUnselected.emit(data);
        }
      }
    }

  }

  /**
   * Svuota le selezioni + le selezioni con checkbox
   */
  public clearCheckboxSelections() {
    this.selectedRows = [];
    this.dynTable.selection = []; // IMPORTANTE!!
    this.dynTable.updateSelectionKeys();
    this.mapCheckboxState.clear();
    this.clearStateSelection();
  }

  /**
   * Svuota SOLO le selezioni nello state della tabella
   */
  public clearStateSelection() {
    if (this.stateKey != null) {
      // rimuovo selezione anche dallo state!
      let stateUpdate: any = JSON.parse(localStorage.getItem(this.stateKey));
      if (stateUpdate != null) {
        stateUpdate.selection = [];
        localStorage.setItem(this.stateKey, JSON.stringify(stateUpdate));
      }
    }
  }

  public resetSelection() {
    this.selectedRows = [];
    this.dynTable.selection = []; // IMPORTANTE!!
  }

  /**
   * Metodo che usa il service x salvare i dati selezionati con eventuali checkbox
   */
  public saveCheckboxSelectedItems() {
    if (this.currentPagination && this.config.checkboxes && this.selectedRows != null) {
      this.mapCheckboxState.set(this.currentPagination.targetPage, this.selectedRows);
    }
  }

  /**
   * Metodo che usa il service x prelevare le righe selezionate con eventuali checkbox per la pagina corrente
   */
  public getCheckboxSelectedItems() {
    if (this.currentPagination && this.config.checkboxes && this.mapCheckboxState) {
      this.selectedRows = this.mapCheckboxState.get(this.currentPagination.targetPage);
    }
  }

  public checkboxHeaderTableDisabled() {
    this.tableHeaderCheckboxDisabled = this.data && this.data.some(elem => elem.checkboxDisabled && elem.checkboxDisabled == true);
  }

  checkParentSelected(rowData): boolean {
    return this.selectedRows && this.selectedRows.find(row => row[this.dataKey] === rowData[this.dataKey]);
  }

  switchChange(row, indexTable) {
    this.switchedValue.emit({checked: row.checked, index: indexTable});
  }

  // metodo che serve per chiudere l'expanded row tra una pagina e l'altra. se c'è lo stateKey, svuota e chiude la property.
  clearExpandedRowKeys() {
    if (this.stateKey != null) {
      // rimuovo selezione anche dallo state!
      let stateUpdate: any = JSON.parse(localStorage.getItem(this.stateKey));
      if (stateUpdate != null) {
        stateUpdate.expandedRowKeys = [];
        this.expandedRows = {};
        localStorage.setItem(this.stateKey, JSON.stringify(stateUpdate));
      }
    }
  }

  /**
   * Gestione input dalle maschere filtri "client"
   * @param val - valore dalla maschera
   * @param colName - colonna x cui filtrare la tabella
   * @param searchMethod - metodo di ricerca (contains, equals...)
   */
  searchFromMask(val: string, colName: string, searchMethod: string) {
    const value = val.indexOf('_') !== -1 ? val.substring(0, val.indexOf('_')) : val;
    this.dynTable.filter(value, colName, searchMethod ? searchMethod : 'contains');
  }

  /**
   * Selezione da filtro multiselect
   */
  onMultiselectChange(event, colName: string) {
    this.dynTable.filter(event.value, colName, 'in');
  }

}
