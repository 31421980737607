import { AfterContentInit, Component, ContentChildren, Directive, Input, OnDestroy, QueryList, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[formRowTemplate]'
})
// tslint:disable-next-line:directive-class-suffix
export class FormRowTemplate {
  // tslint:disable-next-line:no-input-rename
  @Input() formRowTemplate: string; // chiave x template
  @Input() colSize: number; // larghezza della colonna (1-12)
  @Input() colAlign: null | 'text-center' | 'text-left' | 'text-right'; // classe allineamento del contenuto della colonna

  constructor(public template: TemplateRef<any>) {
  }
}

@Component({
  selector: 'form-row',
  template: `
    <div class="d-flex w-100 flex-wrap" [ngClass]="centerContent ? 'text-center' : ''" 
         *ngIf="contentTemplates && contentTemplates.length > 0">
      <ng-template [ngIf]="contentTemplates.length === 1">
        <div [ngClass]="[colsSize[0] ? getColSizeClasses(colsSize[0]) : getColSizeClasses(12), colsAlign[0] ? colsAlign[0] : '']">
          <ng-container *ngTemplateOutlet="contentTemplates[0]"></ng-container>
        </div>
      </ng-template>
      <ng-template [ngIf]="contentTemplates.length === 2">
        <div [ngClass]="[colsSize[0] ? getColSizeClasses(colsSize[0]) : getColSizeClasses(6), colsAlign[0] ? colsAlign[0] : '']">
          <ng-container *ngTemplateOutlet="contentTemplates[0]"></ng-container>
        </div>
        <div [ngClass]="[colsSize[1] ? getColSizeClasses(colsSize[1]) : getColSizeClasses(6), colsAlign[1] ? colsAlign[1] : '']">
          <ng-container *ngTemplateOutlet="contentTemplates[1]"></ng-container>
        </div>
      </ng-template>
      <ng-template [ngIf]="contentTemplates.length === 3">
        <div [ngClass]="[colsSize[0] ? getColSizeClasses(colsSize[0]) : getColSizeClasses(4), colsAlign[0] ? colsAlign[0] : '']">
          <ng-container *ngTemplateOutlet="contentTemplates[0]"></ng-container>
        </div>
        <div [ngClass]="[colsSize[1] ? getColSizeClasses(colsSize[1]) : getColSizeClasses(4), colsAlign[1] ? colsAlign[1] : '']">
          <ng-container *ngTemplateOutlet="contentTemplates[1]"></ng-container>
        </div>
        <div [ngClass]="[colsSize[2] ? getColSizeClasses(colsSize[2]) : getColSizeClasses(4), colsAlign[2] ? colsAlign[2] : '']">
          <ng-container *ngTemplateOutlet="contentTemplates[2]"></ng-container>
        </div>
      </ng-template>
      <ng-template [ngIf]="contentTemplates.length === 4">
        <div [ngClass]="[colsSize[0] ? getColSizeClasses(colsSize[0]) : getColSizeClasses(3), colsAlign[0] ? colsAlign[0] : '']">
          <ng-container *ngTemplateOutlet="contentTemplates[0]"></ng-container>
        </div>
        <div [ngClass]="[colsSize[1] ? getColSizeClasses(colsSize[1]) : getColSizeClasses(3), colsAlign[1] ? colsAlign[1] : '']">
          <ng-container *ngTemplateOutlet="contentTemplates[1]"></ng-container>
        </div>
        <div [ngClass]="[colsSize[2] ? getColSizeClasses(colsSize[2]) : getColSizeClasses(3), colsAlign[2] ? colsAlign[2] : '']">
          <ng-container *ngTemplateOutlet="contentTemplates[2]"></ng-container>
        </div>
        <div [ngClass]="[colsSize[3] ? getColSizeClasses(colsSize[3]) : getColSizeClasses(3), colsAlign[3] ? colsAlign[3] : '']">
          <ng-container *ngTemplateOutlet="contentTemplates[3]"></ng-container>
        </div>
      </ng-template>
      <ng-template [ngIf]="contentTemplates.length > 4">
        <ng-template ngFor let-templateRef [ngForOf]="contentTemplates" let-i="index" [ngForTrackBy]="trackByFn">
          <div [ngClass]="[colsSize[i] ? getColSizeClasses(colsSize[i]) : 'col-xs col-sm col-md col-lg', colsAlign[i] ? colsAlign[i] : '']">
            <ng-container *ngTemplateOutlet="contentTemplates[i]"></ng-container>
          </div>
        </ng-template>
      </ng-template>
    </div>
  `
})
export class FormRowComponent implements AfterContentInit, OnDestroy {

  @Input() centerContent: boolean;

  @ContentChildren(FormRowTemplate) containerList: QueryList<FormRowTemplate>;
  contentTemplates: TemplateRef<any>[] = []; // template specifici dei singoli input
  colsSize: number[] = []; // dimensioni "custom" delle colonne
  colsAlign: string[] = []; // dimensioni "custom" delle colonne
  templateSubscription: Subscription;

  constructor() {
  }

  ngAfterContentInit() {
    this.renderTemplates();
    this.templateSubscription = this.containerList.changes.subscribe(() => {
      this.contentTemplates = [];
      this.colsSize = [];
      this.renderTemplates();
    });
  }

  ngOnDestroy() {
    this.templateSubscription.unsubscribe();
  }

  renderTemplates() {
    this.containerList.forEach((item) => {
      this.contentTemplates.push(item.template);
      this.colsSize.push(item.colSize);
      this.colsAlign.push(item.colAlign);
    });
  }

  trackByFn(index, item) {
    return index;
  }
  
  getColSizeClasses(size: number): string {
    return 'col-xs-' + size + ' col-sm-' + size + ' col-md-' + size + ' col-lg-' + size;
  }

}
