import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Calendar } from 'primeng/calendar';

@Component({
  selector: 'form-month-calendar',
  template: `
    <div [ngClass]="[applyPadding ? 'form-group' : '', marginTop ? 'mt-' + marginTop : '', marginBottom ? 'mb-' + marginBottom : '']">
      <label class="col-form-label font-weight-bold">{{label}}</label>
      <p-calendar
          view="month"
          [style]="{'width':'100%', 'padding-right': '1rem'}"
          [inputStyle]="{'height': 'calc(1.5em + 0.75rem + 2px)', 
            'width':  inputManualWidth ? inputManualWidth + '%' : (deviceDetector.isDesktop() ? '90%' : '85%')}"
          [inputStyleClass]="(highlight ? 'highlightBorders' : '')"
          [yearRange]="yearRange" dateFormat="mm/yy"
          [showIcon]="true" [showButtonBar]="true" [monthNavigator]="monthNavigator" [yearNavigator]="yearNavigator"
          [placeholder]="placeholder ? placeholder : 'MM/AAAA'"
          [formControl]="control"
          [selectionMode]="selectionMode"
          [minDate]="minDate" [maxDate]="maxDate" [defaultDate]="defaultDate" 
          [disabledDates]="disabledDates"
          [baseZIndex]="3002"
          [touchUI]="deviceDetector.isMobile()"
          [numberOfMonths]="numberOfMonths"
          [appendTo]="appendToBody ? 'body' : null"
          [readonlyInput]="readonlyInput"
          (onClearClick)="dateResetted.emit()"
          (onClose)="panelClosed.emit(); control.value ? dateSelected.emit(control.value) : false"
          (onInput)="dateSelected.emit(control.value)"
          (keydown.enter)="dateSelected.emit(control.value); forceClose();"
          (keydown.tab)="dateSelected.emit(control.value); forceClose();"
          #calendarRef
          multipleSeparator=" ">
      </p-calendar>
      <div *ngIf="errorMsg" class="error-message">{{errorMsg}}</div>
    </div>
  `,
  styles: [`
      ::ng-deep .ui-datepicker {
          max-width: 100% !important;
          overflow-x: auto;
          position: absolute;
      }
      ::ng-deep input.highlightBorders {
          border-top: 2px solid #960A2C;
          border-bottom: 2px solid #960A2C;
          border-left: 2px solid #960A2C;
      }
      ::ng-deep .p-button {
        background-color: black;
        border: 1px solid black;
      }
  `],
})
export class FormMonthCalendarComponent {

  @ViewChild('calendarRef') calendarRef: Calendar;

  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() placeholder;
  @Input() errorMsg: string;
  @Input() appendToBody: boolean;
  @Input() highlight: boolean; // flag usato per "evidenziare" l'input del calendar

  @Input() defaultDate: Date; // data di default di apertura settabile
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() disabledDates: Date[];
  @Input() selectionMode = 'single'; // di default singola, possibili: "multiple" e "range"
  @Input() numberOfMonths = 1; // numero di mesi visualizzabili nel datepicker
  @Input() inputManualWidth: number; // % lunghezza dell'input del p-calendar
  @Input() applyPadding = true; // se settata a false rimuove la classe "form-group"
  @Input() marginTop: number;
  @Input() marginBottom: number;
  @Input() monthNavigator = true;
  @Input() yearNavigator = true;
  @Input() readonlyInput = false;
  @Input() yearRange = '2001:2040';

  @Output() dateSelected: EventEmitter<Date> = new EventEmitter();
  @Output() dateResetted: EventEmitter<void> = new EventEmitter();
  @Output() panelClosed: EventEmitter<void> = new EventEmitter();

  constructor(public deviceDetector: DeviceDetectorService) {
  }

  public forceClose() {
    this.calendarRef.hideOverlay();
  }

}
