import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'form-input-switch',
  template: `
    <div [ngClass]="applyPadding ? 'form-group' : ''">
      <label class="col-form-label font-weight-bold">{{label}}</label>&nbsp;
      <br *ngIf="!labelInline">
      <p-inputSwitch [formControl]="control" (onChange)="handleChange.emit($event)"></p-inputSwitch>
    </div>
  `,
})
export class FormInputSwitchComponent {

  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() applyPadding = true; // se settata a false rimuove la classe "form-group"
  @Input() labelInline = false; // per label in riga con lo switch

  @Output() handleChange: EventEmitter<any> = new EventEmitter();

}
