// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  changeUrl: true,
  app_url: 'https://eproject-budget-dev.e-projectsrl.net/', // ambiente demo eprojectdemo
  api_eprojectdemo_url: 'services/eprojectdemo/api/', // ambiente demo eprojectdemo
  // app_url: 'https://demo-budget-dev.e-projectsrl.net/', // ambiente demo clientdemoms
  // api_eprojectdemo_url: 'services/clientdemoms/api/', // ambiente demo clientdemoms
  api_url: 'api/',
  appVersion: require('../../package.json').version,
  supportedLanguages: ['it', 'gb'],
  imgFolder: 'eproject',
  appName: 'BudJet',
  appPrimaryColor: '#004d99'
};

export const tableColumnsOrder = {
  clienteBudget: ['dataUltimaModifica', 'utUltimaApprovazione', 'businessUnit', 'agente', 'areaGeografica', 'cliente', 'dettaglioCliente', 
    'bdgPz', 'bdgE', 'costoTotale', 'margine', 'consPzAnnoCorrente', 'consValoreAnnoCorrente', 'consPzAnnoPrecedente', 'consValoreAnnoPrecedente'],
  clienteRevisione: ['utUltimaApprovazione', 'dataUltimaModifica', 'cliente', 'areaGeografica', 'agente', 'businessUnit', 'dettaglioCliente', 
    'bdgPz', 'bdgPzRevPrec', 'bdgE', 'bdgERevPrec', 'costoTotale', 'margine', 'consPortPz', 'consPortE', 'consPortCosto', 'consPortMargine', 
    'consPortPzPrec', 'consPortEPrec'],
  clienteDettaglioBudget: ['dataUltimaModifica', 'tipo', 'serie', 'modello', 'articolo', 'dettaglioArticolo', 'unitaMisura', 'alert', 
    'bdgPzRevPrec', 'prezzoMedioE', 'bdgRevPrecE', 'costoTotale', 'margine', 'consPzAnnoCorrente', 'consValoreAnnoCorrente', 'consPzAnnoPrecedente', 
    'consValoreAnnoPrecedente'],
  clienteDettaglioRevisione: ['dataUltimaModifica', 'tipo', 'serie', 'modello', 'articolo', 'dettaglioArticolo', 'unitaMisura', 'alert', 
    'bdgPzRevPrec', 'prezzoMedioE', 'bdgRevPrecE', 'costoTotale', 'margine', 'consPortPz', 'consPortE', 'consPortCosto', 'consPortMargine', 
    'cpPzAnnoPrecedente', 'cpValoreAnnoPrecedente'],
  articoloBudget: ['dataUltimaModifica', 'tipo', 'serie', 'modello', 'articolo', 'unitaMisura', 'alert', 'bdgPzRevPrec', 
    'prezzoMedioE', 'bdgRevPrecE', 'costoTotale', 'margine', 'consPzAnnoCorrente', 'consValoreAnnoCorrente', 'consPzAnnoPrecedente', 
    'consValoreAnnoPrecedente'],
  articoloRevisione: ['dataUltimaModifica', 'tipo', 'serie', 'modello', 'articolo', 'unitaMisura', 'alert', 'bdgPzRevPrec',
    'prezzoMedioE', 'bdgRevPrecE', 'costoTotale', 'margine', 'consPortPz', 'consPortE', 'consPortCosto', 'consPortMargine', 
    'cpPzAnnoPrecedente', 'cpValoreAnnoPrecedente'],
  articoloDettaglioBudget: ['dataUltimaModifica', 'businessUnit', 'agente', 'areaGeografica', 'cliente', 'bdgPzRevPrec', 'prezzoMedioE', 'bdgE', 
    'costoTotale', 'margine', 'consPzAnnoCorrente', 'consValoreAnnoCorrente', 'consPzAnnoPrecedente', 'consValoreAnnoPrecedente'],
  articoloDettaglioRevisione: ['dataUltimaModifica', 'businessUnit', 'agente', 'areaGeografica', 'cliente', 'bdgPzRevPrec', 'prezzoMedioE', 'bdgE', 
    'bdgERevPrec', 'costoTotale', 'margine', 'consPortPz', 'consPortE', 'consPortCosto', 'consPortMargine', 'consPortPzPrec', 'consPortEPrec']
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
