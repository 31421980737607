<div class="wrapper">
  <ng-progress [spinner]="false" id="progressBar" [color]="progressColor" [thick]="true"></ng-progress>
  <p-toast></p-toast>
  <!-- sidebar -->
  <sidebar [navItems]="navItems" class="main-sidebar" id="main_sidebar" (closeSidebar)="closeSidebar()"></sidebar>
  <!-- navbar -->
  <navbar (closeSidebar)="toggleSidebar()"></navbar>
  <main role="main" class="main-content">
    <!-- breadcrumbs -->
    <breadcrumbs></breadcrumbs>
    <div class="position-relative">
      <!-- container per spinner a tutto schermo -->
      <spinner></spinner>
      <div class="route-container">
        <!-- container principale routing -->
        <router-outlet></router-outlet>
      </div>
    </div>
  </main>
  <footer class="footer">
    <div class="container text-right mr-0">
      <span class="ml-auto">Version {{storageService.getItemSession(VERSION_KEY)}}</span> &nbsp;&nbsp;
      Powered by <a href="https://www.e-projectsrl.it/" target="_blank" class="font-weight-bold text-white">e-project s.r.l.</a>
    </div>
  </footer>
  <div id="blockScreen" class="overlay" (click)="closeSidebar()"></div>
</div>
