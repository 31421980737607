import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'form-input-number',
  template: `
    <div class="form-group">
      <label class="col-form-label font-weight-bold">{{label}}</label>
      <input type="text" class="form-control" 
             [class.is-invalid]="control.invalid"
             [placeholder]="placeholder" 
             [formControl]="control"
             numeric [decimals]="decimals" #htmlInputElement
             (change)="valueChanged.emit($event);"
             (input)="onInput.emit();">
      <div *ngIf="errorMsg" class="invalid-feedback">{{errorMsg}}</div>
    </div>
  `,
})
export class FormInputNumberComponent {

  @ViewChild('htmlInputElement') htmlInputElement: ElementRef;

  @Input() control: AbstractControl;
  @Input() decimals = 0; // di default 0 decimali (numeri interi)
  @Input() label: string;
  @Input() placeholder: string;
  @Input() errorMsg: string;

  @Output() valueChanged: EventEmitter<string> = new EventEmitter();
  @Output() onInput: EventEmitter<string> = new EventEmitter();
}
