import {Component, OnInit, Renderer2, RendererFactory2} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {DeviceDetectorService} from 'ngx-device-detector';
import {filter} from 'rxjs/operators';
import {navItems} from '../../../_nav';
import {NavItem} from '../../models/general/nav-item';
import {APP_VERSION_KEY, LANGUAGES} from '../../../shared/constants/app.constants';
import {AccountService} from '../../services/rest/account.service';
import {I18nService} from '../../services/general-utils/i18n.service';
import {StorageService} from '../../services/general-utils/storage.service';
import { environment } from 'src/environments/environment';

export interface CodeLabel {
  code: string;
  label: string;
}

@Component({
  selector: 'ab-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  navItems: NavItem[] = navItems;
  navStart: Observable<NavigationEnd>;
  differ: any;
  languages: CodeLabel[] = [];
  VERSION_KEY = APP_VERSION_KEY;
  progressColor: string;

  private renderer: Renderer2;
  accountStatus$: Observable<any | null>;

  constructor(
    public accountService: AccountService,
    private rootRenderer: RendererFactory2,
    public deviceDetector: DeviceDetectorService,
    public router: Router,
    public storageService: StorageService,
    private i18nService: I18nService) {

    this.renderer = rootRenderer.createRenderer(document.querySelector('html'), null);
    this.progressColor = environment.appPrimaryColor;

    this.navStart = router.events.pipe(
      filter(evt => evt instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;

    const supported = this.i18nService.supportedLanguages;
    supported.forEach(sl => {
      if (LANGUAGES.hasOwnProperty(sl)) {
        this.languages.push({code: sl, label: LANGUAGES[sl]});
      }
    });
  }

  ngOnInit() {
    // try to log in automatically
    this.accountStatus$ = this.accountService.identity();
    this.accountStatus$.subscribe(account => {
      if (!account) {
        this.router.navigate(['/login']);
      }
    });
  }

  toggleSidebar() {
    if (this.deviceDetector.isDesktop()) {
      document.getElementById('main_sidebar').classList.toggle('isMinimized'); // per gestione z-index!
      document.getElementById('sidebar').classList.toggle('minimized');
      this.closeDropdownMenu();
    } else {
      // gestione "mobile"
      document.getElementById('sidebar').classList.toggle('active');
      document.getElementById('blockScreen').classList.toggle('active');
    }
  }

  /**
   * Metodo che al click sul menù di una dropdown, se la sidebar è minimizzata, chiude la dropdown automaticamente
   */
  closeDropdownMenu(keyPage?: string) {
    if (document.getElementById('sidebar').classList.contains('minimized')) {
      // sidebar minimizzata --> chiusura di TUTTI i dropdown:
      this.navItems.filter(navItem => navItem.isDropdown).forEach(dropdown => {
        document.getElementById('collapse-' + dropdown.keyPage)?.classList.add('collapsed');
        document.getElementById('collapse-' + dropdown.keyPage)?.setAttribute('aria-expanded', 'false');
        document.getElementById('menu-' + dropdown.keyPage)?.classList.remove('show');
      });
    } else if (keyPage) {
      // sidebar NON minimizzata --> chiude tutte LE ALTRE dropdown eventualmente aperte
      this.navItems.filter(navItem => navItem.isDropdown && navItem.keyPage !== keyPage).forEach(dropdown => {
        document.getElementById('collapse-' + dropdown.keyPage).classList.add('collapsed');
        document.getElementById('collapse-' + dropdown.keyPage).setAttribute('aria-expanded', 'false');
        document.getElementById('menu-' + dropdown.keyPage).classList.remove('show');
      });
    }
  }

  closeSidebar() {
    // se mobile --> al click sul blocca schermo chiudo la sidebar in automatico e tolgo il "blocco":
    document.getElementById('sidebar').classList.remove('active');
    document.getElementById('blockScreen').classList.remove('active');
  }

}
