import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'form-upload',
  template: `
    <div class="w-100">
      <label class="font-weight-bold" *ngIf="label">{{label}}</label>
      <div class="input-group mb-3">
        <div class="input-group-prepend" *ngIf="showTemplateBtn">
          <button class="cursor-pointer input-group-text" type="button" [pTooltip]="'downloadTemplate'|translate"
            (click)="downloadTemplate.emit($event)">
            <i class="fas fa-download"></i></button>
        </div>
        <div class="input-group-prepend" *ngIf="file">
          <button class="cursor-pointer input-group-text" type="button" [pTooltip]="'rimuoviFile'|translate" 
            (click)="clearValue()"><i class="fas fa-trash"></i></button>
        </div>
        <div class="custom-file">
          <input type="file" name="sampleFile" class="custom-file-input" (change)="fileSelected($event)"
                 placeholder="{{placeholder}}" #inputUploadRef
                 [class.is-invalid]="isRequired && !file">
          <label class="custom-file-label" *ngIf="file">{{file?.name}}</label>
          <label class="custom-file-label" *ngIf="!file"></label>
        </div>
      </div>
    </div>
  `,
  styles: ['.cursor-pointer {cursor: pointer}']
})
export class FormUploadComponent {

  @ViewChild('inputUploadRef') inputUploadRef: ElementRef;

  @Input() label: string;
  @Input() placeholder: string;
  @Input() isRequired: boolean;
  @Input() showTemplateBtn = false;

  @Output() selectedFile: EventEmitter<any> = new EventEmitter();
  @Output() removedFile: EventEmitter<void> = new EventEmitter();
  @Output() downloadTemplate: EventEmitter<any> = new EventEmitter();

  file: File;

  fileSelected(event) {
    this.selectedFile.emit(event);
    this.file = event.target.files[0];
  }

  clearValue() {
    this.inputUploadRef.nativeElement.value = '';
    this.file = null;
    this.removedFile.emit();
  }

}
