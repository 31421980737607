import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule, DatePipe, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpClientXsrfModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../../environments/environment';
import {AuthGuard} from './guards/auth.guard';
import {NgxSpinnerModule} from 'ngx-spinner';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ErrorHandlerInterceptor} from './interceptors/errorhandler.interceptor';
import {NgProgressModule} from 'ngx-progressbar';
import {SpinnerModule} from '../shared/components/spinner/spinner.module';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {BreadcrumbsComponent, ErrorComponent, MainComponent, NavbarComponent, SidebarComponent} from './layouts';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {RestHttpInterceptor} from './interceptors/rest-http.interceptor';
import {LoginComponent} from './login';
import {I18nService} from './services/general-utils/i18n.service';
import {AccountService} from './services/rest/account.service';
import {RouterBackService} from './services/general-utils/router-back.service';
import {StateStorageService} from './services/general-utils/state-storage.service';
import {StorageService} from './services/general-utils/storage.service';
import {UtilsService} from './services/general-utils/utils.service';
import {FormUtilsService} from './services/general-utils/form-utils.service';
import {AppRoutingModule} from '../app.routing';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import { SharedModule } from '../shared/shared.module';
import { AuthenticationService } from './services/rest/authentication.service';
import { ModelService } from './services/general-utils/model.service';
import { PrimengTableService } from './services/general-utils/primng-table.service';
import { PrimengTableExportService } from './services/general-utils/primeng-table-export.service';
import { LivelliApprovazioneService } from './services/general-utils/livelli-approvazione.service';
import { PrimengTableFiltersService } from './services/general-utils/primeng-table-filters.service';
import { BudgetTooltipService } from './services/general-utils/budget-tooltip.service';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';


// FIXME - SCOMMENTARE qui e sotto se si hanno più file di traduzione
//  import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  // return new MultiTranslateHttpLoader(http, [
  //     {prefix: './assets/i18n/', suffix: '.json'}, // traduzioni standard
  //     {prefix: './assets/i18n/altre-traduzioni/', suffix: '.json'}, // traduzioni su altri file...
  //   ]);
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const services = [
  AuthGuard,
  DatePipe
];

@NgModule({
  declarations: [
    LoginComponent,
    ErrorComponent,
    SidebarComponent,
    MainComponent,
    NavbarComponent,
    BreadcrumbsComponent
  ],
  providers: [
    ...services
  ],
  imports: [
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    HttpClientXsrfModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('./ngsw-worker.js', {enabled: environment.production}),
    NgxSpinnerModule,
    NgProgressModule,
    SpinnerModule,
    NgxWebstorageModule.forRoot(),
    FontAwesomeModule,
    BsDropdownModule.forRoot()
  ],
  exports: [
    AppRoutingModule,
    HttpClientModule,
    HttpClientXsrfModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgProgressModule,
    ServiceWorkerModule,
    TranslateModule,
    NgxSpinnerModule
  ]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ErrorHandlerInterceptor,
        RestHttpInterceptor,
        AuthenticationService,
        AccountService,
        I18nService,
        RouterBackService,
        StateStorageService,
        StorageService,
        BudgetTooltipService,
        PrimengTableExportService,
        PrimengTableFiltersService,
        PrimengTableService,
        LivelliApprovazioneService,
        UtilsService,
        FormUtilsService,
        ModelService,
        {
          provide: LocationStrategy,
          useClass: HashLocationStrategy
        },
        MessageService,
        {provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: RestHttpInterceptor, multi: true},
        ...services
      ]
    };
  }
}
