import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, Injectable } from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {CodeDescription} from '../../models/general/code-description';
import {LANGUAGES, LOGGED_USER} from '../../../shared/constants/app.constants';
import {I18nService} from '../../services/general-utils/i18n.service';
import {AccountService} from '../../services/rest/account.service';
import { StorageService } from './../../services/general-utils/storage.service';
import { Classe } from '../../models/entities/classe';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @ViewChild('navbarCollapsibleBtnRef') navbarCollapsibleBtnRef: ElementRef;
  @ViewChild('navbarCollapsibleContentRef') navbarCollapsibleContentRef: ElementRef;
  languages: CodeDescription[] = [];
  currentUser: Classe;
  logoPath: string;
  appName: string;

  @Output() closeSidebar: EventEmitter<void> = new EventEmitter();

  constructor(
    public i18nService: I18nService,
    private accountService: AccountService,
    public deviceService: DeviceDetectorService,
    private storageService: StorageService) {
  }

  ngOnInit() {
    this.currentUser = JSON.parse(this.storageService.getItem(LOGGED_USER));
    this.logoPath = 'assets/img/' + environment.imgFolder + '/logo_navbar.png';
    this.appName = environment.appName;
    for (const lang of this.i18nService.supportedLanguages) {
      const language = LANGUAGES.find(l => l[lang]);
      this.languages.push({
        code: lang,
        description: language[lang]
      });
    }
  }

  changeLanguage(language) {
    this.i18nService.language = language;
  }

  logout(): void {
    this.accountService.logout();
  }

  isMinimized(): boolean {
    return document.getElementById('sidebar').classList.contains('minimized');
  }

}
