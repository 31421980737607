import { Injectable } from '@angular/core';
import { Table } from 'primeng/table';
import { LabelValue } from '../../models/general/label-value';

@Injectable()
export class PrimengTableFiltersService {
  
  constructor() {}

  getAgenteOptionsFromTableData(table: Table) {
    const values = table.filteredValue ? table.filteredValue : table.value;
    let nomeAgenteOptions: LabelValue[] = [];
    values.forEach(c => {
      if (nomeAgenteOptions.findIndex(x => x.value == c.nomeAgente) === -1) {
        nomeAgenteOptions.push({
          label: c.nomeAgente,
          value: c.nomeAgente
        });
      }
    });
    return nomeAgenteOptions;
  }

  getAreeGeograficheOptionsFromTableData(table: Table) {
    const values = table.filteredValue ? table.filteredValue : table.value;
    let areaGeograficaOptions: LabelValue[] = [];
    values.forEach(c => {
      if (areaGeograficaOptions.findIndex(x => x.value == c.areaGeografica) === -1) {
        areaGeograficaOptions.push({ label: c.areaGeografica ? c.areaGeografica : '', value: c.areaGeografica });
      }
    });
    return areaGeograficaOptions;
  }

  getBusinessUnitOptionsFromTableData(table: Table) {
    const values = table.filteredValue ? table.filteredValue : table.value;
    let businessUnitOptions: LabelValue[] = [];
    values.forEach(c => {
      if (businessUnitOptions.findIndex(x => x.value == c.businessUnit) === -1) {
        businessUnitOptions.push({ label: c.businessUnit ? c.businessUnit : '', value: c.businessUnit });
      }
    });
    return businessUnitOptions;
  }

}
