import {TranslateService} from '@ngx-translate/core';
import {Component} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {navItems} from '../../../_nav';
import {NavItem} from '../../models/general/nav-item';

export interface BreadCrumbItem {
  label: string;
  url: string;
  icon: string;
  hasPage: boolean;
  parameter: string;
  hideParam: boolean;
  routeParam: boolean;
  auth: string;
}

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['breadcrumb.component.scss']
})
export class BreadcrumbsComponent {

  breadcrumbs: Partial<BreadCrumbItem>[];
  composedUrl = '';
  finalUrl: string;

  // currentUser: User;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public translateService: TranslateService) {
    // this.currentUser = this.userService.getCurrentUser();
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {

      const navigationEndEvent: NavigationEnd = event as NavigationEnd;
      this.breadcrumbs = [];
      this.composedUrl = '';
      this.finalUrl = navigationEndEvent.urlAfterRedirects;

      // spezzo l'url tirando via le parti "vuote":
      const urlItems: string[] = this.finalUrl.split('/').filter(item => item != null && item.length > 0);

      if (urlItems && urlItems.length > 0) {
        this.buildBreadCrump(urlItems[0], urlItems, navItems);
      }
    });
  }

  /**
   * Compone la breadcrumb ricorsivamente
   * @param url - segmento di url corrente
   * @param urlSegments - lista "spezzata" dell'url completo del routing
   * @param navItems - parte dell'albero di navigazione (file "_nav.ts")
   */
  private buildBreadCrump(url: string, urlSegments: string[], navItems: NavItem[]) {

    this.composedUrl += '/' + url; // continuo a comporre l'url per confrontarlo con l'url completo finale in cui si sta navigando
    const urlIndex: number = urlSegments.indexOf(url);

    // per verifica se il prox segmento di url è presente nei figli di quello corrente
    const nextUrl: string = (urlIndex + 1) !== urlSegments.length ? urlSegments[urlIndex + 1] : null;
    const prevUrl: string = urlIndex !== 0 ? urlSegments[urlIndex - 1] : null; // ricavo pezzo di url precedente x check parametri "cliccabili"
    let nextNavItem: NavItem;

    const isRouteParam: boolean = navItems.find(item => item.routeParam && item.keyPage === (prevUrl + '*')) != null;

    // N.B.: da specificare sul _nav.ts!! --> keypage === path OPPURE name === path, quindi x url "composti"
    const navItem: NavItem = navItems.find(item => url === item.keyPage || url === item.name
      || (item.routeParam && item.keyPage === (prevUrl + '*')));

    if (nextUrl) {
      nextNavItem = this.searchNextNavItem(nextUrl, url, navItems); // cerco nei figli della lista navItems corrente
    }

    // se NON ho trovato il navItem nei figli vuol dire che "nextUrl" è un parametro --> lo aggiungo all'url che stiamo componendo
    const parameter: string = !nextNavItem ? nextUrl : null;
    if (parameter) {
      this.composedUrl += '/' + parameter;
    }

    if (navItem) {
      this.breadcrumbs.push({
        label: isRouteParam ? url : navItem.name, // se è parametro cliccabile metto come label del segmento direttamente il parametro...
        url: this.composedUrl,
        hasPage: navItem.hasPage,
        icon: navItem.icon,
        parameter: parameter,
        routeParam: isRouteParam,
        hideParam: navItem.hideParam,
        auth: navItem.auth
      });

      if (navItem.children && this.composedUrl !== this.finalUrl) {

        // l'url ha altri urls "figli" e non ho ancora finito di ricomporre l'url completo
        if (!parameter && urlIndex + 1 < urlSegments.length) {
          // --> chiamata ricorsiva con prox pezzo di url e navItems => children
          const nextUrlSegment: string = urlSegments[urlIndex + 1];
          this.buildBreadCrump(nextUrlSegment, urlSegments, navItem.children);

        } else if (parameter && urlIndex + 2 < urlSegments.length) {
          // --> c'è un parametro quindi faccio un salto di 2 segments e faccio ricorsione:
          const nextUrlSegment: string = urlSegments[urlIndex + 2];
          this.buildBreadCrump(nextUrlSegment, urlSegments, navItem.children);
        }
      }
    }
  }

  /**
   * Metodo usato per ricercare un segmento di url corrispondente dentro ai figli di 1° livello dei navItems correnti
   * @param urlSegment - segmento di url subito dopo quello corrente
   * @param currentUrl - segmento url corrente
   * @param navItems - alberatura navItems (verrà ricercato nei suoi eventuali children)
   */
  searchNextNavItem(urlSegment: string, currentUrl: string, navItems: NavItem[]): NavItem {
    for (const item of navItems) {
      if (item.children) {
        const navItem = item.children.find(item => (urlSegment === item.keyPage) || (urlSegment === item.name)
          || (item.routeParam && item.keyPage === (currentUrl + '*')));
        if (navItem) {
          return navItem;
        }
      }
    }
    return null;
  }

  /**
   * Check se lo user loggato ha i permessi necessari a visualizzare il path come link
   * @param breadcrumb - dati path breadcrumb
   */
  userPermissionOK(breadcrumb: any): boolean {
    /*return !breadcrumb.auth || (breadcrumb.auth && this.currentUser.authorizations
      && this.currentUser.authorizations.indexOf(breadcrumb.auth) !== -1);*/
    return true;
  }

  decodeURL(url: string): string {
    return decodeURI(url);
  }

}
