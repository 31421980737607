import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'page-ribbon',
  template: `
    <div class="ribbon" [ngClass]="[bgColor ? bgColor : 'bg-primary', textColor]">{{content}}</div>`,
  styles: [`
      .ribbon {
          left: -3.5em;
          moz-transform: rotate(-45deg);
          ms-transform: rotate(-45deg);
          o-transform: rotate(-45deg);
          webkit-transform: rotate(-45deg);
          transform: rotate(-47deg);
          overflow: hidden;
          position: absolute;
          top: -20px;
          white-space: nowrap;
          width: 12em;
          z-index: 9999;
          pointer-events: none;
          opacity: 0.75;
          text-align: center;
      }`
  ]
})
export class RibbonComponent {
  @Input() content: string; // contenuto (testo)
  // colore sfondo: se null di default --> primary
  @Input() bgColor: null | 'bg-primary' | 'bg-secondary' | 'bg-info' | 'bg-warning' | 'bg-danger';
  @Input() textColor: 'text-black' | 'text-white';
}
