import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'form-input-text-button',
  template: `
    <div class="form-group">
      <label class="col-form-label font-weight-bold">
        {{label}} <span *ngIf="showValidationOK" class="fas fa-fw fa-check-circle text-success text-right"></span>
      </label>
      <div class="p-inputgroup">
        <input type="text" class="form-control"
             #htmlInputElement
             [class.is-invalid]="control.invalid"
             [ngClass]="'input-with-btn'"
             [placeholder]="placeholder"
             [formControl]="control"
             (blur)="onBlur.emit()"
             (input)="onInput.emit()"
             (keydown.enter)="onSubmit.emit()">
        <button type="button" pButton pRipple icon="pi pi-search" (click)="onClick.emit()"></button>
      </div>
      <div *ngIf="errorMsg" class="invalid-feedback">{{errorMsg}}</div>
    </div>
  `,
  styles: [`
      .input-with-btn {
          -webkit-border-top-right-radius: 0;
          border-top-right-radius: 0;
          -moz-border-radius-bottomright: 0;
          -webkit-border-bottom-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: 0 none;
      }
  `]
})
export class FormInputTextButtonComponent {

  @ViewChild('htmlInputElement') htmlInputElement: ElementRef; // il ref serve a gestire eventi direttamente sull'input

  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() errorMsg: string;
  @Input() showValidationOK: boolean;

  @Output() onSubmit: EventEmitter<void> = new EventEmitter();
  @Output() onBlur: EventEmitter<void> = new EventEmitter();
  @Output() onInput: EventEmitter<void> = new EventEmitter();
  @Output() onClick: EventEmitter<void> = new EventEmitter();

}
