import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AccountService } from '../services/rest/account.service';
import { StorageService } from '../services/general-utils/storage.service';
import { UtilsService } from '../services/general-utils/utils.service';

export interface ErrorInfo {
  status: number;
  title: string;
  detail: string;
}

// stati gestiti per rimandare in automatico alla pag. di login (token scaduto o inattività)
export const STATUS_REDIRECT = [0, 401, 403, 504];

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {


  constructor(
    private translate: TranslateService,
    private accountService: AccountService,
    private router: Router,
    private storageService: StorageService,
    private utilsService: UtilsService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((err, caught) => {
        if (err.url && err.url.includes('api/authenticate')) {
          this.utilsService.fireErrorMsg(this.translate.instant('autenticazioneFallita'), this.translate.instant('credenzialiNonValide'));
          return throwError(err);
        } else if ((err.url && err.url.includes('utenti/utente-loggato')) || (err.url && err.url.includes('logout-cas'))
          || STATUS_REDIRECT.includes(err.status)) {
          // errore di re-login con token CAS scaduto e/o errore dopo inattività in coll --> non apro popup ma atterro direttamente in login
          console.warn('USER NON LOGGATO O TOKEN SCADUTO!');
          this.storageService.clear();
          this.router.navigate(['/login']);
          return new Observable<never>();
        } else if (err.status === 0) {
          this.openErrorModalServer(this.translate.instant('problemi_connessione'));
        } else {
          this.openErrorModal(err);
        }
        if (err instanceof HttpErrorResponse && err.error) {
          const errorDetails: any = err.error;
          if (errorDetails) {
            const errorInfo: ErrorInfo = {
              status: errorDetails.status,
              title: errorDetails.title,
              detail: errorDetails.detail ? errorDetails.detail : null
            };
            this.openErrorModal(errorInfo);
          }
        }
        return throwError(err);
      })
    );
  }

  openErrorModal(errInfo: ErrorInfo): void {
    // controllo se l'error ha la struttura(errorTitle, exceptionMessage, cause e subErrors).
    // altrimento lo interpreto come stringa(come gli errori 500) e li mostro sullo swal.(occhio che viene fatto il JSON.stringfy al inizio)
    // const errInfo = errorText && errorText.indexOf('errorTitle') !== -1 ? JSON.parse(errorText) : errorText;

    // componiamo il contenuto dello swal di errore:
    let htmlContent = '';
    if (errInfo && errInfo.detail) {
      htmlContent +=
        '<button id="collapse-swal" class="swal2-confirm swal2-styled font-sm" ' +
        'style="display: inline-block; border-left-color: #960A2C; border-right-color: #960A2C;">' +
        this.translate.instant('err_dettagli') +
        '</button><br/><br/>' +
        '<div id="error-frame" style="display:none; font-size:medium; max-height: 10rem; overflow-y: auto; overflow-x: auto;">' +
        ' <div style="text-align: left">';
      if (errInfo.detail) {
        htmlContent += ' <p>' + errInfo.detail + '</p>';
      }
      htmlContent += '</div>';
    }

    Swal.fire({
      icon: 'error',
      title: '<div style="text-align: center; font-size: 1.5rem; line-height: normal; max-height: 6rem; overflow-y: auto;">' +
        (errInfo.status + ' - ' + errInfo.title) +
        '</div>',
      html: htmlContent,
      confirmButtonText: this.translate.instant('close'),
      willOpen: () => {
        const content = Swal.getContent();
        const $ = content.querySelector.bind(content);
        // ci devono per forza essere uno tra exception message e cause per mostrare il tasto "read more"
        if (errInfo && errInfo.detail) {
          const collapse = $('#collapse-swal');
          // @ts-ignore
          collapse.addEventListener('click', () => {
            // @ts-ignore
            const btnReadMore: HTMLButtonElement = $('#error-frame');
            if (btnReadMore.style.display === 'none') {
              btnReadMore.style.display = 'block';
            } else {
              btnReadMore.style.display = 'none';
            }
          });
        }
      }
    });
  }

  openErrorModalServer(errMsg: any): void {
    Swal.fire({
      icon: 'error',
      title: '<div style="text-align: left; font-size: 1.5rem;">' + errMsg + '</div>',
      confirmButtonText: this.translate.instant('close')
    });
  }
}
