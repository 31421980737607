import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../../environments/environment';
import { APP_VERSION_KEY, LANG_KEY, USER_KEY, UTENTE_TEST, LOGGED_USER, VISIBILITA, AUTHORITIES } from '../../shared/constants/app.constants';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StorageService } from '../services/general-utils/storage.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UtilsService } from '../services/general-utils/utils.service';
import { AuthenticationService } from '../services/rest/authentication.service';
import { UtenteService } from '../services/rest/utente.service';
import { ProfiloEnum } from '../models/enums/profilo-enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  formLogin: FormGroup;
  subscriptions: Subscription = new Subscription();
  loading: boolean;
  logoPath: string;
  appName: string;

  constructor(
    private router: Router,
    private storageService: StorageService,
    public deviceService: DeviceDetectorService,
    private formBuilder: FormBuilder,
    private utilsService: UtilsService,
    private authenticationService: AuthenticationService,
    private utenteService: UtenteService,
    public translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.logoPath = 'assets/img/' + environment.imgFolder + '/logo_login.png';
    this.appName = environment.appName;
    this.formLogin = this.formBuilder.group({
      username: [null, Validators.required],
      password: [null, Validators.required]
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  login() {
    this.storageService.setItem(APP_VERSION_KEY, environment.appVersion);
    this.storageService.setItem(LANG_KEY, 'it');
    const input = {
      username: this.formLogin.controls['username'].value,
      password: this.formLogin.controls['password'].value,
      rememberMe: true
    };
    this.loading = true;
    this.formLogin.disable();
    this.subscriptions.add(this.authenticationService.login(input).subscribe(data => {
      if (data) {
        this.loading = false;
        this.formLogin.enable();
        this.getCurrentUser();
      } else {
        this.loading = false;
        this.formLogin.enable();
        this.utilsService.fireErrorConfirmMsg('errore', 'errore');
      }
    }, error => {
      this.loading = false;
      this.formLogin.enable();
    }));
  }

  getCurrentUser() {
    this.utenteService.getUtenteLoggato().subscribe(respUL => {
      if (respUL && respUL.body) {
        this.storageService.setItem(LOGGED_USER, JSON.stringify(respUL.body));

        let profili: string[] = [];
        respUL.body.utenteProfilos.forEach(up => {
          profili.push(up.profilo.profilo);
        });
        this.storageService.setItem(AUTHORITIES, JSON.stringify(profili));
        
        const userId = respUL.body.internalId;
        this.utenteService.getVisibilita(userId).subscribe(respV => {
          this.storageService.setItem(VISIBILITA, JSON.stringify(respV.body));
          if(respUL.body.utenteProfilos.find(up => up.profilo.profilo === ProfiloEnum.BUDGET || up.profilo.profilo === ProfiloEnum.BUDGET_NO_EDIT)) {
            this.router.navigate(['/budget']);
          } else if (respUL.body.utenteProfilos.find(up => up.profilo.profilo === ProfiloEnum.ARTICOLI)) {
            this.router.navigate(['/articoli']);
          } else if (respUL.body.utenteProfilos.find(up => up.profilo.profilo === ProfiloEnum.ADMIN)) {
            this.router.navigate(['/admin/gestione-agenti']);
          } else {
            this.utilsService.fireErrorConfirmMsg('accessoNegato', this.translate.instant('accessoNegato_msg'));
          }
        });
      }
    });
  }
  
}
