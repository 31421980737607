export const USER_KEY = 'userKey';
export const LOGGED_USER = 'loggedUser';
export const AUTHORITIES = 'authorities';
export const VISIBILITA = 'visibilita';
export const LANG_KEY = 'language';
export const APP_VERSION_KEY = 'appVersion';
export const BUDGET_CLIENTE = 'budgetCliente';
export const BUDGET_CLIENTE_DETTAGLIO = 'budgetClienteDettaglio';
export const BUDGET_ARTICOLO = 'budgetArticolo';
export const BUDGET_ARTICOLO_DETTAGLIO = 'budgetArticoloDettaglio';
export const BUDGET = 'budget';
export const RIGA_TABELLA_CLIENTI = 'rigaTabellaClienti';
export const RIGA_TABELLA_ARTICOLI = 'rigaTabellaArticoli';

// keys per salvataggio info tabelle nello storage
export const TABLE_CLIENTE = 'tableCliente';
export const TABLE_CLIENTE_DETTAGLIO = 'tableClienteDettaglio';
export const TABLE_ARTICOLO = 'tableArticolo';
export const TABLE_ARTICOLO_DETTAGLIO = 'tableArticoloDettaglio';

export const LANGUAGES: any[] = [{ it: 'Italiano' }, { gb: 'English' }];
export const DASH = '-';

export const X_TOTAL_COUNT = 'x-total-count'; // chiave header per record totali paginazione lato server
export const DATE_FORMAT_JSON = 'yyyy-MM-ddTHH:mm:ssZ'; // formato json OK per backend
export const DATE_FORMAT_TABLE = 'dd/MM/yyyy'; // formato di visualizzazione date per frontend

// costanti patterns (x validazioni campi nei form):
export const PATTERN_CF = '^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$';
export const PATTERN_PI = '^[0-9]{11}';
export const PATTERN_CF_PI = '(^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$)|(^[0-9]{11})';
export const PATTERN_EMAIL = '^[A-Za-z0-9._%+-]+@[A-Za-z0-9._%+-]{2,}[.][A-Za-z]{2,}$';
export const PATTERN_TEL = '^(\\+)(\\d{1,3})?\\d{10}$';

// varie keys per filtri JPA a BE (usare: nome criteria + key)
export const JPA_EQUALS = '.equals';
export const JPA_CONTAINS = '.contains';
export const JPA_IN = '.in';
export const JPA_SPECIFIED = '.specified';
export const JPA_GE = '.greaterOrEqualThan';
export const JPA_GREATER = '.greaterThan';
export const JPA_LE = '.lessOrEqualThan';
export const JPA_LESS = '.lessThan';

// costanti per icone (freccette) andamenti 
export const ARROW_UP_ICON = 'fas fa-arrow-up';
export const ARROW_DOWN_ICON = 'fas fa-arrow-down';
export const EQUALS_ICON = 'fas fa-equals';

// costanti per colori (freccette) andamenti 
export const GREEN_ICON_COLOR = '#4dbd74';
export const RED_ICON_COLOR = '#d9534f';
export const ORANGE_ICON_COLOR = '#ff7514';

export const UTENTE_TEST = {
  username: 'TEST USER',
  langKey: 'it'
};

// costanti p-calendar:
export const CALENDAR_IT = {
  firstDayOfWeek: 1,
  dayNames: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
  dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
  dayNamesMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
  monthNames: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
  monthNamesShort: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
  today: 'Oggi',
  clear: 'Resetta'
};
export const CALENDAR_EN = {
  firstDayOfWeek: 1,
  dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  today: 'Today',
  clear: 'Clear'
};

export const INTEGER_MAX_VALUE = 2147483647;
export const INTEGER_MIN_VALUE = -2147483648;