import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import {AUTHORITIES, USER_KEY} from '../../shared/constants/app.constants';
import {navItems} from '../../_nav';
import {StorageService} from '../services/general-utils/storage.service';
import {NavItem} from '../models/general/nav-item';

@Injectable()
export class AuthGuard  {

  navItems = navItems;

  constructor(
    private router: Router,
    private storageService: StorageService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.storageService.getItem(USER_KEY)) {
      return this.checkAuthsOK(state.url);
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

  /**
   * Check se l'utente può entrare in pagina (ha le auths)
   */
  checkAuthsOK(url: string): boolean {
    const navItem: NavItem = this.navItems.find(ni => url.includes(ni.url) || (ni.children && ni.children.some(ch => url.includes(ch.url))));
    if (navItem && navItem.authRoles) {
      const authorities = JSON.parse(this.storageService.getItem(AUTHORITIES));
      if (!authorities.some((auth: string) => navItem.authRoles.indexOf(auth) >= 0)) {
        return false;
      }
    }
    return true;
  }

}
