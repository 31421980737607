import 'hammerjs';

import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

import {environment} from '../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {PrimeNGConfig} from 'primeng/api';
import {I18nService} from './core/services/general-utils/i18n.service';
import {StorageService} from './core/services/general-utils/storage.service';
import {AccountService} from './core/services/rest/account.service';
import {APP_VERSION_KEY, LANG_KEY} from './shared/constants/app.constants';


@Component({
  selector: 'body',
  template: `
    <router-outlet></router-outlet>`,
  providers: [I18nService]
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private i18nService: I18nService,
    private storageService: StorageService,
    private accountService: AccountService,
    private translateService: TranslateService,
    private primengConfig: PrimeNGConfig) {
    this.controlAppVersion();
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    const currentLang: string = sessionStorage.getItem(LANG_KEY);
    const browserLang = this.translateService.getBrowserLang();
    this.i18nService.init(currentLang ? currentLang : (browserLang.indexOf('it') !== -1 ? 'it' : 'gb'), environment.supportedLanguages);
  }

  /**
   * control version app: if changed force logout
   */
  controlAppVersion() {
    const storageVersion = this.storageService.getItemSession(APP_VERSION_KEY);
    console.log('Client Store App version', storageVersion);
    console.log('AppVersion', environment.appVersion);
    if (!storageVersion || storageVersion !== environment.appVersion) {
      this.storageService.clear();
      this.storageService.setItemSession(APP_VERSION_KEY, environment.appVersion);
      this.accountService.logout();
    }
  }

}
