import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './core/layouts';
import { AuthGuard } from './core/guards/auth.guard';
import { LoginComponent } from './core/login';
import { errorRoute } from './core/layouts/error/error.route';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: {
      pageTitle: 'pageTitle_login'
    }
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'home',
      keyPage: 'home'
    },
    children: [
      {
        path: 'budget',
        loadChildren: () => import('./views/budget/budget.module').then(m => m.BudgetModule)
      }, {
        path: 'budget/:idOv/cliente',
        loadChildren: () => import('./views/budget-cliente/budget-cliente.module').then(m => m.BudgetClienteModule)
      }, {
        path: 'budget/:idOv/cliente/:idCliente',
        loadChildren: () => import('./views/budget-cliente/budget-cliente-dettaglio/budget-cliente-dettaglio.module').then(m => m.BudgetClienteDettaglioModule)
      }, {
        path: 'budget/:idOv/scorporo/:idGrCliente',
        loadChildren: () => import('./views/budget-cliente/scorporo-cliente/scorporo-cliente.module').then(m => m.ScorporoClienteModule)
      }, {
        path: 'budget/:idOv/articolo',
        loadChildren: () => import('./views/budget-articolo/budget-articolo.module').then(m => m.BudgetArticoloModule)
      }, {
        path: 'budget/:idOv/articolo/:nomeGArt',
        loadChildren: () => import('./views/budget-articolo/budget-articolo-dettaglio/budget-articolo-dettaglio.module').then(m => m.BudgetArticoloDettaglioModule)
      }, {
        path: 'budget/schedulazione-revisione/:idOv',
        loadChildren: () => import('./views/schedulazione-revisione/schedulazione-revisione.module').then(m => m.SchedulazioneRevisioneModule)
      }, {
        path: 'articoli',
        loadChildren: () => import('./views/articoli/articoli.module').then(m => m.ArticoliModule)
      }, {
        path: 'costi-unitari',
        loadChildren: () => import('./views/costi-unitari/costi-unitari.module').then(m => m.CostiUnitariModule)
      }, {
        path: 'articoli/costo/:idArticolo',
        loadChildren: () => import('./views/costo-articolo/costo-articolo.module').then(m => m.CostoArticoloModule)
      }, {
        path: 'admin/app-config',
        loadChildren: () => import('./views/admin/app-config/app-config.module').then(m => m.AppConfigModule)
      }, {
        path: 'admin/data-entry',
        loadChildren: () => import('./views/admin/data-entry/data-entry.module').then(m => m.DataEntryModule)
      }, {
        path: 'admin/gestione-agenti',
        loadChildren: () => import('./views/admin/gestione-agenti/gestione-agenti.module').then(m => m.GestioneAgentiModule)
      }, {
        path: 'admin/funzioni',
        loadChildren: () => import('./views/admin/funzioni/funzioni.module').then(m => m.FunzioniModule)
      }
    ]
  },
  ...errorRoute
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
