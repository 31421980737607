import { ProfiloEnum } from './core/models/enums/profilo-enum';
import { NavItem } from './core/models/general/nav-item';

export const navItems: NavItem[] = [{
  name: 'budget',
  url: '/budget',
  keyPage: 'budget',
  icon: 'fas fa-calculator',
  hasPage: true,
  authRoles: [ProfiloEnum.BUDGET, ProfiloEnum.BUDGET_NO_EDIT],
  visibleOnSidebar: true
}, {
  name: 'articoli',
  url: '/articoli',
  keyPage: 'articoli',
  icon: 'fas fa-boxes',
  hasPage: true,
  authRoles: [ProfiloEnum.ARTICOLI],
  visibleOnSidebar: true
}, {
  name: 'costiUnitari',
  url: '/costi-unitari',
  keyPage: 'costi-unitari',
  icon: 'fas fa-coins',
  hasPage: true,
  authRoles: [ProfiloEnum.EDIT_COSTO_UNITARIO],
  visibleOnSidebar: true
}, {
  name: 'amministrazione',
  icon: 'fas fa-users-cog',
  keyPage: 'amministrazione',
  authRoles: [ProfiloEnum.ADMIN],
  visibleOnSidebar: true,
  isDropdown: true,
  children: [{
    name: 'agenti',
    url: '/admin/gestione-agenti',
    keyPage: 'gestione-agenti',
    icon: 'fas fa-user-cog',
    hasPage: true,
    authRoles: [ProfiloEnum.ADMIN],
    visibleOnSidebar: true
  }, {
    name: 'configurazioni',
    url: '/admin/app-config',
    keyPage: 'app-config',
    icon: 'fas fa-cogs',
    hasPage: true,
    authRoles: [ProfiloEnum.ADMIN],
    visibleOnSidebar: true
  }, {
    name: 'dataEntry',
    url: '/admin/data-entry',
    keyPage: 'data-entry',
    icon: 'fas fa-database',
    hasPage: true,
    authRoles: [ProfiloEnum.DATA_ENTRY],
    visibleOnSidebar: true
  }, {
    name: 'funzioni',
    url: '/admin/funzioni',
    keyPage: 'funzioni',
    icon: 'fas fa-tools',
    hasPage: true,
    authRoles: [ProfiloEnum.ADMIN],
    visibleOnSidebar: true
  }]
}];
