import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SessionStorageService} from 'ngx-webstorage';
import {Observable, of, ReplaySubject} from 'rxjs';
import {catchError, shareReplay, tap} from 'rxjs/operators';

import {StateStorageService} from '../general-utils/state-storage.service';
import {I18nService} from '../general-utils/i18n.service';
import {StorageService} from '../general-utils/storage.service';
import {USER_KEY, LOGGED_USER} from '../../../shared/constants/app.constants';
import {environment} from '../../../../environments/environment';
import {NavItem} from '../../models/general/nav-item';

@Injectable({providedIn: 'root'})
export class AccountService {

  private userIdentity = null;
  private authenticationState = new ReplaySubject<any | null>(1);
  private accountCache$?: Observable<any | null>;

  constructor(
    private sessionStorage: SessionStorageService,
    private stateStorageService: StateStorageService,
    private router: Router,
    private i18nService: I18nService,
    private storageService: StorageService) {
  }

  authenticate(identity: any | null): void {
    this.userIdentity = identity;
    this.authenticationState.next(this.userIdentity);
  }

  hasRole(): boolean {
    return true;
  }

  hasAnyRoles(ruoli: any): boolean {
    return true;
  }

  /**
   * Check autorizzazioni sui navItems (sidebar)
   */
  filterNavItems(navItems: Partial<NavItem>[]) {
    return navItems.filter(item => {
      if (item.authRoles && !item.children) {
        return this.hasAnyRoles(item.authRoles);
      } else if (item.authRoles && item.children) {
        const hasAuthorization: boolean = this.hasAnyRoles(item.authRoles);
        if (hasAuthorization) {
          // il menù padre è ok --> valutiamo anche i figli
          item.children = item.children.filter(child => {
            return child.authRoles ? this.hasAnyRoles(child.authRoles) : true;
          });
        }
        return hasAuthorization;
      } else {
        return true;
      }
    });
  }

  identity(force?: boolean): Observable<any | null> {
    if (!this.accountCache$ || force || !this.isAuthenticated()) {
      this.accountCache$ = this.fetchUtenteData().pipe(
        catchError(err => {
          this.router.navigate(['/']);
          return of(null);
        }),
        tap((user: any | null) => {
          this.authenticate(JSON.parse(this.storageService.getItem(LOGGED_USER)));
          // After retrieve the account info, the language will be changed to
          // the user's preferred language configured in the account setting
          if (user && user.langKey) {
            this.i18nService.language = this.sessionStorage.retrieve('locale') || user.langKey;
          }
          if (user) {
            this.navigateToStoredUrl();
          }
        }),
        shareReplay()
      );
    }
    return this.accountCache$;
  }

  isAuthenticated(): boolean {
    return this.userIdentity !== null;
  }

  getAuthenticationState(): Observable<any | null> {
    return this.authenticationState.asObservable();
  }

  private fetchUtenteData(): Observable<any> {
    return new Observable((observer) => {
      observer.next(this.storageService.getItem(USER_KEY));
      observer.complete();
    });
  }

  private navigateToStoredUrl(): void {
    // previousState can be set in the authExpiredInterceptor and in the userRouteAccessService
    // if login is successful, go to stored previousState and clear previousState
    const previousUrl = this.stateStorageService.getUrl();
    if (previousUrl) {
      this.stateStorageService.clearUrl();
      this.router.navigateByUrl(previousUrl);
    }
  }

  logout() {
    if (environment.production) {
      this.storageService.clear();
      this.router.navigate(['/login']);
    } else {
      this.storageService.clear();
      window.location.reload(); // x sicurezza (svuota anche la cache del browser... e ritorna in automatico alla login)
    }
  }

}
