<div class="breadcrumb">
  <li class="breadcrumb-item active float-left">
    <a href="javascript:history.back()"><i class="fas fa-arrow-left"></i> Back</a>
  </li>
  <ng-template ngFor let-breadcrumb [ngForOf]="breadcrumbs" let-last="last">
    <li class="breadcrumb-item float-left" [ngClass]="{ active: last }">
      <i *ngIf="breadcrumb.icon" class="{{ breadcrumb.icon }} fa-md pt-1"></i>
      <!-- NON mostriamo parametro nell'url (hideParam = true) -->
      <a *ngIf="breadcrumb.hasPage && (!breadcrumb.parameter || breadcrumb.hideParam) && !breadcrumb.routeParam 
      && userPermissionOK(breadcrumb)"
         [routerLink]="decodeURL(breadcrumb.url)" [ngClass]="breadcrumb.icon ? 'pl-1' : ''">
        {{ breadcrumb.label | translate }}
      </a>
      <!-- mostriamo parametro nell'url (hideParam = false) -->
      <a *ngIf="breadcrumb.hasPage && (breadcrumb.parameter && !breadcrumb.hideParam && !breadcrumb.routeParam) 
      && userPermissionOK(breadcrumb)">
         <!-- [routerLink]="decodeURL(breadcrumb.url)" [ngClass]="breadcrumb.icon ? 'pl-1' : ''"> -->
        {{ breadcrumb.label | translate }}
      </a>
      <!-- parametro cliccabile (hideParam = false e routeParam = true) -->
      <a *ngIf="breadcrumb.hasPage && breadcrumb.routeParam && userPermissionOK(breadcrumb)"
         [routerLink]="decodeURL(breadcrumb.url)" [ngClass]="breadcrumb.icon ? 'pl-1' : ''">
        {{ breadcrumb.label }}
      </a>
      <!-- url non cliccabile (non c'è pagina collegata o lo user non ha i permessi adeguati) -->
      <span *ngIf="!breadcrumb.hasPage || !userPermissionOK(breadcrumb)"> 
      {{ breadcrumb.label | translate }}
    </span>
    </li>
  </ng-template>
</div>