import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'form-buttonbar',
  template: `
    <div class="d-flex w-100 justify-content-center">
      <button *ngIf="confirmLabel" type="button" class="btn btn-primary mr-2" [disabled]="formInvalid" (click)="submit.emit()">
        <i class="fa-fw" [ngClass]="confirmIconClass"></i>&nbsp;{{confirmLabel}}
      </button>
      <button *ngIf="cancelLabel" type="button" class="btn btn-info" (click)="cancel.emit()">
        <i class="fa-fw" [ngClass]="cancelIconClass"></i>&nbsp;{{cancelLabel}}
      </button>
    </div>
  `,
})
export class FormButtonbarComponent {

  @Input() formInvalid: boolean;
  @Input() confirmLabel: string;
  @Input() cancelLabel: string;
  @Input() confirmIconClass = 'far fa-save';
  @Input() cancelIconClass = 'fas fa-ban';

  @Output() submit: EventEmitter<void> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();
}
