import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'form-confirm-button',
  template: `
    <div class="d-flex w-100 justify-content-center">
      <button type="button" class="btn btn- mr-2" [ngClass]="confirmButtonClass ? confirmButtonClass : 'btn-info'" (click)="submit.emit()">
        <i class="fas fa-fw" [ngClass]="confirmIconClass"></i>{{confirmLabel}}
      </button>
    </div>
  `,
})
export class FormConfirmButtonComponent {
  @Input() confirmLabel = 'OK';
  @Input() confirmIconClass = 'fa-check';
  @Input() confirmButtonClass: null | 'btn-primary' | 'btn-info' | 'btn-danger' | 'btn-warning';
  @Output() submit: EventEmitter<void> = new EventEmitter();
}
