import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  private split: string[];
  private internalBean: any;

  transform(value: any, keys: string, term: string) {
    if (!term || term === '' || term === 'null' || term == null) {
      return value;
    }

    return (value || []).filter((item) => keys.split(',').some((key) => {
      // 2 LIVELLI DI RICERCA:
      if (!key.includes('.')) {
        // 1° livello: (proprietà diretta)
        return item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key]);
      } else {
        this.split = key.split('.');
        // 2° livello: (proprietà indiretta)
        this.internalBean = item[this.split[0]];
        return this.internalBean !== null && this.internalBean.hasOwnProperty(this.split[1])
          && new RegExp(term, 'gi').test(this.internalBean[this.split[1]]);
      }
    }));
  }
}
