export enum ProfiloEnum {
  BUDGET = 'BUDGET',
  CREA_BUDGET = 'CREA_BUDGET',
  BUDGET_ARTICOLO = 'BUDGET_ARTICOLO',
  ARTICOLI = 'ARTICOLI',
  BUDGET_NO_EDIT = 'BUDGET_NO_EDIT',
  DOWNLOAD_EXCEL = 'DOWNLOAD_EXCEL',
  EDIT_COSTO_UNITARIO = 'EDIT_COSTO_UNITARIO',
  SCHEDULAZIONE_REVISIONE = 'SCHEDULAZIONE_REVISIONE',
  ADMIN = 'ADMIN',
  APPROVA_BUDGET = 'APPROVA_BUDGET',
  DATA_ENTRY = 'DATA_ENTRY'
}

export const getProfiloList = (): any[] => {
  return Object.keys(ProfiloEnum).map(key => {
    return { value: key, label: ProfiloEnum[key] };
  });
};