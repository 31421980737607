import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class BudgetTooltipService {

  constructor(
    private _decimalPipe: DecimalPipe,
    private translate: TranslateService,
  ) {}

  getBdgPezzi(item: any, tipoBudget: string, budget: any) {
    return this.translate.instant('bdgPzTipo', {tipoBudget: tipoBudget}) + ': ' + this._decimalPipe.transform(item.bdgPezzi, '1.0-0') + '\n' +
      this.translate.instant('qtyPrec') + ': ' + this._decimalPipe.transform(budget.ovPadre ? item.bdgPezzi_old : item.consPzAnnoPrecedente, '1.0-0');
  }

  getBk(item: any, tipoBudget: string, budget: any) {
    return this.translate.instant('bdgETipo', {tipoBudget: tipoBudget}) + ': ' + this._decimalPipe.transform(item.bk, '1.0-0') + '\n' +
      this.translate.instant('euroPrec') + ': ' + this._decimalPipe.transform(budget.ovPadre ? item.bk_old : item.consValoreAnnoPrecedente, '1.0-0');
  }

  getPercScostamento(item: any) {
    return this.translate.instant('scostamentoPz') + ': ' + this._decimalPipe.transform(item.scostamento, '1.0-0') + '\n' +
      this.translate.instant('percScostamentoPz') + ': ' + this._decimalPipe.transform(item.percScostamento, '1.2-2') + '%\n' +
      this.translate.instant('scostamentoE') + ': ' + this._decimalPipe.transform(item.scostamentoE, '1.2-2') + '\n' +
      this.translate.instant('percScostamentoE') + ': ' + this._decimalPipe.transform(item.percScostamentoE, '1.2-2') + '%';
  }

  getPercScosPz(item: any) {
    return this.translate.instant('scostamentoPz') + ': ' + this._decimalPipe.transform(item.scosPz, '1.0-0') + '\n' +
      this.translate.instant('percScostamentoPz') + ': ' + this._decimalPipe.transform(item.percScosPz, '1.2-2') + '%\n' +
      this.translate.instant('scostamentoE') + ': ' + this._decimalPipe.transform(item.scosE, '1.2-2') + '\n' +
      this.translate.instant('percScostamentoE') + ': ' + this._decimalPipe.transform(item.percScosE, '1.2-2') + '%';
  }

  getConsPortPz(item: any, tipoBudget: string) {
    const bdgPezzi = item.bdgPezzi != null ? item.bdgPezzi : (item.bP12M_new != null ? item.bP12M_new : item.bP12M);
    return this.translate.instant('bdgPzTipo', {tipoBudget: tipoBudget}) + ': ' + this._decimalPipe.transform(bdgPezzi, '1.0-0') + '\n' +
      this.translate.instant('consPz') + ': ' + this._decimalPipe.transform(item.consPz, '1.0-0') + '\n' +
      this.translate.instant('portafPz') + ': ' + this._decimalPipe.transform(item.portafPz, '1.0-0') + '\n' +
      this.translate.instant('consPortPz') + ': ' + this._decimalPipe.transform(item.consPortPz, '1.0-0') + '\n' +
      this.translate.instant('scostamentoPz') + ': ' + this._decimalPipe.transform(item.scosPzBdgConsPort != null ? item.scosPzBdgConsPort :
        bdgPezzi - item.consPortPz, '1.0-0');
  }

  getConsPortE(item: any, tipoBudget: string) {
    const bdgE = item.bk != null ? item.bk : item.b12M;
    const consE = item.consK != null ? item.consK : item.consE;
    return this.translate.instant('bdgETipo', {tipoBudget: tipoBudget}) + ': ' + this._decimalPipe.transform(bdgE, '1.2-2') + '\n' +
    this.translate.instant('consE') + ': ' + this._decimalPipe.transform(consE, '1.2-2') + '\n' +
    this.translate.instant('portafE') + ': ' + this._decimalPipe.transform(item.portafEuro, '1.2-2') + '\n' +
    this.translate.instant('consPortE') + ': ' + this._decimalPipe.transform(item.consPortE, '1.2-2') + '\n' +
    this.translate.instant('scostamentoE') + ': ' + this._decimalPipe.transform(bdgE - item.consPortE, '1.2-2');
  }

  getFlagArticolo(item: any) {
    return this.translate.instant('critico') + ': ' + (item.articolo.flagCritico ? this.translate.instant('si') : this.translate.instant('no')) + '\n' +
      this.translate.instant('custom') + ': ' + (item.articolo.flagCustom ? this.translate.instant('si') : this.translate.instant('no')) + '\n' + 
      (item.articolo.fInFout ? (this.translate.instant('fInOut') + ': ' + item.articolo.fInFout) : '');
  }

  getPzFatt12M(item: any) {
    return this.translate.instant('pz12M') + ': ' + this._decimalPipe.transform(item.p12M, '1.0-0') + '\n' +
      this.translate.instant('fatt12M') + ': ' + this._decimalPipe.transform(item.c12M, '1.2-2') + '\n' +
      this.translate.instant('pMedio') + ': ' + (item.p12M ? this._decimalPipe.transform(item.c12M / item.p12M, '1.2-2') : 0);
  }

  getCons(item: any) {
    return this.translate.instant('consPz') + ': ' + this._decimalPipe.transform(item.consPz, '1.0-0') + '\n' +
      this.translate.instant('cons') + ': ' + this._decimalPipe.transform(item.consE, '1.2-2') + '\n' +
      this.translate.instant('pMedio') + ': ' + (item.consPz ? this._decimalPipe.transform(item.consE / item.consPz, '1.2-2') : 0);
  }

}