import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'form-select',
  template: `
    <div [ngClass]="applyPadding ? 'form-group' : ''">
      <label class="col-form-label font-weight-bold">{{label}}</label>
      <page-spinner size="small" *ngIf="loading"></page-spinner><br>
      <p-dropdown
          *ngIf="!loading"
          [options]="items"
          [formControl]="control"
          [optionValue]="valueFieldName"
          [optionLabel]="labelFieldName"
          [filter]="true" filterBy="{{valueFieldName + ',' + labelFieldName}}"
          [showClear]="true"
          [placeholder]="placeholder"
          [appendTo]="appendToBody ? 'body' : null"
          [class.is-invalid]="control.invalid"
          (onChange)="selectChanged.emit($event.value)"
          (onClear)="clear.emit($event)">
      </p-dropdown>
      <div *ngIf="errorMsg" class="invalid-feedback">{{errorMsg}}</div>
    </div>
  `,
  styles: [`
    ::ng-deep .p-dropdown {
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
    }
  `]
})
export class FormSelectComponent {

  @Input() control: AbstractControl;
  @Input() items: any[];
  @Input() labelFieldName = 'nome'; // di default label = campo "nome"
  @Input() valueFieldName = 'id'; // di default value = campo "id"
  @Input() label: string;
  @Input() placeholder: string;
  @Input() errorMsg: string;
  @Input() loading: boolean;
  @Input() clearable = true;
  @Input() appendToBody: boolean;
  @Input() applyPadding = true; // se settata a false rimuove la classe "form-group"

  @Output() selectChanged: EventEmitter<any> = new EventEmitter();
  @Output() removedItem: EventEmitter<void> = new EventEmitter();
  @Output() clear: EventEmitter<void> = new EventEmitter();

}
