<!-- filtri di ricerca -->
<p-panel *ngIf="config.searchFields?.length" header="{{config?.labels?.ricercaHeader}}" [toggleable]="true"
  [(collapsed)]="filterCollapsed" toggler="header" class="filters-panel"
  [styleClass]="config.marginTable ? config.marginTable : 'm-0'" expandIcon="pi pi-times" collapseIcon="pi pi-search">
  <form (ngSubmit)="formFilterQuery()" [formGroup]="formFilter" novalidate>
    <div class="d-flex flex-wrap">
      <div [ngClass]="deviceService.isMobile() ? 'w-100' : 'w-50'" class="p-3" style="height: 68px !important"
        *ngFor="let fieldForm of config.searchFields">

        <!-- label filtro -->
        <label class="font-weight-bold mb-1 mt-2">{{fieldForm.label}}</label>

        <!-- filtro input normale -->
        <div *ngIf="fieldForm.type == DynTableColFilterType.INPUT_CONTAINS">
          <input class="form-control" formControlName="{{fieldForm.name}}">
        </div>

        <!-- filtro select (dropdown) con ricerca -->
        <div *ngIf="fieldForm.type == DynTableColFilterType.SELECT">
          <p-dropdown [placeholder]="fieldForm.placeholder" styleClass="form-control-height" [style]="{'width':'100%'}"
            [options]="fieldForm.options" formControlName="{{fieldForm.name}}" [filter]="true" [showClear]="true"
            [resetFilterOnHide]="true" (onChange)="pickedValueFromSelectFilter($event, fieldForm.name);">
          </p-dropdown>
        </div>

        <!-- Filtro date-picker su singola data -->
        <div *ngIf="fieldForm.type == DynTableColFilterType.DATE_SEARCH">
          <p-calendar [monthNavigator]="true" [yearNavigator]="true" [showButtonBar]="true" [showIcon]="true"
            styleClass="form-control-height" [style]="{'width':'100%'}" selectionMode="single" yearRange="2000:2030"
            dateFormat="dd/mm/yy" formControlName="{{ fieldForm.name }}" appendTo="body" [baseZIndex]="3002"
            [touchUI]="deviceService.isMobile()">
          </p-calendar>
        </div>

        <!-- Filtro date-picker con range di date -->
        <div *ngIf="fieldForm.type == DynTableColFilterType.BETWEEN">
          <p-calendar [monthNavigator]="true" [yearNavigator]="true" [showButtonBar]="true" [showIcon]="true"
            styleClass="form-control-height" [style]="{'width':'100%'}" selectionMode="range" yearRange="2000:2030"
            dateFormat="dd/mm/yy" formControlName="{{ fieldForm.name }}" appendTo="body" [baseZIndex]="3002"
            [touchUI]="deviceService.isMobile()">
          </p-calendar>
        </div>

        <!-- filtro di ricerca per numeri interi (anno, mese...) -->
        <div class="button-input-search" *ngIf="fieldForm.type == DynTableColFilterType.IN_NUM">
          <input formControlName="{{fieldForm.name}}" class="form-control" type="number"
            placeholder="{{fieldForm.placeholder}}">
        </div>

        <!-- filtro di ricerca del valore in input con chiamata al servizio -->
        <div class="button-input-search" *ngIf="fieldForm.type == DynTableColFilterType.IN_VAL">
          <input formControlName="{{fieldForm.name}}" class="form-control" type="text"
            placeholder="{{fieldForm.placeholder}}">
          <button
            [disabled]="!formFilter.controls[fieldForm.name].value || formFilter.controls[fieldForm.name].value?.length == 0"
            class="btn btn-primary btn-inval" type="button" pTooltip="{{fieldForm.tooltip}}"
            (click)="researchInputValue(formFilter.controls[fieldForm.name].value, fieldForm.name)">
            <i class="fas fa-fw fa-plus"></i>
          </button>
          <button *ngIf="formFilter.controls[fieldForm.name].value?.length > 0" class="btn btn-primary btn-invisible"
            type="button" (click)="formFilter.controls[fieldForm.name].setValue(null)">
            <i class="fas fa-fw fa-times"></i>
          </button>
        </div>
      </div>
    </div>
    <br>
    <div class="p-3">
      <button [disabled]="!formFilter.valid" type="submit" class="btn btn-sm btn-primary">
        <i class="fas fa-search"></i> {{config?.labels?.searchBtnLabel}}
      </button>
      <button type="button" (click)="resetSearch()" class="btn btn-sm btn-outline-primary ml-2">
        <i class="fas fa-undo"></i> {{config?.labels?.resetBtnLabel}}
      </button>
    </div>
  </form>
</p-panel>

<br>

<!-- struttura tabelle -->
<div [ngClass]="getMargin()">
  <p-table #dynTable [columns]="columns" [value]="data" [dataKey]="dataKey" [paginator]="config.paginator"
    [totalRecords]="total" [rows]="inputPagination? inputPagination.recordsPerPage : 10"
    [rowsPerPageOptions]="data?.length > 25 ? [5, 10, 25, data?.length] : [5, 10, 25]" [expandedRowKeys]="expandedRows" [lazy]="!config.clientManaged"
    responsiveLayout="scroll" (onLazyLoad)="onLazyLoad($event)" [loading]="loading" stateStorage="local" [stateKey]="stateKey"
    [selectionMode]="config.multiSelectable || config.checkboxes ? 'multiple' : (config.singleSelectable ? 'single' : null)"
    [(selection)]="selectedRows" [metaKeySelection]="config.multiSelectable ? true : null" [resizableColumns]="true"
    (onHeaderCheckboxToggle)="checkboxSelectAll($event)" (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)="onRowUnselect($event)" styleClass="p-datatable-striped dyn-table">

    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns">
      </colgroup>
    </ng-template>

    <!-- headers delle colonne (con eventuale colgroup) -->
    <ng-template pTemplate="header" let-columns>

      <!-- qui ci sono le testate finte delle colonne  -->
      <tr *ngIf="colGroup" class="zero-expanded-row">
        <th *ngIf="config.checkboxes" style="width: 3em;position: static;" class="invisible-th"></th>
        <th *ngIf="actionsPresent()" style="position: static;" class="invisible-th"></th>
        <th *ngFor="let col of columns" [pSortableColumn]="col.sortable ? col.name : ''" class="invisible-th"
          pResizableColumn>
        </th>
      </tr>

      <!-- qui i raggruppamenti delle colonne -->
      <tr *ngIf="colGroup" class="dyn-table-header-row dyn-table-header">
        <th *ngIf="actionsPresent()" style="position: static;" class="th-groupcol"></th>
        <th *ngFor="let colgroup of colGroup" [attr.colspan]="colgroup.colspan" class="th-groupcol" pResizableColumn>
          <div>{{colgroup.label}}</div>
        </th>
      </tr>

      <!-- qui ci sono le testate delle colonne  -->
      <tr class="dyn-table-header-row dyn-table-header">
        <th *ngIf="config.checkboxes" style="width: 3em;" class="th-header">
          <p-tableHeaderCheckbox *ngIf="!disableHeaderCheckbox" [disabled]="tableHeaderCheckboxDisabled">
          </p-tableHeaderCheckbox>
        </th>
        <th *ngIf="rowSpanMetadata" [ngStyle]="rowSpanMetadata.style" pResizableColumn class="th-header">
          {{rowSpanMetadata.header}}</th>
        <th *ngIf="actionsPresent()" class="th-header">
          {{config.labels.actionsHeader}}
        </th>
        <th *ngFor="let col of columns" [pSortableColumn]="col.sortable ? (col.sortField ? col.sortField : col.name) : ''"
          class="font-weight-bold th-header" [ngClass]="col.headerAlign ? col.headerAlign : ''" pResizableColumn>
          <div>{{ col.label }}
            <p-sortIcon *ngIf="col.sortable" [field]="col.name"></p-sortIcon>
            <span *ngIf="col.startPeriodLabel"><br>{{ col.startPeriodLabel }}<br>{{ col.endPeriodLabel }}</span>
          </div>
        </th>
      </tr>

      <!-- riga filtri (lato client) x colonna -->
      <tr class="text-center" *ngIf="config.clientSideFilters">
        <th *ngIf="actionsPresent()"></th>
        <th *ngFor="let col of columns" class="th-filter">

          <p-columnFilter *ngIf="col.searchable && col.searchType === DynTableSearchType.TEXT" type="text" [field]="col.name" 
            [placeholder]="col.searchPh"></p-columnFilter>

          <form [formGroup]="formFilter"  [ngStyle]="col.searchable && col.searchType === DynTableSearchType.MULTISELECT_APPROVE ? 
            {'min-width': '40px', 'max-width': '40px'} : {}" novalidate>

            <input pInputText type="number" *ngIf="col.searchable && col.searchType === DynTableSearchType.NUMBER"
              (input)="dynTable.filter($event.target['value'], col.name, (col.searchMethod ? col.searchMethod : 'contains'))"
              [placeholder]="col.searchPh" class="ui-column-filter w-100" [formControlName]="col.name">

            <p-inputMask *ngIf="col.searchable && col.searchType === DynTableSearchType.DATE"
              (onInput)="searchFromMask($event.target?.value, col.name, col.searchMethod)" [placeholder]="col.searchPh"
              styleClass="ui-column-filter w-100" mask="99/99/9999" [formControlName]="col.name">
            </p-inputMask>

            <p-multiSelect *ngIf="col.searchable && col.searchType === DynTableSearchType.MULTISELECT_APPROVE" [options]="col.searchOptions" 
                          (onChange)="onMultiselectChange($event, col.name)" styleClass="ui-column-filter w-100" [formControlName]="col.name" 
                          [showHeader]="false" appendTo="body">
              <ng-template let-option pTemplate="item">
                <div class="ui-multiselect-option">
                  <i class="fas fa-circle" [ngStyle]="{'color': option.value === '!' ? '#d9534f' : (option.value === '<' ? '#ff7514' : (
                    option.value === '=' ? '#fce300' : '#5cb85c')) }" style="font-size: x-large;"></i>
                  &nbsp;<span>{{option.label}}</span>
                </div>
              </ng-template>
            </p-multiSelect>

          </form>
        </th>
        <!-- <th *ngIf="config.reordableRow" style="width: 3em;"></th>
        <th *ngIf="expandedFields && expandedFields.length > 0"></th>
        
          <form [formGroup]="formFilter" novalidate>

            <ng-select *ngIf="col.searchable && col.searchType === DynTableSearchType.BOOLEAN"
                       [items]="col.searchOptions" (change)="searchBoolean($event?.value, col.name)"
                       bindValue="value" bindLabel="label"
                       [placeholder]="col.searchPh" class="ui-column-filter w-100"
                       [formControlName]="col.name">
            </ng-select>

            <p-inputMask *ngIf="col.searchable && col.searchType === DynTableSearchType.TIME"
                         (onInput)="searchFromMask($event.target?.value, col.name, col.searchMethod)" [placeholder]="col.searchPh"
                         styleClass="ui-column-filter w-100" mask="99:99"
                         [formControlName]="col.name">
            </p-inputMask>

            <p-inputMask type="text" *ngIf="col.searchable && col.searchType === DynTableSearchType.CURRENCY"
                         (onInput)="searchFromMask($event.target?.value, col.name, col.searchMethod)" [placeholder]="col.searchPh"
                         styleClass="ui-column-filter w-100" mask="€000.000.000,00"
                         [formControlName]="col.name">
            </p-inputMask>

            <ng-select *ngIf="col.searchable && col.searchType === DynTableSearchType.SELECT"
                       [items]="col.searchOptions" (change)="dynTable.filter($event?.value, col.name, 'equals')"
                       bindValue="value" bindLabel="label" [placeholder]="col.searchPh" class="ui-column-filter w-100"
                       [formControlName]="col.name">
            </ng-select>

            <p-multiSelect *ngIf="col.searchable && col.searchType === DynTableSearchType.MULTISELECT"
                           [options]="col.searchOptions" [filterPlaceHolder]="col.searchPh"
                           (onChange)="onMultiselectChange($event, col.name)" optionLabel="label" styleClass="ui-column-filter w-100"
                           [formControlName]="col.name">
              <ng-template let-option pTemplate="item">
                <div class="ui-multiselect-option">
                  <span>{{option.label}}</span>
                </div>
              </ng-template>
            </p-multiSelect>

          </form> -->
      </tr>

      <tr class="text-right !important" *ngIf="hasFooterFields()">
        <th *ngFor="let footerField of getFooterFields(); let i = index"
          style="text-align: right !important;box-sizing: border-box;padding: .25em .5em;" [ngStyle]="footerField.style"
          class="ui-resizable-column cell-total">
          {{footerField.hasFooter ? footerField.value : ""}}
          {{(!footerField.hasFooter && i == 0) ? config.labels.totals : ""}}
        </th>
      </tr>

    </ng-template>
    <!-- righe tabella -->
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded" let-rowIndex="rowIndex">

      <tr *ngIf="config.checkboxes && config.singleSelectable" [pSelectableRow]="rowData"
        [pSelectableRowIndex]="rowData[dataKey]" (click)="handler($event, rowData)">
        <!-- colonna checkbox -->
        <td class="dyn-table-column" style="width: 3em;text-align: center !important;">
          <p-tableCheckbox [value]="rowData" [disabled]="rowData.checkboxDisabled" (click)="$event.stopPropagation();">
          </p-tableCheckbox>
        </td>
        <!-- eventuale rowspan -->
        <ng-template
          [ngIf]="rowSpanMetadata && config.rowspanField && rowSpanMetadata[rowData[config.rowspanField]][dataKey] === rowIndex">
          <td class="ui-resizable-column" [attr.rowspan]="rowSpanMetadata[rowData[config.rowspanField]].size"
            [ngStyle]="rowSpanMetadata.style">
            <span [ngClass]="'custom-span'"
              [innerHTML]="sanitizer.bypassSecurityTrustHtml(rowSpanMetadata[rowData[config.rowspanField]].value)">
            </span>
          </td>
        </ng-template>
        <ng-template [ngTemplateOutlet]="blockBody"></ng-template>
      </tr>

      <!-- struttura con checkboxes. da notare che in questo caso non sono stati aggiunti le prop pSelectableRow, altrimenti viene applicata la classe css
      che seleziona tutta la riga di arancio-->
      <tr *ngIf="config.checkboxes && !config.singleSelectable" (click)="handler($event, rowData)">
        <!-- colonna checkbox -->
        <td class="dyn-table-column" style="width: 3em;text-align: center !important;">
          <p-tableCheckbox *ngIf="!rowData.hideCheckbox" [value]="rowData" [disabled]="rowData.checkboxDisabled">
          </p-tableCheckbox>
        </td>
        <!-- eventuale rowspan -->
        <ng-template
          [ngIf]="rowSpanMetadata && config.rowspanField && rowSpanMetadata[rowData[config.rowspanField]][dataKey] === rowIndex">
          <td class="ui-resizable-column" [attr.rowspan]="rowSpanMetadata[rowData[config.rowspanField]].size"
            [ngStyle]="rowSpanMetadata.style">
            <span [ngClass]="'custom-span'"
              [innerHTML]="sanitizer.bypassSecurityTrustHtml(rowSpanMetadata[rowData[config.rowspanField]].value)">
            </span>
          </td>
        </ng-template>
        <ng-template [ngTemplateOutlet]="blockBody"></ng-template>
      </tr>

      <!-- se selectableRowDisable(impostata sugli items/data dei component) allora la riga non è selezionabile  -->
      <tr *ngIf="!config.checkboxes" [pSelectableRow]="rowData" [pSelectableRowIndex]="rowData[dataKey]"
        [pSelectableRowDisabled]="rowData.selectableRowDisable" (click)="handler($event, rowData)">
        <!-- eventuale rowspan -->
        <ng-template
          [ngIf]="rowSpanMetadata && config.rowspanField && rowSpanMetadata[rowData[config.rowspanField]][dataKey] === rowIndex">
          <td class="ui-resizable-column" [attr.rowspan]="rowSpanMetadata[rowData[config.rowspanField]].size"
            [ngStyle]="rowSpanMetadata.style">
            <span [ngClass]="'custom-span'"
              [innerHTML]="sanitizer.bypassSecurityTrustHtml(rowSpanMetadata[rowData[config.rowspanField]].value)">
            </span>
          </td>
        </ng-template>
        <ng-template [ngTemplateOutlet]="blockBody"></ng-template>
      </tr>

      <!-- blocco body con actions/dati e tutta la struttura riusabile -->
      <ng-template #blockBody>
        <!-- se ci sono campi nell'expander mostro l'action ">" -->
        <td *ngIf="actionsPresent()" class="dyn-table-column" style="position: static;">
          <div class="d-flex justify-content-center">
            <!-- azione speciale expander (se ci sono expandedFields) -->
            <div class="float-left pt-2 pr-2 text-center"
              *ngIf="(expandedFields && expandedFields.length > 0) || (selectableExpander && (rowData?.children?.length > 0))">
              <a href="#" [pRowToggler]="rowData" (click)="actionRowHandler($event, rowData)">
                <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
              </a>
            </div>

            <!-- colonna azioni -->
            <ng-container *ngFor="let action of actions">
              <!-- azione standard (non expander) -->
              <!-- N.B.: per poter gestire un particolare tooltip "dinamico" in una action usare la convenzione:
                action.type + _ + (testo tooltip) -->
              <div *ngIf="!(action.hidden && rowData[action.hidden]) && !action.splitButton"
                class="float-left text-center">
                <button [disabled]="action.disabled ? rowData[action.disabled]: false"
                  (click)="handlerAction($event, action, rowData)"
                  class="btn btn-sm {{action.cls ? action.cls : 'btn-outline-primary'}}"
                  [pTooltip]="rowData.actionTooltip && rowData.actionTooltip === 'true' ? rowData[action.tooltip] : action.tooltip"
                  [tooltipPosition]="action.tooltipLarge ? 'right' : 'top'"
                  [tooltipStyleClass]="action.tooltipLarge ? 'tooltip-large' : null" style="margin: 2px" type="button">
                  <span *ngIf="action.stackedIcons" class="fa-stack" style="font-size: 0.52em;">
                    <i *ngFor="let stackedIcon of action.stackedIcons"
                      class="fa {{stackedIcon}} fa-stack-1x customStackIcon"></i>
                  </span>
                  <i *ngIf="!action.stackedIcons && action.icon" class="{{ action.icon }}"></i>
                </button>
              </div>

              <!-- split button per le row action -->
              <div *ngIf="action.splitButton && !(action.hidden && rowData[action.hidden])" class="split-button-class">
                <p-splitButton icon="{{action.icon}}" [appendTo]="'body'"
                  (onClick)="action.onClick(action.type, rowData)"
                  (onDropdownClick)="actionHandler($event, action.type, rowData); onMenuClick($event, rowData)"
                  [tooltipStyleClass]="action.tooltipLarge ? 'tooltip-large' : ''" [model]="action.items"
                  pTooltip="{{ action.tooltip }}" tooltipPosition="top" [menuStyle]="{width: '15em'}"
                  styleClass="text-left">
                </p-splitButton>
              </div>
            </ng-container>
          </div>
        </td>

        <!-- colonne standard -->
        <td *ngFor="let col of columns" class="dyn-table-column" [pEditableColumn]="col.editable">

          <!-- colonna "normale" senza type e non editabile -->
          <div *ngIf="!col.type && !col.editable" class="ui-resizable-column">
            <span [ngClass]="col.customCssClass != null ? col.customCssClass : 'custom-span'"
              [ngStyle]="col.cellStyle ? col.cellStyle : {'text-align': 'left'}"
              (click)="!deviceService.isDesktop() ? showCustomDialog(rowData[col.name]) : null"
              title="{{col.tooltip ? rowData[col.tooltip] : rowData[col.name]}}">
              {{rowData[col.name]}}
            </span>
          </div>

          <!-- colonna con type text con valore nuovo + valore vecchio e non editabile -->
          <div *ngIf="col.type && col.type === DynTableColType.TEXT_RIGHT && !col.editable" class="ui-resizable-column">
            <span [ngClass]="col.customCssClass != null ? col.customCssClass : 'custom-span'"
              [ngStyle]="col.cellStyle ? col.cellStyle : {'text-align': 'right'}"
              (click)="!deviceService.isDesktop() ? showCustomDialog(rowData[col.name]) : null"
              title="{{col.tooltip ? rowData[col.tooltip] : rowData[col.name]}}">
              {{rowData[col.name]}}
              <i *ngIf="rowData['icon' + col.name]" [ngClass]="rowData['icon' + col.name]" [ngStyle]="{'color': rowData['color' + col.name]}"></i>
            </span>
          </div>

          <!-- colonna con type text con allineamento centrale e non editabile -->
          <div *ngIf="col.type && col.type === DynTableColType.TEXT_CENTER && !col.editable" class="ui-resizable-column">
            <span [ngClass]="col.customCssClass != null ? col.customCssClass : 'custom-span'"
              [ngStyle]="col.cellStyle ? col.cellStyle : {'text-align': 'center'}"
              (click)="!deviceService.isDesktop() ? showCustomDialog(rowData[col.name]) : null"
              title="{{col.tooltip ? rowData[col.tooltip] : rowData[col.name]}}">
              {{rowData[col.name]}}
              <i *ngIf="rowData['icon' + col.name]" [ngClass]="rowData['icon' + col.name]" [ngStyle]="{'color': rowData['color' + col.name]}"></i>
            </span>
          </div>

          <!-- colonna con tipo particolare -->
          <div
              *ngIf="col.type && col.type !== DynTableColType.ROUTE
                && col.type !== DynTableColType.BADGE
                && col.type !== DynTableColType.DOTS
                && col.type !== DynTableColType.BYTE
                && col.type !== DynTableColType.ICON
                && col.type !== DynTableColType.TEXT_RIGHT
                && col.type !== DynTableColType.TEXT_CENTER
                && col.type !== DynTableColType.TEXT_COMPARE_ICON
              && !col.editable"
              class="ui-resizable-column">
            <span [ngClass]="'custom-span'" [ngStyle]="col.cellStyle ? col.cellStyle : {'text-align': 'center'}"
                  (click)="!deviceService.isDesktop() ? showCustomDialog(rowData[col.name]) : null"
                  title="{{col.tooltip ? rowData[col.tooltip] : ''}}"
                  [innerHTML]="renderField(col, rowData[col.name])">{{rowData[col.name]}}
            </span>
          </div>

          <div *ngIf="col.type === DynTableColType.TEXT_COMPARE_ICON && !col.editable" class="ui-resizable-column">
            <span [ngClass]="'custom-span'" [ngStyle]="col.cellStyle ? col.cellStyle : {'text-align': 'center'}"
                  (click)="!deviceService.isDesktop() ? showCustomDialog(rowData[col.name]) : null"
                  title="{{col.tooltip ? rowData[col.tooltip] : ''}}"
                  [innerHTML]="renderFieldTextCompareIcon(rowData[col.name], rowData[col.iconRightClass], rowData[col.iconRightColor])">{{rowData[col.name]}}
            </span>
          </div>

          <!-- colonna di tipo 'icon' + (eventuale tooltip -> nomeCampo + '_tooltip') -->
          <div *ngIf="col.type === DynTableColType.ICON && !col.editable" class="ui-resizable-column"
            [ngStyle]="col.cellStyle ? col.cellStyle : {'text-align': 'center'}">
            <i class="{{rowData[col.name]}} main-color" style="font-size: x-large"
              [pTooltip]="rowData[col.name + '_tooltip'] ? rowData[col.name + '_tooltip'] : null" tooltipPosition="top">
            </i>
          </div>

          <!-- colonna di tipo 'dots_approve' (pallino rosso, arancione, giallo, verde in base allo stato stato dell'approvazione) -->
          <div *ngIf="col.type && col.type === DynTableColType.DOTS_APPROVE && !col.editable" class="ui-resizable-column">
            <span [ngClass]="'custom-span'" [ngStyle]="col.cellStyle ? col.cellStyle : {'text-align': 'center'}"
              (click)="!deviceService.isDesktop() ? showCustomDialog(rowData[col.name]) : null"
              [innerHTML]="renderField(col, rowData[col.name])">
            </span>
          </div>

          <!-- colonna di tipo 'dots' (pallino rosso o verde) -->
          <div *ngIf="col.type && col.type == 'dots' && !col.editable" class="ui-resizable-column">
            <span [ngClass]="'custom-span'" [ngStyle]="col.cellStyle ? col.cellStyle : {'text-align': 'center'}"
              (click)="!deviceService.isDesktop() ? showCustomDialog(rowData[col.name]) : null"
              [innerHTML]="renderField(col, rowData[col.name])">
            </span>
          </div>

          <!-- colonna di tipo 'badge' -->
          <div *ngIf="col.type && col.type == 'badge' && !col.editable" class="ui-resizable-column">
            <span [ngClass]="'custom-span'" [ngStyle]="col.cellStyle ? col.cellStyle : {'text-align': 'center'}"
              (click)="!deviceService.isDesktop() ? showCustomDialog(rowData[col.name]) : null"
              [innerHTML]="renderField(col, rowData[col.name])">
            </span>
          </div>

          <!-- colonna tipo switch -->
          <div *ngIf="col.type && col.type == 'switch' && !col.editable"
            [ngStyle]="col.cellStyle ? col.cellStyle : {'text-align': 'center'}" class="ui-resizable-column">
            <p-inputSwitch [(ngModel)]="rowData[col.name]" [disabled]="rowData[col.disabled]"
              (onChange)="switchChange($event, rowIndex)"></p-inputSwitch>
          </div>

          <!-- colonna con link che apre nuovo tab -->
          <div *ngIf="col.type == 'route' && !col.editable" class="ui-resizable-column">
            <span [ngClass]="col.customCssClass != null ? col.customCssClass : 'custom-span'"
              [ngStyle]="col.cellStyle ? col.cellStyle : {'text-align': 'center'}"
              (click)="routeSelectedValue.emit({value: rowData[col.name], row: rowData, colName: col.name})"
              [innerHTML]="renderField(col, rowData[col.name])">
            </span>
          </div>

          <!-- colonna con link che apre nuovo tab -->
          <div *ngIf="col.type == 'progress'" [ngStyle]="col.cellStyle ? col.cellStyle : {'text-align': 'center'}"
            class="ui-resizable-column">
            <p-progressBar [value]="rowData[col.name]" [showValue]="true"></p-progressBar>
          </div>

          <!-- colonna di tipo 'byte' -->
          <div *ngIf="col.type && col.type == 'byte' && !col.editable" class="ui-resizable-column">
            <span [ngClass]="'custom-span'" [innerHTML]="renderField(col, rowData[col.name])"
              [title]="(rowData[col.name] | number:'1.0':'en-US') + ' Byte'">
            </span>
          </div>

          <!-- colonna di tipo 'arraylabelpair' non editabile -->
          <div *ngIf="col.type && col.type == 'arraylabelpair' && !col.editable">
            <div *ngIf="rowData[col.name] && rowData[col.name].length > 0">
              <div *ngFor="let item of rowData[col.name]; let i = index">
                {{ item.code + (item.description ? (' - ' + item.description) : '')}}
                <hr *ngIf="i !== rowData[col.name].length - 1" class="m-1">
              </div>
            </div>
          </div>

          <!-- colonna editabile -->
          <p-cellEditor *ngIf="col.editable" class="ui-resizable-column">
            <ng-template pTemplate="input">
              <input class="form-control form-control-head w-100 text-black-50" pInputText type="text"
                [(ngModel)]="rowData[col.name]" (change)="editeCell(rowIndex, col, rowData[col.name])">
            </ng-template>
            <ng-template pTemplate="output">
              <div class="form-control form-control-head" [innerHTML]="renderField(col, rowData[col.name])"></div>
            </ng-template>
          </p-cellEditor>

        </td>
      </ng-template>

      <!-- sottogruppi (totali sotto) -->
      <tr *ngIf="rowGroup && rowGroup.key == rowData[rowGroup.key] && rowGroup[rowData.groupBy][dataKey] === rowIndex">
        <td *ngFor="let footerField of getSumGrouped(rowData.groupBy); let i = index"
          style="box-sizing: border-box;padding: .25em .5em;font-weight: bold;" [ngStyle]="footerField.style"
          class="ui-resizable-column">
          {{footerField.hasFooter ? footerField.value : ""}}
          {{(!footerField.hasFooter && i == 0) ? config.labels.totals : ""}}
        </td>
      </tr>

    </ng-template>

    <!-- pulsante nuova riga -->
    <ng-template *ngIf="config.addRowTable" pTemplate="summary" let-rowData>
      <div style="text-align:left">
        <button type="button" class="btn btn-primary" (click)="addRowTable(columns)" label="Add"
          [disabled]="config.addRowButtonDisabled">
          <i class="fas fa-fw fa-plus"></i>
        </button>
      </div>
    </ng-template>

    <!-- expander della tabella (ripresa da dyn-table) -->
    <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns" *ngIf="!selectableExpander">
      <tr class="zero-expanded-row">
        <!-- non rimuovere, ci mantiene l'ordine della zebra  -->
      </tr>
      <tr class="w-100">
        <td [attr.colspan]="columns?.length + 1">
          <div class="container ml-2">
            <!-- se sono raggruppati in tab -->
            <div [@rowExpansionTrigger]="'active'" class="row">
              <div *ngFor="let group of getExpandedGroups()" class="col text-left">
                <div class="mt-1"><b><i>{{group}}</i></b></div>
                <hr class="mt-1 mb-1" />
                <div *ngFor="let col of getExpandedFields(group)">
                  <div *ngIf="!col.type" [ngStyle]="col.cellStyle">
                    <span [ngClass]="col.customCssClass != null ? col.customCssClass : 'expander-group-text'"
                      title="{{rowData[col.name]}}" [ngStyle]="{cursor: col.dialog ? 'pointer' : 'default'}">
                      <span class="font-weight-bold" *ngIf="col && col.label"> {{col.label}}:&nbsp;</span>
                      <span [innerHTML]="renderField(col, rowData[col.name])"></span>
                    </span>
                  </div>
                  <div *ngIf="col.type" [ngStyle]="col.cellStyle">
                    <span [ngClass]="col.customCssClass != null ? col.customCssClass : 'expander-group-text'"
                      [ngStyle]="{cursor: col.dialog ? 'pointer' : 'default'}">
                      <span class="font-weight-bold" *ngIf="col && col.label"> {{col.label}}:&nbsp;</span>
                      <span [innerHTML]="renderField(col, rowData[col.name])"></span>
                    </span>
                  </div>
                </div>
              </div>
              <!-- se non sono raggruppati in tab -->
              <div class="text-left col-12">
                <div *ngFor="let col of getExpandedFields()">
                  <div *ngIf="!col.type" [ngStyle]="col.cellStyle">
                    <span [ngClass]="col.customCssClass != null ? col.customCssClass : 'expander-group-text'"
                      title="{{rowData[col.name]}}" [ngStyle]="{cursor: col.dialog ? 'pointer' : 'default'}">
                      <b>{{col.label}}</b>: <span [innerHTML]="renderField(col, rowData[col.name])"></span>
                    </span>
                  </div>
                  <div *ngIf="col.type" [ngStyle]="col.cellStyle">
                    <span [ngClass]="col.customCssClass != null ? col.customCssClass : 'expander-group-text'"
                      [ngStyle]="{cursor: col.dialog ? 'pointer' : 'default'}">
                      <b>{{col.label}}</b>: <span [innerHTML]="renderField(col, rowData[col.name])"></span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <!-- row expansion con checkbox per pluri-selezione (ogni riga deve avere i propri "children") -->
    <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns" *ngIf="selectableExpander">
      <tr>
        <td [attr.colspan]="columns?.length + 2" class="text-justify align-middle">
          <div *ngFor="let rowChildren of rowData.children" class="p-1">
            <p-tableCheckbox class="ml-3" [value]="rowChildren" [disabled]="checkParentSelected(rowData)">
            </p-tableCheckbox>
            {{ rowChildren.label }}
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer">
      <tr *ngIf="hasFooterFields()">
        <td *ngFor="let footerField of getFooterFields(); let i = index"
          style="text-align: right;box-sizing: border-box;padding: .25em .5em;" [ngStyle]="footerField.style"
          class="ui-resizable-column cell-total">
          {{footerField.hasFooter ? footerField.value : ""}}
          {{(!footerField.hasFooter && i == 0) ? config.labels.totals : ""}}
        </td>
      </tr>
    </ng-template>

  </p-table>
  <!-- numeri di pagina -->
  <p-footer>
    <div class="pb-2 text-center" *ngIf="showFooter">
      <span>
        {{ config.labels.showing }} {{ (dynTable.first < 0 ? 1 : (dynTable.first + 1)) }} -
        {{ ((dynTable.first + dynTable.rows) > total ? (total ? total : (data?.length))
          : (dynTable.first + dynTable.rows)) }}
        {{ config.labels.of }} <strong> {{ total ? total : (data?.length) }}</strong>
      </span>
    </div>
  </p-footer>
</div>