import {Injectable} from '@angular/core';
import {DynTableColAlign, DynTableColWidth, DynTableRowSpan} from './dyn-table.model';

@Injectable({providedIn: 'root'})
export class DynTableService {

  constructor() {
  }

  /**
   * Utility per larghezze colonne dyn-table
   */
  public getColWidth(type: DynTableColWidth, customWidth?: number) {
    switch (type) {
      case DynTableColWidth.MINI:
        return {width: '48px'};
      case DynTableColWidth.VERY_SMALL:
        return {width: '72px'};
      case DynTableColWidth.SMALL:
        return {width: '96px'};
      case DynTableColWidth.MEDIUM:
        return {width: '128px'};
      case DynTableColWidth.LARGE:
        return {width: '192px'};
      case DynTableColWidth.VERY_LARGE:
        return {width: '224px'};
      case DynTableColWidth.HUGE:
        return {width: '272px'};
      case DynTableColWidth.MAX:
        return {width: '320px'};
      case DynTableColWidth.OVERMAX:
        return {width: '480px'};
      case DynTableColWidth.DATE_WIDTH:
        return {width: '96px'};
      case DynTableColWidth.NDG_WIDTH:
        return {width: '112px'};
      case DynTableColWidth.CUSTOM:
        // dimensione "custom"
        return {width: customWidth + 'px'};
      default:
        // di default width "MEDIUM"
        return {width: '128px'};
    }
  }

  /**
   * Utility per allineamenti content colonne dyn-table (N.B.: di default l'allineamento è center, usare solo quando serve realmente..)
   */
  public getColAlign(type: DynTableColAlign): any {
    switch (type) {
      case DynTableColAlign.LEFT:
        return {'text-align': 'left'};
      case DynTableColAlign.RIGHT:
        return {'text-align': 'right'};
      case DynTableColAlign.CENTER:
        return {'text-align': 'center'}; // (default)
    }
  }

  /**
   * Formattazione degli importi in tabella
   */
  public formatAmount(amount, useDecimal: boolean) {
    if (!amount) {
      return useDecimal ? '0,00' : '0';
    }
    if (!useDecimal) {
      amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace(',', '.');
    }
    if (useDecimal) {
      amount = Number(amount);
      amount = amount.toFixed(2).toString().replace('.', ',');
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    } else {
      return amount;
    }
  }
  
  // formattazione importi 3 cifre decimali
  public formatAmount3Digits(amount, useDecimal: boolean) {
    if (!amount) {
      return useDecimal ? '0,000' : '0';
    }
    if (!useDecimal) {
      amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace(',', '.');
    }
    if (useDecimal) {
      amount = Number(amount);
      amount = amount.toFixed(3).toString().replace('.', ',');
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    } else {
      return amount;
    }
  }
  
   // formattazione importi 4 cifre decimali
   public formatAmount4Digits(amount, useDecimal: boolean) {
    if (!amount) {
      return useDecimal ? '0,0000' : '0';
    }
    if (!useDecimal) {
      amount.toString().replace(/(\d)(?=(\d{4})+(?!\d))/g, '$1,').replace(',', '.');
    }
    if (useDecimal) {
      amount = Number(amount);
      amount = amount.toFixed(4).toString().replace('.', ',');
      return amount.toString().replace(/\B(?=(\d{4})+(?!\d))/g, '.');
    } else {
      return amount;
    }
  }

  public padString(n, width, padString = '0') {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(padString) + n;
  }

  /**
   * Conversione di date
   */
  public convertTimezone(timezone, formatHHmmss = false) {
    if (!timezone) {
      return null;
    }
    if (timezone.length === 16) {
      timezone = timezone.substr(0, 10) + 'T00:00:00' + timezone.substr(10);
    } else if (timezone.length === 22) {
      timezone = timezone.substr(0, 16) + ':00' + timezone.substr(16);
    } else if (timezone.length === 29) {
      timezone = timezone.replace(/\.\d+/, '');
    } else if (timezone.length !== 25) {
      return null;
    }
    const date = new Date(Date.parse(timezone));
    return this.formatDate(date, formatHHmmss);
  }

  private formatDate(date, formatHHmmss: boolean = false, formatTrattini: boolean = false, formatHHmm: boolean = false) {
    // Hours part from the timestamp
    const hours = date.getHours();
    // Minutes part from the timestamp
    const minutes = '0' + date.getMinutes();
    // Seconds part from the timestamp
    const seconds = '0' + date.getSeconds();

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    if (formatTrattini) {
      return this.padString(year, 4) + '-' + this.padString(month, 2) + '-' + this.padString(day, 2);
    }
    // Will display time in 10:30:23 format
    if (formatHHmm) {
      return this.padString(day, 2) + '/' + this.padString(month, 2) + '/' + year + ' '
        + this.padString(hours, 2) + ':' + this.padString(minutes.substr(-2), 2);
    }
    if (formatHHmmss) {
      return this.padString(day, 2) + '/' + this.padString(month, 2) + '/' + year + ' '
        + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    } else {
      return this.padString(day, 2) + '/' + this.padString(month, 2) + '/' + year;
    }
  }
  
  /**
   * Per generare la configurazione di un eventuale rowspan dyn-table "standard"
   * @param items - dati righe dyntable
   * @param rowspanFieldName - nome del campo da cui prelevare la chiave di aggregazione
   * @param header - traduzione header della colonna rowspan
   * @param style - stile header/colonna rowspan (tipicamente "width")
   */
  public createRowspanMetadata(items: any[], rowspanFieldName: string, header: string, style: any): DynTableRowSpan {
    let rowSpanMetadata: DynTableRowSpan = {
      header: header,
      style: style
    }
    if (items && rowspanFieldName) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const keyField = item[rowspanFieldName]; // VALORE del campo indicato come chiave... sarà il valore dentro la cella rowspan
        if (i == 0) {
          rowSpanMetadata[keyField] = {index: 0, size: 1, value: keyField};
        } else {
          const previousRowData = items[i - 1];
          const previousRowGroup = previousRowData.anagrafica;
          if (keyField === previousRowGroup) {
            // stessa chiave --> incremento size
            rowSpanMetadata[keyField].size++;
          } else {
            // chiave diversa --> cambio index e size ricomincia da 1
            rowSpanMetadata[keyField] = {index: i, size: 1, value: keyField}
          }
        }
      }
    }
    return rowSpanMetadata;
  }
}
