import {MenuItem} from 'primeng/api';
import {Validators} from '@angular/forms';

// configurazione generale della tabella:
export interface DynTableConfig {
  clientManaged?: boolean; // IMPORTANTE: tabella con dati pre-caricati gestita lato client
  searchFields?: Array<DynTableSearchFields>; // filtri di ricerca della tabella
  labels?: DynTableLabels; // labels "statiche" della tabella
  paginator?: boolean; // mostra o meno la paginazione della tabella
  sort?: string; // sorting tabella
  dir?: number; // direzione colonna ('asc', 'desc')
  rowClickAction?: any; // riga cliccata
  multiSelectable?: boolean; // per rendere multi-selezionabili le righe della tabella
  singleSelectable?: boolean; // per rendere selezionabile una riga alla volta della tabella
  loading?: boolean; // mostra o meno il caricamento della tabella
  addRowTable?: boolean;
  addRowButtonDisabled?: boolean;
  marginTable?: string; // margine tabella
  checkboxes?: boolean; // abilita la selezione delle righe con checkbox
  rowspanField?: string; // DynTableColumn name su cui eseguire il rowspan (serve sempre anche passare in input "rowSpanMetadata")
  clientSideFilters?: boolean; // indica che si vuole usare i filtri lato "client" (abbiamo già tutti i dati) per colonna
}

// label "statiche" della tabella:
export interface DynTableLabels {
  title?: string; // titolo della ricerca
  searchBtnLabel?: string; // eventuale traduzione "Avvia" button
  resetBtnLabel?: string; // eventuale traduzione "Reset" button
  ricercaHeader?: string; // traduzione "Ricerca" header filtri
  totals: string;
  showing?: string;
  of?: string;
  actionsHeader?: string;
}

// configurazione di una colonna della tabella:
export interface DynTableColumn {
  name: string;
  label: string; // traduzione campo
  startPeriodLabel?: string;
  endPeriodLabel?: string;
  headerAlign?: string;
  type?: DynTableColType;
  sortable?: boolean;
  sortField?: string;
  style?: any;
  cellStyle?: any;
  sum?: boolean; // colonna sommabile
  editable?: boolean; // colonna editabile
  disabled?: string; // per disabilitare l'editabilità di una colonna(tipo sugli switch)
  tooltip?: string;
  iconRightClass?: string;
  iconRightColor?: string;

  searchable?: boolean; // indica che deve comparire la ricerca "classica" nell'header
  searchType?: DynTableSearchType; // tipo di ricerca
  searchMethod?: DynTableSearchMethod; // metodo di ricerca
  searchPh?: string; // placeholder visualizzato nel campo di ricerca
  searchOptions?: DynTableSearchOption[];

  // CAMPI X EXPANDER:
  group?: string; // gruppo di appartenenza
  groupLabel?: string; // traduzione titolo gruppo expander
  customCssClass?: any; // colonna con style e classe personalizzata
  grouped?: boolean; // per raggruppare e fare somme per sottogruppi
}

// configurazione colonne unite con colspan:
export interface DynTableColSpan {
  name: string;
  label: string; // traduzione campo
  colspan: number;
}

export interface DynTableRowSpan {
  header: string; // traduzione header rowspan
  style?: any;
  // ...verrà aggiunta la config dei campi "custom" in base ai dati della tabella
}

// configurazione campi nei filtri di ricerca:
export interface DynTableSearchFields {
  name: string; // nome campo
  label: string; // traduzione campo
  type: string; // tipo filtro (default input_contains ???)
  options?: any[]; // valori se type == "select"
  disabled?: boolean; // disabilitato true/false
  validators?: Array<Validators>; // validatori del campo
  defaultOption?: any; // valore default per il form select
  placeholder?: string; // placeholder per i campi di ricerca
  tooltip?: string; // tooltip per "inVal"
}

export enum DynTableSearchFieldType {
  INPUT_CONTAINS = 'input_contains',
  SELECT = 'select',
  DATE_SEARCH = 'dateSearch',
  DATE_RANGE = 'between',
  EXT_SERVICE = 'inVal',
}

// configurazione della singola action della tabella:
export interface DynTableAction {
  type: string;
  icon: string;
  stackedIcons?: string[];
  disabled?: string;
  hidden?: string;
  tooltip?: string;
  cls?: string;
  width?: string; // per larghezza colonna acttion
  splitButton?: boolean; // campo per attivare o meno il menu split-button
  items?: MenuItem[];
  tooltipLarge?: boolean;
  onClick: (actionType: string, row: any) => void; // click su una delle azioni o expander
}

export interface IconActionData {
  actionType: string; // identificatore del tipo di icon-action
  row: any; // dati della riga dyn-table
}

// tipologie di colonne "speciali" (vedi metodo "renderField")
export class DynTableColType {
  static BOOLEAN = 'boolean';
  static NUMBER = 'number';
  static DECIMAL = 'decimal';
  static NO_DECIMAL = 'noDecimal';
  static PERC = 'perc';
  static IMAGE = 'image';
  static ICON = 'icon';
  static ICON_ACTION = 'icon_action'; // specificare con --> classeIcona;actionType
  static URL = 'url';
  static EURO = 'euro';
  static EURO_NO_DECIMAL = 'euroNoDecimal';
  static EURO_3_DIGITS = 'euro3digits';
  static EURO_4_DIGITS = 'euro4digits';
  static DATETIME = 'datetime';
  static CUSTOM = 'custom';
  static DATE = 'date';
  static DOTS = 'dots';
  static BADGE = 'badge';
  static ROUTE = 'route';
  static PROGRESS = 'progress';
  static ICON_BADGE = 'icon_badge';
  static SWITCH = 'switch';
  static BYTE = 'byte';
  static ARRAYLABELPAIR = 'arraylabelpair';
  static DOTS_APPROVE = 'dots_approve';
  static TEXT_CENTER = 'text_center';
  static TEXT_RIGHT = 'text_right';
  static TEXT_COMPARE_ICON = 'text-compare-icon';
}

export enum DynTableColWidth {
  MINI,
  VERY_SMALL,
  SMALL,
  MEDIUM,
  LARGE,
  VERY_LARGE,
  HUGE,
  MAX,
  OVERMAX,
  DATE_WIDTH,
  NDG_WIDTH,
  CUSTOM
}

export enum DynTableColAlign {
  LEFT,
  RIGHT,
  CENTER
}

export enum DynTableTypeClick {
  CHECKBOX,
  SINGLEROW
}

export interface DynTablePagination {
  orderField: string,
  orderDirection: string,
  currentPage: number,
  targetPage: number,
  totalPages: number,
  recordsPerPage: number,
  totalRecords: number
}

export class DynTableBadgeType {
  static PRIMARY = 'badge badge-primary;';
  static INFO = 'badge badge-info;';
  static SUCCESS = 'badge badge-success;';
  static DANGER = 'badge badge-danger;';
}

export class DynTableSearchType {
  static TEXT = 'text';
  static NUMBER = 'number';
  static BOOLEAN = 'boolean';
  static DATE = 'date';
  static TIME = 'time';
  static CURRENCY = 'currency';
  static SELECT = 'select';
  static MULTISELECT_APPROVE = 'multiselect_approve';
}

export enum DynTableSearchMethod {
  startsWith, 
  contains, 
  endsWith, 
  equals, 
  notEquals, 
  in, 
  lt, 
  lte, 
  gt, 
  gte
}

export interface DynTableSearchOption {
  label?: string;
  value?: any;
  color?: string;
}

// tipologia di filtro "server-side"
export class DynTableColFilterType {
  static INPUT_CONTAINS = 'input_contains';
  static EQUALS = 'equals';
  static SELECT = 'select';
  static DATE_SEARCH = 'dateSearch';
  static TIME_SEARCH = 'timeSearch';
  static DATE_RANGE = 'dateRange';
  static IN_VAL = 'inVal';
  static CALL_WS = 'callWs';
  static TIME_RANGE = 'time_range';
  static DATE_INTERVAL = 'date_interval';
  static TS_INTERVAL = 'ts_interval';
  static BETWEEN = 'between';
  static IN_NUM = 'inNum';
}

export interface CrudParams {
  criterion: Criterion;
  pagination: any;
  sorting: any;
}
export interface Criterion {
  type: string; // se ha nestedCriterions può essere "and", "or", ecc...
  name: string;
  params: any[];
  nestedCriterions: Criterion[];
}
