import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {MultiSelect} from 'primeng/multiselect';

@Component({
  selector: 'form-multiselect',
  template: `
    <div class="form-group">
      <label class="col-form-label font-weight-bold">{{label}}</label>
      <ng-template [ngIf]="loading">
        <br>
        <p-progressSpinner [style]="{width: '20px', height: '20px'}"></p-progressSpinner>
      </ng-template>
      <p-multiSelect
          #multiSelectRef
          [hidden]="loading"
          styleClass="w-100"
          [formControl]="control"
          [options]="items"
          [defaultLabel]="placeholder"
          [selectedItemsLabel]="selectedItemsLabel"
          [resetFilterOnHide]="true"
          [maxSelectedLabels]="maxSelectedLabels"
          (onChange)="selectChanged.emit($event)">
      </p-multiSelect>
      <div *ngIf="errorMsg" class="invalid-feedback">{{errorMsg}}</div>
    </div>
  `,
})
export class FormMultiselectComponent {

  @ViewChild('multiSelectRef') multiSelectRef: MultiSelect;

  // input onChange:
  @Input() control: AbstractControl;
  @Input() items: any[];
  @Input() loading: boolean;
  @Input() errorMsg: string;

  // input "fissi":
  @Input() label: string;
  @Input() placeholder: string;
  @Input() selectedItemsLabel: string;
  @Input() labelFieldName = 'label';
  @Input() valueFieldName = 'value';
  @Input() maxSelectedLabels = 3;

  @Output() selectChanged: EventEmitter<any> = new EventEmitter();

  closeDropdown() {
    if (this.multiSelectRef != null) {
      this.multiSelectRef.hide();
    }
  }

}
