export const restServices = {
  authenticate: 'authenticate',
  account: 'account',
  logout: '',
  budgetOv: 'budget-ov',
  byId: 'by-id',
  utente: 'utente',
  perUtente: 'per-utente',
  perUtenteDettaglio: 'per-utente-dettaglio',
  budget: 'budget',
  clienteArticolo: 'cliente-articolo',
  dettaglio: 'dettaglio',
  utenteLoggato: 'utente-loggato',
  visibilita: 'visibilita',
  articoloDettaglio: 'articolo-dettaglio',
  ricalcola: 'ricalcola',
  all: 'all',
  prezzoMedio: 'prezzo-medio',
  articolo: 'articolo',
  listaTipo: 'lista-tipo',
  nuovoBudget: 'nuovo-budget',
  approva: 'approva',
  libera: 'libera',
  nuovaRevisione: 'nuova-revisione',
  listaSpecificiByGenerico: 'lista-specifici-by-generico',
  dettaglioScorporo: 'dettaglio-scorporo',
  dettaglioScorporoPerArticolo: 'dettaglio-scorporo-per-articolo',
  scorpora: 'scorpora',
  perArticolo: 'per-articolo',
  perArticoloCliente: 'per-articolo-cliente',
  consPerArticoloScorpora: 'cons-per-articolo-scorpora',
  archivia: 'archivia',
  export: 'export',
  importArticoli: 'import-articoli',
  configurazione: 'configurazione',
  cliente: 'cliente',
  gruppoCliente: 'gruppo-cliente',
  costoUnitario: 'costo-unitario',
  schedulazioneRevisione: 'schedulazione-revisione',
  profilo: 'profilo',
  utenteProfilo: 'utente-profilo',
  classe: 'classe',
  company: 'company',
  nazione: 'nazione',
  admin: 'admin',
  mail: 'mail',
  consuntivo: 'consuntivo',
  configurazioneVistaClasse: 'configurazione-vista-classe'
};
