import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'form-input-textarea',
  template: `
    <div class="form-group">
      <label class="col-form-label font-weight-bold">{{label}}</label>
      <textarea class="form-control"
                [rows]="rows"
                [class.is-invalid]="control.invalid"
                [placeholder]="placeholder"
                [formControl]="control">
      </textarea>
      <div *ngIf="errorMsg" class="invalid-feedback">{{errorMsg}}</div>
    </div>
  `,
})
export class FormInputTextareaComponent {

  @Input() control: AbstractControl;
  @Input() rows = 3; // di default sempre 3 righe
  @Input() label: string;
  @Input() placeholder: string;
  @Input() errorMsg: string;

}
