import { Component, Input } from '@angular/core';

@Component({
  selector: 'form-input-readonly',
  template: `
    <div class="form-group">
      <label class="col-form-label font-weight-bold">{{label}}</label>
      <input type="text" class="form-control" [value]="staticValue" [readOnly]="true">
    </div>
  `
})
export class FormInputReadonlyComponent {
  @Input() label: string;
  @Input() staticValue: string;
}
