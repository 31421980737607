import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'form-input-hhmm',
  template: `
    <div class="form-group">
      <label class="col-form-label font-weight-bold">{{label}}</label>
      <p-inputMask
          [formControl]="control"
          [styleClass]="'form-control ' + (control.invalid ? 'is-invalid' : '')" [style]="{'width': '100%'}"
          mask="99:99" slotChar="HH:MM" placeholder="HH:MM..."
          (onInput)="inputChanged.emit(); inputFinish = false"
          (onComplete)="inputCompleted.emit(); inputFinish = true"
          [pattern]="keyFilterRegex">
      </p-inputMask>
      <div *ngIf="inputFinish && control.invalid" class="error-message">{{errorMsg}}</div>
    </div>
  `,
})
export class FormInputHhmmComponent {

  keyFilterRegex: RegExp = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
  inputFinish = false;

  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() errorMsg: string;

  @Output() inputChanged: EventEmitter<string> = new EventEmitter();
  @Output() inputCompleted: EventEmitter<string> = new EventEmitter();

}
