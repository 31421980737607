import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'form-checkbox',
  template: `
    <div class="form-group">
      <label class="col-form-label font-weight-bold">{{label}}</label>&nbsp;&nbsp;
      <p-checkbox [formControl]="control" [binary]="true" [value]="value" 
        (onChange)="valueChanged.emit($event);" [ngStyle]="{'padding-bottom': 'calc(0.375rem + 1px)'}"></p-checkbox>
    </div>
  `,
})
export class FormCheckboxComponent {
  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() value: any; // valore settabile per checkbox a mutua-esclusione

  @Output() valueChanged: EventEmitter<string> = new EventEmitter();
}
