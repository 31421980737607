import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'form-input-optional-switch',
  template: `
    <div class="form-group">
      <label class="col-form-label font-weight-bold pr-1" for="input-switch" id="input-switch-label">{{label}}</label>
      <p-checkbox [binary]="true" [(ngModel)]="active" (onChange)="checkboxStatusChanged($event?.checked)"></p-checkbox>
      <br>
      <p-inputSwitch
          [formControl]="control"
          id="input-switch" ariaLabelledBy="input-switch-label"
          (onChange)="handleChange.emit($event)">
      </p-inputSwitch>
    </div>
  `
})
export class FormInputOptionalSwitchComponent {

  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() active: boolean; // flag ad indicare se attivare o meno lo switch

  @Output() handleChange: EventEmitter<any> = new EventEmitter();

  checkboxStatusChanged(value: boolean) {
    if (value) {
      this.control.enable();
      this.control.setValue(false);
    } else {
      this.control.setValue(null);
      this.control.disable();
    }
  }


}
