import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
  constructor() {}

  setItem(itemKey: string, itemObject: string): any {
    let encryptedValue = btoa(escape(itemObject));
    const item = localStorage.getItem(itemKey);
    if (item === itemObject) {
      encryptedValue = btoa(escape(item));
      return localStorage.setItem(itemKey, encryptedValue);
    } else {
      return localStorage.setItem(itemKey, encryptedValue);
    }
  }

  setItemSession(itemKey: string, itemObject: string): any {
    let encryptedValue = btoa(escape(itemObject));
    const item = sessionStorage.getItem(itemKey);
    if (item === itemObject) {
      encryptedValue = btoa(escape(item));
      return sessionStorage.setItem(itemKey, encryptedValue);
    } else {
      return sessionStorage.setItem(itemKey, encryptedValue);
    }
  }

  getItem(itemKey: string): any {
    const itemFromStorage = localStorage.getItem(itemKey);
    if (itemFromStorage) {
      return unescape(atob(itemFromStorage));
    } else {
      return false;
    }
  }

  getItemSession(itemKey: string): any {
    const itemFromStorage = sessionStorage.getItem(itemKey);
    if (itemFromStorage) {
      return unescape(atob(itemFromStorage));
    } else {
      return false;
    }
  }

  hasItem(itemKey: string) {
    return localStorage.getItem(itemKey) != null;
  }

  hasItemSession(itemKey: string) {
    return sessionStorage.getItem(itemKey) != null;
  }

  removeItem(itemKey: string) {
    localStorage.removeItem(itemKey);
  }

  removeItems(itemKeys: string[]) {
    itemKeys.forEach(key => this.removeItem(key));
  }

  removeItemSession(itemKey: string) {
    sessionStorage.removeItem(itemKey);
  }

  clear() {
    localStorage.clear();
  }
}
