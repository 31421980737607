import { Component, Input } from '@angular/core';

@Component({
  selector: 'label-value',
  template: `
    <p [ngClass]="[noMarginBottom ? 'mb-0' : '', marginTop != null ? 'mt-' + marginTop : '']">
      <b>{{label}}: </b>
      <ng-template [ngIf]="!badgeType">{{value}}</ng-template>
      <ng-template [ngIf]="badgeType">
        <span class="badge" [ngClass]="badgeType" style="font-size: 0.8rem">{{value}}</span>
      </ng-template>
    </p>
  `
})
export class LabelValueComponent {
  @Input() label: string;
  @Input() value: any;
  @Input() badgeType: null | 'badge-primary' | 'badge-success' | 'badge-info' | 'badge-danger' | 'badge-warning';
  @Input() noMarginBottom = false;
  @Input() marginTop: number;
}
