<nav class="navbar navbar-expand-md fixed-top" [ngClass]="deviceService.isMobile() ? 'py-0' : ''">

  <a *ngIf="!deviceService.isMobile()" href="#/budget" [ngClass]="deviceService.isMobile() ? 'mb-4 mr-0' : ''">
    <img [src]="logoPath" alt="Logo">
  </a>

  <span align="center" class="font-weight-bold nav-app-name">{{appName}}</span>

  <ul class="navbar-nav">
    <li class="nav-item">
      <button type="button" class="btn-invisible" (click)="closeSidebar.emit()">
        <i class="fas fa-fw fa-lg fa-bars reduce-nav-icon"></i>
      </button>
    </li>
  </ul>

  <div class="d-flex flex-fill">
    <!-- CONTENUTO CENTRALE NAVBAR QUI -->
  </div>

  <button *ngIf="deviceService.isMobile()"
          class="btn-invisible" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse"
          aria-expanded="false" aria-label="">
    <img src="assets/img/bars.png" alt="">
  </button>

  <div class="collapse navbar-collapse navbar-content" id="navbarCollapse" style="height: 50px;"
       [ngClass]="deviceService.isMobile() ? 'border border-dark rounded' : ''">

    <ul class="navbar-nav ml-auto">

      <!-- MENU CAMBIO LINGUA -->
      <!-- Se si vuole mostrare il menu, prima vanno effettuate tutte le traduzioni in lingua inglese nel file gb.json -->
      <!-- <li class="nav-item dropdown pt-3">
        <a class="nav-link dropdown-toggle text-white" href="javascript:void(0);" id="languagesDropdown"
           role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
           [ngClass]="deviceService.isMobile() ? 'text-center' : ''">
          <span class="flag-icon flag-icon-{{i18nService.language}}" style="font-size: 1.5rem;"></span>
        </a>
        <div class="dropdown-menu m-0 pb-0 translate-left-lang" aria-labelledby="languagesDropdown"
             [ngClass]="deviceService.isMobile() ? 'border border-dark rounded position-absolute' : ''">
          <a *ngFor="let language of languages" class="dropdown-item" href="javascript:void(0);"
             (click)="changeLanguage(language.code)">
            <i class="flag-icon flag-icon-{{language.code}} mb-0" style="font-size: 1.5rem;"></i>
            &nbsp;<span style="vertical-align: super;">{{ language.description }}</span>
          </a>
        </div>
      </li> -->

      <!-- MENU UTENTE -->
      <li [autoClose]="true" [insideClick]="true" class="nav-item dropdown cursor-pointer pl-3" dropdown>
        <a class="btn nav-link float-right text-white mt-1 pr-4" dropdownToggle [ngClass]="deviceService.isMobile() ? 'text-center' : ''">
        <span class="float-left mt-2 mr-2">
          <i class="fas fa-user" style="font-size: 1.5rem;"></i>
          <!-- prevedere user image -->
        </span>
          <div class="text-left float-right">
            <div class="mt-2 float-left">
              <span class="font-weight-bold pt-3">{{currentUser.nome}}</span>
            </div>
            <span class="fas fa-caret-down float-right mt-3 ml-2"></span>
          </div>
        </a>
        <!-- menu -->
        <div class="dropdown-menu m-0 pb-0 translate-left" *dropdownMenu
             [ngClass]="deviceService.isMobile() ? 'border border-dark rounded position-absolute' : ''">
          <!-- logout -->
          <div class="dropdown-item" (click)="logout()">
            <i class="fas fa-sign-out-alt fa-fw"></i>&nbsp;&nbsp;<span class="font-weight-bold">{{'Logout'|translate}}</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</nav>
