import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { TableView } from '../../models/enums/table-view';
import { LabelValue } from '../../models/general/label-value';
import { SortInfo } from '../../models/general/sort-info';
import { TableColumn } from '../../models/general/table-column';
import { StorageService } from './storage.service';

@Injectable()
export class PrimengTableService {
  constructor(
    private storageService: StorageService,
    private translate: TranslateService
  ) {}

  createColumnOptions(availableColumns: TableColumn[]): LabelValue[] {
    let options: LabelValue[] = [];
    availableColumns.forEach((column) => column.header ? options.push({label: column.header + ' ' + (column.header2 ? column.header2 : '') + ' ' + 
    (column.header3 ? column.header3 : '') + ' ' + (column.header4 ? column.header4 : ''), value: column.id}) : null);
    return options;
  }

  getOptionsViste(): LabelValue[] {
    return [
      { label: this.translate.instant('vistaDefBudget'), value: TableView.DEF_BUDGET },
      { label: this.translate.instant('vistaRevBudget'), value: TableView.REV_BUDGET }
    ];
  }

  getTableColumnsStorage(tableName: string): any {
    return JSON.parse(this.storageService.getItem(tableName + 'Columns'));
  }

  setTableColumnstorage(tableName: string, cols: any): any {
    this.storageService.setItem(tableName + 'Columns', JSON.stringify(cols));
  }

  getTableFiltersStorage(tableName: string): any {
    return JSON.parse(this.storageService.getItem(tableName + 'Filters'));
  }

  setTableFiltersStorage(tableName: string, filters: any): any {
    this.storageService.setItem(tableName + 'Filters', JSON.stringify(filters));
  }

  getTableSortStorage(tableName: string): any {
    return JSON.parse(this.storageService.getItem(tableName + 'Sort'));
  }

  setTableSortStorage(tableName: string, sort: any): any {
    this.storageService.setItem(tableName + 'Sort', JSON.stringify(sort));
  }

  saveSortOptionsToStorage(table: Table, tableKey: string) {
    const sortInfo: SortInfo = {
      sortField: table._sortField,
      sortOrder: table._sortOrder
    }
    this.setTableSortStorage(tableKey, sortInfo);
  }

  changeColumnSelection(columns: TableColumn[], availableColumns: TableColumn[], tableKey: string, event: any) {
    if (columns.findIndex((element) => element.id === event.itemValue) === -1) {
      columns.push(availableColumns.find((element) => element.id === event.itemValue));
    } else {
      columns = columns.filter(element => element.id !== event.itemValue);
    }
    this.setTableColumnstorage(tableKey, columns);
    return columns;
  }
}
