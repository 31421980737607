import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import { LOGGED_USER } from 'src/app/shared/constants/app.constants';
import { Classe } from '../../models/entities/classe';
import { ProfiloEnum } from '../../models/enums/profilo-enum';
import {NavItem} from '../../models/general/nav-item';
import { StorageService } from '../../services/general-utils/storage.service';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() navItems: NavItem[];
  @Output() closeSidebar: EventEmitter<void> = new EventEmitter();
  loggedUser: Classe;
  profili: ProfiloEnum[] = [];

  // gestione 2^ sidebar
  backupNavItems: any[] = []; // backup nav items originali (da nav.ts) nel caso in cui debba essere ricaricata la sidebar
  currentUrl: string;
  secondLevelActive = false;

  subscriptions: Subscription = new Subscription();

  constructor(
    private router: Router,
    public deviceService: DeviceDetectorService,
    private cdRef: ChangeDetectorRef,
    private storageService: StorageService) {
  }

  ngOnInit() {
    this.loggedUser = JSON.parse(this.storageService.getItem(LOGGED_USER));
    this.loggedUser.utenteProfilos.forEach(up => {
      if (!up.idApplicazione) {
        this.profili.push(up.profilo.profilo);
      }
    });
    this.currentUrl = this.router.url;
    // PER SUB-SIDEBAR:
    this.checkUrl(this.navItems);
    this.subscriptions.add(this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event) => {
      this.currentUrl = (event as NavigationEnd).url;
      this.checkUrl(this.navItems);
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    if (!this.deviceService.isDesktop()) {
      document.getElementById('sidebar').classList.add('mobile');
    }
    if (this.deviceService.isMobile()) {
      this.closeSidebar.emit();
    }
    this.cdRef.detectChanges();
  }

  /**
   * Funzione che esegue controllo sull'url corrente x switchare sidebar
   */
  private checkUrl(navItems: Partial<NavItem>[]) {
    let found: boolean;
    const mainUrl = '/' + this.currentUrl.split('/')[1];
    for (const navItem of navItems) {
      if (navItem.url != null) {
        const navItemMainUrl = '/' + navItem.url.split('/')[1];
        if (mainUrl === navItemMainUrl) {
          if (navItem.hasSidebar && !this.secondLevelActive) {
            // se navItem ha sidebar di 2° livello --> switcho sidebar!
            this.reloadSidebar(navItem);
          }
          found = true;
          break;
        }
      }
    }
    if (!found && this.secondLevelActive) {
      this.backToMainSidebar();
    }

  }

  backToMainSidebar() {
    // riassegno nav items originali e disabilito back button
    this.navItems = [];
    Object.assign(this.navItems, this.backupNavItems);
    this.secondLevelActive = false;
    document.getElementById('sidebar-list').classList.remove('animate__slideInLeft');
    document.getElementById('sidebar-list').classList.add('animate__slideInRight');
  }

  reloadSidebar(menuItem: any) {
    if (menuItem.hasSidebar) {
      // devo ricaricare le voci della sidebar con le voci "figlie" della voce padre e attivare il pulsante "back"
      this.backupNavItems = [];
      Object.assign(this.backupNavItems, this.navItems); // salvo i nav items "originali"
      this.navItems = menuItem.children;
      this.secondLevelActive = true;
      document.getElementById('sidebar-list').classList.remove('animate__slideInRight');
      document.getElementById('sidebar-list').classList.toggle('animate__slideInLeft');
    }
  }

  checkUrlIncluded(navItemUrl: string): boolean {
    return this.currentUrl.includes(navItemUrl);
  }

  checkDropdownOnLoading(navItemUrl: string): boolean {
    return this.checkUrlIncluded(navItemUrl) && !this.isMinimized();
  }

  /**
   * Metodo che al click sul menù di una dropdown, se la sidebar è minimizzata, chiude la dropdown automaticamente
   */
  closeDropdownMenu(keyPage?: string) {
    if (document.getElementById('sidebar').classList.contains('minimized')) {
      // sidebar minimizzata --> chiusura di TUTTI i dropdown:
      this.navItems.filter(navItem => navItem.isDropdown).forEach(dropdown => {
        document.getElementById('collapse-' + dropdown.keyPage).classList.add('collapsed');
        document.getElementById('collapse-' + dropdown.keyPage).setAttribute('aria-expanded', 'false');
        document.getElementById('menu-' + dropdown.keyPage).classList.remove('show');
      });
    } else if (keyPage) {
      // sidebar NON minimizzata --> chiude tutte LE ALTRE dropdown eventualmente aperte
      this.navItems.filter(navItem => navItem.isDropdown && navItem.keyPage !== keyPage 
        && this.hasPermission(this.profili, navItem.authRoles)).forEach(dropdown => {
        document.getElementById('collapse-' + dropdown.keyPage).classList.add('collapsed');
        document.getElementById('collapse-' + dropdown.keyPage).setAttribute('aria-expanded', 'false');
        document.getElementById('menu-' + dropdown.keyPage).classList.remove('show');
      });
    }
  }
  
  isMinimized(): boolean {
    return document.getElementById('sidebar').classList.contains('minimized');
  }
  
  hasPermission(profili: ProfiloEnum[], authRoles: ProfiloEnum[]) {
    return profili.some(ar => authRoles.indexOf(ar) >= 0);
  }

}
