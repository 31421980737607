import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SpinnerComponent} from './spinner.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {SpinnerServiceConfig} from './spinner.service';

@NgModule({
    imports: [
        CommonModule,
        NgxSpinnerModule
    ],
    declarations: [
        SpinnerComponent
    ],
    exports: [
        SpinnerComponent
    ],
    providers: []
})
export class SpinnerModule {
    static forRoot(config: SpinnerServiceConfig): ModuleWithProviders<SpinnerModule> {
        return {
            ngModule: SpinnerModule,
            providers: [
                {provide: SpinnerServiceConfig, useValue: config}
            ]
        };
    }
}
