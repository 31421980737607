import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { TableColumn } from '../../models/general/table-column';
import { UtilsService } from './utils.service';

@Injectable()
export class PrimengTableExportService {
  
  constructor(
    private _decimalPipe: DecimalPipe,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) {}

  exportExcel(table: Table, columns: TableColumn[], hasCellUtenteApprovazione?: boolean) {
    let excelData = [];
    const tableRows = table.filteredValue ? table.filteredValue : table._value;
    tableRows.forEach(row => {
      let cell = {};
      columns.forEach(column => {
        if (hasCellUtenteApprovazione) {
          cell[this.translate.instant('utente') + ' ' + this.translate.instant('approvazione')] = row.utUltimaApprovazione;
        }
        if (column.sortField) {
          const columnHeader = column.header + (column.header2 ? ' ' + column.header2 : '') + (column.header3 ? ' ' + column.header3 : '') + 
            (column.header4 ? ' ' + column.header4 : '');
          const sortFieldSplitted = column.sortField.split(".");
          if (sortFieldSplitted.length === 1) {
            cell[columnHeader] = typeof row[column.sortField] == 'number' ? (this._decimalPipe.transform(row[column.sortField], '1.0-2') +
              (columnHeader.startsWith('%') ? '%' : '')) : row[column.sortField];
          } else {
            if (row[sortFieldSplitted[0]]) {
              cell[columnHeader] = row[sortFieldSplitted[0]][sortFieldSplitted[1]];
            }
          }
        }
      });
      excelData.push(cell);
    });

    this.utilsService.exportExcel(excelData, 'budget', 'budget');
  }

}
