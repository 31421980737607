import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeGb from '@angular/common/locales/en-GB';
import { CoreModule } from './core/core.module';
import { SpinnerModule } from './shared/components/spinner/spinner.module';
import { DynTableModule } from './shared/components/dyn-table';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientXsrfModule } from '@angular/common/http';

registerLocaleData(localeIt);
registerLocaleData(localeGb);

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientXsrfModule,
    BrowserModule,
    CoreModule.forRoot(),
    SpinnerModule.forRoot({
      spinnerText: '',
      bgColor: 'rgba(0, 0, 0, 0.4)',
      size: 'large',
      color: 'black',
      type: 'ball-clip-rotate',
      fullScreen: false
    }),
    DynTableModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    {provide: LOCALE_ID, useValue: "it-IT"}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

